import React from "react";
// import qore from "../../../qoreContext";
// import hrscData from "./scrap-result-1658209946587.json";
// import hrscData from "./article-examples.json";
// import hrscData from "./hrsc-articles-final.json";

export default function () {
  // const TABLE_NAME = "allCategoryHrsc";
  // const { insertRow } = qore.view("allHrServiceCenter").useInsertRow({});

  // const { data: data1 } = qore.view("allHrServiceCenter").useListRow({});
  // const { data: data2 } = qore
  //   .view("allHrServiceCenter")
  //   .useListRow({ offset: 50 });
  // const { data: data3 } = qore
  //   .view("allHrServiceCenter")
  //   .useListRow({ offset: 100 });
  // const { data: data4 } = qore
  //   .view("allHrServiceCenter")
  //   .useListRow({ offset: 150 });
  // const { data: data5 } = qore
  //   .view("allHrServiceCenter")
  //   .useListRow({ offset: 200 });
  // const { data: data6 } = qore
  //   .view("allHrServiceCenter")
  //   .useListRow({ offset: 250 });
  // const { data: data7 } = qore
  //   .view("allHrServiceCenter")
  //   .useListRow({ offset: 300 });
  // const { data: data8 } = qore
  //   .view("allHrServiceCenter")
  //   .useListRow({ offset: 350 });

  const handleClick = async () => {
    // for (const item of hrscData) {
    //   await insertRow(item);
    // }
    // const allData = [
    //   ...data1,
    //   ...data2,
    //   ...data3,
    //   ...data4,
    //   ...data5,
    //   ...data6,
    //   ...data7,
    //   ...data8,
    // ];
  };

  return (
    <div
      style={{
        // height: "100vh",
        // width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "scroll",
        marginTop: "10rem",
      }}>
      {/* <pre style={{}}>{JSON.stringify(displayData, null, 2)}</pre> */}
      <div>
        <button
          style={{
            padding: "1rem 2rem",
            backgroundColor: "black",
            color: "white",
            fontSize: "48px",
          }}
          onClick={handleClick}>
          Upload
        </button>
      </div>
    </div>
  );
}
