import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'
import FinishedGoods from './catContent/FinishedGoods'
import IntermidateBulk from './catContent/IntermidateBulk'
import IntermidatePack from './catContent/IntermidatePack'
import PackagingMaterial from './catContent/PackagingMaterial'
import RawMaterial from './catContent/RawMaterial'
import SemiFinishedGoodsBulk from './catContent/SemiFinishedGoodsBulk'
import SemiFinishedGoodsBulkRefill from './catContent/SemiFinishedGoodsRefill'

const accordionContent = [
    {
        "id": 1,
        "title": "Finished Goods",
        "content": <FinishedGoods />
    },
    {
        "id": 2,
        "title": "Semi Finished Goods Bulk",
        "content": <SemiFinishedGoodsBulk />
    },
    {
        "id": 3,
        "title": "Semi Finished Goods Refill",
        "content": <SemiFinishedGoodsBulkRefill />
    },
    {
        "id": 4,
        "title": "Intermidate Bulk",
        "content": <IntermidateBulk />
    },
    {
        "id": 5,
        "title": "Intermidate Pack",
        "content": <IntermidatePack />
    },
    {
        "id": 6,
        "title": "Raw Material",
        "content": <RawMaterial />
    },
    {
        "id": 7,
        "title": "Packaging Material",
        "content": <PackagingMaterial />
    },
]

export default function Catalogue() {
    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">

                <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                    {accordionContent.map(accordion => (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                    {accordion.title}
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={accordion.id}>
                                <Card.Body>{accordion.content}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Container>
        </>
    )
}
