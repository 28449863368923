import React, { useState, useEffect } from "react";
import { Row, Button, Container, Nav } from "react-bootstrap";
import SlickSlider from "../../../../../../components/slick";
import { useMediaQuery } from 'react-responsive'
import Resource from "./content/Resource";
import Catalogue from "./content/Catalogue";
import Link from "./content/Link";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('subTabDownstream');

const listCategory = [
	{
		key: 'Link',
		name: 'Link'
	},
	{
		key: 'Catalogue',
		name: 'Catalogue'
	},
	{
		key: 'Resource',
		name: 'Resource'
	},
]

export default function POSM({ tabMDM }) {
	const [activeKey, setActiveKey] = useState(tab ? tab : 'Link');
	const history = useHistory();
	const params = new URLSearchParams({ tabDownstream: tabMDM, subTabDownstream: activeKey });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })

	useEffect(() => {
		history.push({ search: params.toString() });
		// eslint-disable-next-line
	}, [activeKey])

	const handleChangeActiveKey = (item) => {
		setActiveKey(item)
	}

	const settings = {
		infinite: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: false,
		centerMode: false,
		dots: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1.9,
					centerMode: false,
				},
			},
		],
		slidesPerView: 1,
		spaceBetween: 0,
		centeredSlides: true,
		navigation: {
			nextEl: ".swiper-btn-prev",
			prevEl: ".swiper-btn-next",
		},
	};

	return (
		<>
			<Container className='ptb--50 ptb-md--50 ptb-sm--30 bg_color--1 pb--50'>
				{isTabletOrMobile ?
					<>
						<SlickSlider
							settings={settings}
							style={{ width: '100%' }}
						>
							{listCategory?.map((category) => {
								return (
									<Row sm={12} md={12} lg={12}>
										{category?.key === activeKey ?
											<Button
												onClick={() => handleChangeActiveKey(category.key)}
												style={{ borderBottom: '4px solid blue', borderTop: '0px', borderRight: '0px', borderLeft: '0px', backgroundColor: 'white', color: 'blue', margin: '0px 12px', width: '100%', fontSize: '10px' }}
											>
												<strong>{category.name}</strong>
											</Button>

											:
											<Button
												onClick={() => handleChangeActiveKey(category.key)}
												style={{ borderBottom: '4px solid lightGrey', borderTop: '0px', borderRight: '0px', borderLeft: '0px', backgroundColor: 'white', color: 'black', margin: '0px 12px', width: '100%', fontSize: '10px' }}
											>
												<strong>{category.name}</strong>
											</Button>
										}
									</Row>
								)
							})}
						</SlickSlider>
					</>
					:
					<>
						<Nav variant="pills" className="mb-3">
							{listCategory?.map((category) => {
								return (
									<Nav.Item>
										{activeKey === category.key ?
											<Nav.Link eventKey={category.key} onClick={() => handleChangeActiveKey(category.key)} style={{ borderBottom: '4px solid blue', backgroundColor: 'white', color: 'blue' }}>
												<strong>{category.name}</strong>
											</Nav.Link>
											:
											<Nav.Link eventKey={category.key} onClick={() => handleChangeActiveKey(category.key)} style={{ borderBottom: '4px solid lightGrey', backgroundColor: 'white' }}>
												{category.name}
											</Nav.Link>
										}
									</Nav.Item>
								)
							})}
						</Nav>
					</>
				}
				{activeKey === 'Link' && <Link />}
				{activeKey === 'Catalogue' && <Catalogue />}
				{activeKey === 'Resource' && <Resource />}
			</Container>
		</>
	)
}
