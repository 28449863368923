import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Nav, Button, Card } from "react-bootstrap";
import AppServiceList from './AppServiceList'
import SlickSlider from "../../../../components/slick";
import { useMediaQuery } from 'react-responsive'
import PortfolioItem from "./PortofolioItem";
import qore from "../../../../qoreContext";
import { Popover } from 'react-tiny-popover';
import { useHistory } from 'react-router';

const listCategory = [
  {
    key: 'coreBusinessApps',
    name: 'Core Business Apps'
  },
  {
    key: 'corporateApps',
    name: 'Corporate Apps'
  },
  {
    key: 'publicFacingApps',
    name: 'Public Facing Apps'
  }
]

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabApss');
let view = paramsVal.get('view') === "true";
let negara = paramsVal.get('negara');
let directorate = paramsVal.get('directorate');

const ApplicationServices = () => {
  const history = useHistory();
  const [search, setSearch] = useState();
  const [activeKey, setActiveKey] = useState(tab ? tab : 'coreBusinessApps');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isListView, setIsListView] = useState(view ? view : false)
  const [filter, setFilter] = useState({
    directorate: directorate ? directorate.split(",") : [],
    location: negara ? negara : 'Indonesia'
  })

  const params = new URLSearchParams({ 
    tabApss: activeKey, 
    view: isListView,
    negara: filter.location,
    directorate:filter.directorate
  });

  const handleChangeFilterDirectorate = (e) => {
    setIsPopoverOpen(true)
    let checkDirectorate = e.target.id
    if (!filter.directorate.includes(checkDirectorate) && e.target.checked) {
      filter.directorate.push(checkDirectorate)
    }
    if (filter.directorate.includes(checkDirectorate) && !e.target.checked) {
      filter.directorate.splice(filter.directorate.indexOf(checkDirectorate), 1);
    }
    setFilter({
      ...filter,
      directorate: filter.directorate
    })
  }

  const handleChangeFilterLocation = (e) => {
    setIsPopoverOpen(true)
    if (e.target.id === 'location-malaysia') {
      setFilter({
        ...filter,
        location: 'Malaysia'
      })
    } else if (e.target.id === 'location-indonesia') {
      setFilter({
        ...filter,
        location: 'Indonesia'
      })
    }
  }

  const { data: dataServiceCatalog, revalidate: revalidateCatalog } = qore.view("allServiceCatalog").useListRow({
    p: search,
    overrideLimit: true,
    '$by.orderItem': 'asc',
  });

  useEffect(() => {
    history.push({ search: params.toString() });
    revalidateDirectorateData()
    // eslint-disable-next-line
  }, [activeKey, isListView, filter])

  const handleChangeActiveKey = (item) => {
    setActiveKey(item)
    //history.push({ search: params.toString() });
    revalidateDirectorateData()
  }

  const { data: dataDirectorateServices, revalidate: revalidateDirectorateData } = qore.view("allCategory2AppService").useListRow({
    subCategory1: activeKey.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }),
    overrideLimit: true
  });

  const settings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          centerMode: false,
        },
      },
    ],
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-btn-prev",
      prevEl: ".swiper-btn-next",
    },
  };

  return (
    <Container className='ptb--50 ptb-md--80 ptb-sm--30 bg_color--1 pb--50' onClick={() => { if (isPopoverOpen) setIsPopoverOpen(!isPopoverOpen) }}>
      {isTabletOrMobile ?
        <>
          {search ?
            null
            :
            <SlickSlider
              settings={settings}
              style={{ width: '100%' }}
            >
              {listCategory?.map((category) => {
                return (
                  <Row sm={12} md={12} lg={12} key={category.key}>
                    {category?.key === activeKey ?
                      <Button
                        onClick={() => { handleChangeActiveKey(category.key); setIsPopoverOpen(false) }}
                        style={{ borderBottom: '4px solid blue', borderRadius: '0px', borderTop: '0px', borderRight: '0px', borderLeft: '0px', backgroundColor: 'white', color: 'blue', margin: '0px 12px', width: '100%', fontSize: '10px' }}
                      >
                        <strong>{category.name}</strong>
                      </Button>

                      :
                      <Button
                        onClick={() => { handleChangeActiveKey(category.key); setIsPopoverOpen(false) }}
                        style={{ borderBottom: '4px solid lightGrey', borderRadius: '0px', borderTop: '0px', borderRight: '0px', borderLeft: '0px', backgroundColor: 'white', color: 'black', margin: '0px 12px', width: '100%', fontSize: '10px' }}
                      >
                        <strong>{category.name}</strong>
                      </Button>
                    }
                  </Row>
                )
              })}
            </SlickSlider>
          }
        </>
        :
        <Row>
          <Col md={12} lg={12}>
            {search ?
              null
              :
              <Nav variant="pills" className="mb-3">
                {listCategory?.map((category) => {
                  return (
                    <Nav.Item key={category.key}>
                      {activeKey === category.key ?
                        <Nav.Link eventKey={category.key} onClick={() => { handleChangeActiveKey(category.key); setIsPopoverOpen(false) }} style={{ borderBottom: '4px solid blue', borderRadius: '0px', backgroundColor: 'white', color: 'blue' }}>
                          <strong>{category.name}</strong>
                        </Nav.Link>
                        :
                        <Nav.Link eventKey={category.key} onClick={() => { handleChangeActiveKey(category.key); setIsPopoverOpen(false) }} style={{ borderBottom: '4px solid lightGrey', borderRadius: '0px', backgroundColor: 'white' }}>
                          {category.name}
                        </Nav.Link>
                      }
                    </Nav.Item>
                  )
                })}
              </Nav>
            }
          </Col>
        </Row>
      }
      <Row style={{ marginTop: '10px' }}>
        <Col md={6} lg={6} sm={6} xs={4}>
          <Popover
            isOpen={isPopoverOpen}
            onClick={() => setIsPopoverOpen(true)}
            positions={['bottom', 'top', 'right', 'left']}
            content={
              <Card>
                <Card.Body>
                  <p style={{ fontSize: '10px', margin: '0px' }}>Directorate</p>
                  <Form>
                    {dataDirectorateServices.map((item) => {
                      return (
                        <Form.Check
                          type='checkbox'
                          id={`${item.name}`}
                          onChange={(e) => handleChangeFilterDirectorate(e)}
                          label={item.name}
                          checked={filter.directorate.includes(item.name)}
                          key={item.name}
                        />
                      )
                    })}
                    <hr />

                    <p style={{ fontSize: '10px', margin: '0px' }}>Location</p>
                    <div className="mb-0">
                      <Form.Check
                        type='radio'
                        id='location-indonesia'
                        label='Indonesia'
                        checked={filter.location === 'Indonesia' ? true : false}
                        onChange={(e) => handleChangeFilterLocation(e)}
                      />
                    </div>
                    <div className="mb-0">
                      <Form.Check
                        type='radio'
                        id='location-malaysia'
                        label='Malaysia'
                        checked={filter.location === 'Malaysia' ? true : false}
                        onChange={(e) => handleChangeFilterLocation(e)}
                      />
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            }
          >
            <Button onClick={() => setIsPopoverOpen(!isPopoverOpen)} style={{ backgroundColor: 'white', color: 'grey', borderColor: 'grey' }}>
              <i className="fa fa-filter"></i> Filter
            </Button>

          </Popover>
        </Col>
        <Col md={4} lg={5} sm={4} xs={4} style={{ marginLeft: 'auto', padding: '0px', marginRight: '0px' }}>
          <div style={{ display: 'flex', borderWidth: '2px' }}>
            <input
              autoFocus
              type={'text'}
              placeholder={'Search'}
              value={search}
              style={{ width: '80%' }}
              onChange={(e) => {
                setSearch(e.target.value)
                revalidateCatalog()
              }}
            />
            {search &&
              <i className="fa fa-close" onClick={() => setSearch('')} style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}></i>
            }
          </div>
        </Col>
        <Col md={2} lg={1} sm={2} xs={2} style={{ margin: 'auto', display: 'flex', justifyContent: 'end' }}>
          {!isTabletOrMobile && 'View'}
          &nbsp;<i className="fa fa-th" style={{ color: isListView ? 'lightGrey' : 'black', margin: 'auto' }} onClick={() => setIsListView(false)}></i>
          &nbsp;&nbsp;<i className="fa fa-reorder" style={{ color: isListView ? 'black' : 'lightGrey', margin: 'auto' }} onClick={() => setIsListView(true)}></i>
        </Col>
      </Row>

      {search ?
        dataServiceCatalog.length > 0 ?
          <Row className="mt--n10">
            {dataServiceCatalog?.map((item) => {
              return (
                <Col xs={3} sm={3} md={2} lg={1} className="mt--30 move-up wow">
                  <PortfolioItem
                    title='{portfolio.title}'
                    thumb={require('../../../assets/img/portfolio/grid/vision.png')}
                    data={item}
                  />
                </Col>
              )
            })}
          </Row>
          : <p>Apps tidak ditemukan</p>
        :
        <>
          {activeKey === 'coreBusinessApps' && <AppServiceList subCategory1='Core Business Apps' filter={filter} isListView={isListView} />}
          {activeKey === 'corporateApps' && <AppServiceList subCategory1='Corporate Apps' filter={filter} isListView={isListView} />}
          {activeKey === 'publicFacingApps' && <AppServiceList subCategory1='Public Facing Apps' filter={filter} isListView={isListView} />}
        </>
      }
    </Container>
  );
};

export default ApplicationServices;
