import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PortfolioItem from "../../../../components/portfolio/PortfolioItemOne";

const PortfolioGrid = ({ wide, ourWorks }) => {
  return (
    <div
      className="brook-portfolio-area bg_color--1"
      style={{ marginBottom: "4rem" }}>
      <Container fluid={!!wide} className={wide ? "plr--30" : ""}>
        <Row style={{ padding: "2rem 0" }}>
          <Col lg={12}>
            <h3>Our Work</h3>
          </Col>
        </Row>
        <Row className="mt--n30">
          {ourWorks.map((item) => (
            <Col sm={4} lg={3} key={item.id} className="mt--30">
              <PortfolioItem title={item.name} thumb={item.displayImage} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default PortfolioGrid;
