import React, { Fragment } from 'react'
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/banner/BannerPageOne";
import LayoutDefaultParaportal from '../../layouts/LayoutDefaultParaportal'
import img from "../../../assets/img/paraportal/mascot-02.png";
import TabFaq from './tab/TabFaq';

export default function FaqParaportal() {
  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - FAQ</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <Breadcrumb title="FAQ" color="#a5a6eb" img={img} />
        <TabFaq />
      </LayoutDefaultParaportal>
    </Fragment>
  )
}