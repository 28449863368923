import React from "react";
import { Container} from "react-bootstrap";

export default function Link() {

    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
                Coming Soon
            </Container>
        </>
    );
}
