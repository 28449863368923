import React, { useState, useEffect, useMemo } from "react";
import { Container } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import qore from "../../../../qoreContext";
import FilterComponent from "../FilterComponent";

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
          backgroundColor: '#EBF3FF',
          fontSize: '16px',
          fontWeight: 'bold'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const KpiCatalogue = () => {

  const [pending, setPending] = useState(true)
  const [dataTable, setDataTable] = useState([])

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [filterText, setFilterText] = useState('')

  const { data: dataTableRaw } = qore.view("allKpiCatalogue").useListRow({
    overrideLimit: true,
  });
  
  const filteredItems = dataTableRaw.filter(item =>
    (item.kpiName && item.kpiName.toLowerCase().includes(filterText.toLowerCase())) || 
    (item.kpiDefinition && item.kpiDefinition.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.calculationLogic && item.calculationLogic.toLowerCase().includes(filterText.toLowerCase())),
  )

  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText} 
        placeholder="Search KPI"
      />
		);
	}, [filterText, resetPaginationToggle]);

  useEffect(() => {
    const timeout = setTimeout(() => {
			setDataTable(filteredItems);
			setPending(false);
		}, 1000);
		return () => clearTimeout(timeout);
  }, [filteredItems])

  const columns = [
    {
        name: 'KPI Name',
        selector: row => row.kpiName,
        sortable: true,
        wrap : true
    },
    {
        name: 'KPI Definition',
        selector: row => row.kpiDefinition,
        sortable: true,
        wrap : true
        
    },
    {
        name: 'Calculation Logic',
        selector: row => row.isNoHtml ?
        row.calculationLogicData :
          <span dangerouslySetInnerHTML={{
            __html: row.calculationLogicData
            }}
          />,
        wrap: true,
    },
    /* {
      name: "Actions",
      cell: () => <Button>Action</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }, */
  ];

  return (
    <Container className='ptb--80 ptb-md--80 ptb-sm--60 bg_color--1 pb--50'>
      <DataTable
          columns={columns}
          data={dataTable}
          pointerOnHover
          highlightOnHover
          pagination
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={pending}
          customStyles={customStyles}
        />
    </Container>
  );
};

export default KpiCatalogue;
