import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IconHome from "../../../../assets/paraportal/icon-home.svg";
import IconPhone from "../../../../assets/paraportal/icon-phone.svg";
import IconMail from "../../../../assets/paraportal/icon-mail.svg";

const ContactUs = ({
  addressTitle,
  addressDetail,
  contactEmail,
  contactPhone,
}) => {
  return (
    <div style={{ marginBottom: "5rem" }}>
      <Container
        style={{
          minHeight: "5rem",
        }}>
        <Row>
          <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
            <h3
              style={{
                fontWeight: "700",
              }}>
              Contact Us
            </h3>
          </Col>
          <Col lg={4}>
            <Row>
              <Col lg={2} style={{ paddingRight: "0", textAlign: "right" }}>
                <img src={IconHome} alt="icon-home" />
              </Col>
              <Col lg={10}>
                <p>{addressTitle}</p>
                <p>{addressDetail}</p>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <Row style={{ marginBottom: "0.5rem" }}>
              <Col lg={2} style={{ paddingRight: "0", textAlign: "right" }}>
                <img src={IconMail} alt="icon-mail" />
              </Col>
              <Col lg={10}>
                <p>{contactEmail}</p>
              </Col>
            </Row>
            <Row>
              <Col lg={2} style={{ paddingRight: "0", textAlign: "right" }}>
                <img src={IconPhone} alt="icon-phone" />
              </Col>
              <Col lg={10}>
                <p>{contactPhone}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
