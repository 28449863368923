import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import PortfolioOneParaportal from '../portfolio/homes/PortfolioOneParaportal';
import AccordionEvent from '../../pages/info/Event/AccordionEvent'

export default function UpcomingEvent() {
    return (
        <div className="bk-blog-area section-ptb-50 clearfix move-up wow">
            <Container>
                <Row>
                    <Col lg={8}>
                        <PortfolioOneParaportal />
                    </Col>
                    <Col lg={4}>
                        <div className="section-title--between wow move-up">
                            <h3 className="heading heading-h3 mb-3" style={{ 'color' : '#215AA8' }}>Upcoming Event</h3>
                        </div>
                        <AccordionEvent limit='2'/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
