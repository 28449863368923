import React from 'react';

const IconListItemOneParaportal = ({ data }) => {
    return (
        <div className="single-motive-speech wow move-up mt--30">
            <div className="icon">
                <img src={require(`../../assets/CulturePage/icon/${data.imgIcon}`)} alt="flexible"/>
            </div>
            <div className="content">
                <h4 style={{ 'color': '#215AA8' }}>{data.title}</h4>
                <p className="bk_pra heading-font pr--40" style={{ 'color': '#215AA8' }}>{data.content}</p>
            </div>
        </div>
    );
};

export default IconListItemOneParaportal;
