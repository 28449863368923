import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import qore from "../../../../qoreContext";
import ListServiceCatalog from './ListServiceCatalog';
import ListServiceCatalogListView from "./ListServiceCatalogListView";
import { useMediaQuery } from 'react-responsive'

const AppServiceList = ({ wide, subCategory1, filter, isListView }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const { data: dataDirectorateServices } = qore.view("allCategory2AppService").useListRow({
    subCategory1: subCategory1,
    overrideLimit: true
  });

  return (
    <div className="brook-portfolio-area pb--100 ptb-md--40 ptb-sm--0 bg_color--1">
      <Container fluid={!!wide} className={wide ? 'plr--30' : ''}>
        <div className="mt--20" />
        {filter.directorate.length > 0 ?
          isListView && !isTabletOrMobile ?
            <Row>
              {filter.directorate?.map((dir) => {
                return (
                  <Col xs={3} sm={3} md={3} lg={3} style={{ margin: '0px', padding: '0px' }} key={dir}>
                    <ListServiceCatalogListView subCategory1={subCategory1} directorate={dir} filter={filter} isListView={isListView} />
                  </Col>
                )
              })}
            </Row>
            :
            filter.directorate?.map((dir) => {
              if (isListView)
                // LIST VIEW
                return (
                  <ListServiceCatalogListView subCategory1={subCategory1} directorate={dir} filter={filter} isListView={isListView} key={dir} />
                )
              else
                return (
                  <div className="mb--20" key={dir}>
                    <ListServiceCatalog subCategory1={subCategory1} directorate={dir} filter={filter} isListView={isListView} />
                  </div>
                )
            })
          :
          isListView && !isTabletOrMobile ?
            <Row>
              {dataDirectorateServices?.map((dir) => {
                return (
                  <Col xs={3} sm={3} md={3} lg={3} style={{ margin: '0px', padding: '0px' }} key={dir.name}>
                    <ListServiceCatalogListView subCategory1={subCategory1} directorate={dir.name} filter={filter} isListView={isListView} />
                  </Col>
                )
              })}
            </Row>
            :
            dataDirectorateServices?.map((dir) => {
              if (isListView)
                // LIST VIEW MOBILE
                return (
                  <ListServiceCatalogListView subCategory1={subCategory1} directorate={dir.name} filter={filter} isListView={isListView} key={dir.name} />
                )
              else
                // LIST GRID
                return (
                  <div className="mb--20" key={dir.name}>
                    <ListServiceCatalog subCategory1={subCategory1} directorate={dir.name} filter={filter} isListView={isListView} />
                  </div>
                )
            })
        }
      </Container>
    </div>
  );
};


export default AppServiceList;
