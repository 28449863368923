import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const BannerPageOne = ({
  title,
  subtitle,
  backgroundColor,
  img,
  style,
  titleColor,
}) => {
  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundColor,
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      className="breadcrumb-area breadcrumb-title-bar breadcrumb-title-white">
      {/* className="breadcrumb-area ptb--100 ptb-md--150 ptb-sm--150 breadcrumb-title-bar breadcrumb-title-white"> */}
      <Container fluid>
        <Row>
          <Col lg={6}>
            <div style={{ padding: "0 10rem" }}>
              <img src={img} alt="logo" />
            </div>
          </Col>
          <Col
            lg={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div className="breadcrumb-inner text-left mt_sm--50 mt_md--50">
              <h1
                className="heading heading-h1"
                style={{ color: titleColor || "#fefefe", marginBottom: "1.5rem" }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p className="text-white" style={{
                fontSize: '24px'
              }}>
                {subtitle}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BannerPageOne;
