import React, { useState, useEffect } from "react";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import qore from "../../../qoreContext";
import { Link } from "react-router-dom";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { onHandleGtmViewHrsc } from "../../../helper/gtm";

const animatedComponents = makeAnimated();

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let valueArea = paramsVal.get('filterArea');

const options = [
  { value: 0, label: 'Head Office' },
  { value: 1, label: 'Distribution Center' },
  { value: 2, label: 'Plant' }
]

const Searchone = ({ title, subtitle, color, style, filterAreaChange, filterArea }) => {
  const [search, setSearch] = useState()
  const [areaKerja, setAreaKerja] = useState([])
  const [defaultValue, setDefaultValue] = useState(filterArea)
  const { data: dataHrscSearch, revalidate } = qore.view("hrscWithFilter").useListRow({
    p: search,
    areaKerja
  })

  const changeFilter = (e) => {
    setAreaKerja(e.map((label) => {
      return label.label
    }))
    setDefaultValue(e.map((index) => {
      return index.label
    }))
  }

  useEffect(() => {
    filterAreaChange(defaultValue)
    setAreaKerja(defaultValue)
  }, [defaultValue, filterAreaChange])

  var arr1 = valueArea ? valueArea.split(",") : defaultValue

  var resu = arr1.map((a) =>
    a === "Head Office" ? options[0] :
    a === "Distribution Center" ? options[1] :
    a === "Plant" ? options[2] :
    options[3]
  )

  const trackHrscGtm = (data) => {
    const temp = {...data}
    temp.title = `${data?.title} | ${data?.areaKerja?.displayField}`
    onHandleGtmViewHrsc(temp);
  } 

  return (
    <div
      style={{ ...style, backgroundColor: color }}
      className="ptb--50 ptb-md--150 ptb-sm--150">
      <Container>
        <div className=" ">
          <h1
            className="heading heading-h1 text-white"
            style={{ fontSize: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p className="text-white">{subtitle}</p>
          <div>
            <Row>
              <Col lg={8}>
                <div className="contact-form">
                  <input
                    autoFocus
                    type="text"
                    placeholder="Search HRSC information"
                    style={{ height: '55px', marginInline: '10px', borderRadius: "5px" }}
                    onChange={(e) => {
                      setSearch(e.target.value)
                      revalidate()
                    }}
                  />
                </div>
                <ListGroup style={{ marginTop: '5px', marginLeft: '15px', backgroundColor: 'white', maxHeight: '150px', overflowY: 'scroll' }}>
                  {dataHrscSearch.map((item) => {
                    return (
                      <div>
                        {search.length > 0 ? (
                          <Link to={`${`${process.env.PUBLIC_URL}/hrsc-article/${item?.areaKerja.displayField}/${item?.title}`}`}
                            style={{ color: 'black' }}
                            onClick={() => trackHrscGtm(item)}
                          >
                            <ListGroup.Item >{item?.title} | {item?.areaKerja?.displayField}</ListGroup.Item>
                          </Link>
                        ) : (
                          null
                        )}
                      </div>
                    )
                  })}
                </ListGroup>
              </Col>
              <Col lg={4}>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={options}
                  defaultValue={resu}
                  onChange={changeFilter}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Searchone;

