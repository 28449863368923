import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { LightgalleryProvider } from 'react-lightgallery';
import YouTube from 'react-youtube';
import qore from "../../../../qoreContext";

export default function OurVideo() {

    const { data: dataOurVideos } = qore.view("ourVideosCultureShow").useListRow({});

    const opts = {
        height: '200',
        width: '350',
        playerVars: {
            autoplay: 0,
        },
    };
    return (
        <div style={{ 'backgroundColor' : '#1f5aa8' }}>
            <Container className='ptb--80 ptb-md--80 ptb-sm--60 text-white'>
                <div className="brook-section-title mb--50 mb_sm--20 mb_md--20 wow move-up">
                    <h3 className="heading heading-h3 text-white">Our Video</h3>
                </div>
                <Row>
                    <Col xs={12}>
                        <div className="gallery-wrapper gallery-grid">
                            <LightgalleryProvider>
                                {dataOurVideos.length ? dataOurVideos.map((dataVideo)=>(
                                    <div key={dataVideo.id}>
                                        <YouTube opts={opts} videoId={dataVideo.youtubeId} />
                                        <p className='text-center h5 text-white'>{dataVideo.title}</p>
                                    </div>
                                )): null}
                            </LightgalleryProvider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
