import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import LayoutDefaultParaportal from "../../../layouts/LayoutDefaultParaportal";

import { useParams } from 'react-router-dom';
import NewsDetailContentMDM from './NewsDetailContentMDM';

const NewsDetailMDM = () => {
    const { id } = useParams();
    return (
        <Fragment>
            <Helmet>
                <title>PARAPORTAL - News</title>
            </Helmet>
            <LayoutDefaultParaportal className="template-color-1 template-font-1">
                <NewsDetailContentMDM sidebar={false} idNews={id} />
            </LayoutDefaultParaportal>
        </Fragment>
    );
};

export default NewsDetailMDM;
