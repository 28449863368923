import { Card, Accordion, Badge } from 'react-bootstrap';
import React from 'react';
import qore from "../../../../qoreContext";
import HRSCListContent from './HRSCListContent';


const ChildContent = ({ id }) => {
  const { data: dataHRSC_child } = qore.view("allCategoryHrsc").useListRow({
    parentID: id,
    overrideLimit: true
  });
  return (
    <div>
      <Accordion>
        {dataHRSC_child?.map((item) => (
          <Card className="mb-1 border-0 card__accordion" style={{ maxHeight: '20%' }}>
            <Card.Header style={{ backgroundColor: 'white', borderBottom: '0px', padding: '0px 18px' }}>
              <Accordion.Toggle
                eventKey="0" style={{ border: '0px' }}>
                <Badge pill bg="dark" style={{ color: 'grey' }}>
                  &#9658;
                </Badge> {item?.name}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body style={{ padding: '0rem 1.25rem' }}>
                <HRSCListContent id={item.id} />
                {/* <HRSCListContent/> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
}

export default ChildContent;

// import { Container, Card, Row, Col, Button, Accordion, Badge } from 'react-bootstrap';
// import React from 'react';
// import CardItems from './CardItems';
// import CardContent from './CardContent';
// import HRSCListContent from './HRSCListContent';


// const ChildContent = ({ id }) => {
//     const { data: dataHRSC_child } = qore.view("allCategoryHrsc").useListRow({
//         parentID: id
//     });
//     return (
//         <div style={{
//             margin: '-2%'
//         }}>
//             {dataHRSC_child?.map((item) => (

//                 <Accordion>
//                     <Card className="border-0" style={{ maxHeight: '20%' }}>
//                         <Card.Header style={{ backgroundColor: 'white', height: '50%' }}>
//                             <Accordion.Toggle className="font-weight-bold" as={Button}
//                                 variant="Dark" eventKey="0" style={{marginLeft: "-20%" , fontSize: '12px', height: '50%' }}>
//                                 <Badge pill bg="dark">
//                                     >
//                                 </Badge> {item?.name}
//                             </Accordion.Toggle>
//                         </Card.Header>
//                         <Accordion.Collapse eventKey="0">
//                             <Card.Body>


//                                 <HRSCListContent id={item.id} />

//                                 {/* <HRSCListContent/> */}
//                             </Card.Body>
//                         </Accordion.Collapse>
//                     </Card>
//                 </Accordion>
//             ))}
//         </div>
//     );
// }

// export default ChildContent;