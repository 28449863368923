import React from "react";
import IconFile from "../../../../../assets/paraportal/icon-file.svg";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { onHandleGtmViewHrsc } from "../../../../../helper/gtm";

export default function ArticleLink({ title, workArea }) {
  const trackHrscGtm = (title) => {
    onHandleGtmViewHrsc({ title: `${title} | ${workArea}` });
  };
  return (
    <Link
      to={`${process.env.PUBLIC_URL}/hrsc-article/${workArea}/${title}`}
      onClick={() => trackHrscGtm(title)}
    >
      <Card
        style={{
          border: "0px",
          padding: "0.25rem 0",
        }}>
        <div
          style={{
            display: "flex",
          }}>
          <div
            style={{
              width: "10%",
            }}>
            <img
              src={IconFile}
              alt="icon"
              style={{
                height: "1em",
                width: "auto",
              }}
            />
          </div>
          <p
            style={{
              width: "90%",
              textAlign: "left",
            }}>
            {title}
          </p>
        </div>
      </Card>
    </Link>
  );
}
