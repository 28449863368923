import React from "react";
import { Button } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";

const listCategory = [
  {
    icon : 'fa fa-table',
    name : 'Master Data',
  },
  {
    icon : 'fa fa-list-ul',
    name : 'Glossary',
  },
  {
    icon : 'fa fa-newspaper-o',
    name : 'Newsletter',
  },
  {
    icon : 'fa fa-address-book-o',
    name : 'Contact Us',
  },
  {
    icon : 'fa fa-heart-o',
    name : 'Favorites',
  }
]

const ListCatMDM = ({ title, subtitle, color, style, handleChangeCategory, category }) => {
  return (
    <div
      style={{ ...style, backgroundColor: color }}
      className="breadcrumb-area ptb--50 ptb-md--150 ptb-sm--150 breadcrumb-title-bar breadcrumb-title-white">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="breadcrumb-inner text-left mt_sm--50 mt_md--50">
              <h3
                className="heading heading-h3 text-white"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p className="text-white">{subtitle}</p>
            </div>
            <div className="mt-5">
              {listCategory.map((cat) => {
                return(
                  cat?.name === category ?
                    <Button
                      key={cat.name}
                      onClick={() => handleChangeCategory(cat?.name)}
                      style={{marginRight:'10px', borderRadius:'10px', backgroundColor:'#215AA8'}}            >
                      <i className={cat.icon} />  
                      &nbsp;&nbsp;{cat?.name}
                    </Button>
                  : 
                    <Button
                      key={cat.name}
                      onClick={() => handleChangeCategory(cat?.name)}
                      style={{marginRight:'10px', borderRadius:'10px', backgroundColor:'white', color:'darkblue'}}                     >
                      <i className={cat.icon} />
                      &nbsp;&nbsp;{cat?.name}
                    </Button>              
                )
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListCatMDM;
