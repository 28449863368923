import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import PortfolioItem from "../../../components/portfolio/PortfolioItemOne";

const PortfolioGridParaportal = ({ wide }) => {

  const vision = '<h4 class="heading" style="text-align: center;"><span style="color: rgb(255, 255, 255);">VISION</span></h4><p>&nbsp;</p><div class="text" style="text-align: center;"><span style="color: rgb(255, 255, 255);">Menjadi perusahaan yang berkomitmen untuk memiliki pengelolaan terbaik dan berkembang terus menerus dengan bersama-sama menjadikan hari ini lebih baik dari hari kemarin melalui produk berkualitas yang memberikan manfaat bagi Paragonian, mitra, masyarakat, dan lingkungan.</span></div>'

  const mission = '<h4 class="heading" style="text-align: center;"><span style="color: rgb(255, 255, 255);">MISSION</span></h4><p>&nbsp;</p><div class="text" style="text-align: center;"><ol><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Mengembangkan Paragonian</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Menciptakan kebaikan untuk pelanggan</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Perbaikan berkesinambungan</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Tubuh bersama-sama</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Memelihara bumi</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Mendukung pendidikan dan kesehatan bangsa</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Mengembangkan bisnis</span></li></ol></div>'

  const value = '<h4 class="heading" style="text-align: center;"><span style="color: rgb(255, 255, 255);">VALUE</span></h4><p>&nbsp;</p><div class="text" style="text-align: center;"><ol><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Ketuhanan</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Kepedulian</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Kerendahan hati</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Ketangguhan</span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Inovasi</span></li></ol></div>'

  return (
    <div className="brook-portfolio-area pb--100 ptb-md--80 ptb-sm--60 bg_color--1">
      <Container fluid={!!wide} className={wide ? 'plr--30' : ''}>
        <Row className="mt--n30">
          <Col sm={6} lg={4} className="mt--30 move-up wow">
            <PortfolioItem
              title={vision}
              thumb={require('../../assets/img/portfolio/grid/vision.png')}
            />
          </Col>
          <Col sm={6} lg={4} className="mt--30 move-up wow">
            <PortfolioItem
              title={mission}
              thumb={require('../../assets/img/portfolio/grid/mision.jpg')}
            />
          </Col>
          <Col sm={6} lg={4} className="mt--30 move-up wow">
            <PortfolioItem
              title={value}
              thumb={require('../../assets/img/portfolio/grid/value.jpg')}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PortfolioGridParaportal;
