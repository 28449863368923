import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import LayoutDefaultParaportal from "../../../../layouts/LayoutDefaultParaportal";
import BreadcrumbsMDM from "../BreadcrumbsMDM";
import BosMDM from "./content/bosMDM/BosMDM";
import ProductMDM from "./content/productMDM/ProductMDM";
import BomMDM from "./content/bomMDM/BomMDM";
import VendorMDM from "./content/vendorMDM/VendorMDM";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabUpstream');
let subTabUpstream = paramsVal.get('subTabUpstream');

const Upstream = () => {
  const [category, setCategory] = useState(tab ? tab : 'Product');
  const history = useHistory();
  const params = new URLSearchParams({ tabUpstream: category, subTabUpstream : subTabUpstream ? subTabUpstream : 'Link'});

  const handleChangeCategory = (item) => {
    setCategory(item)
  }

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [category])

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Master Data Management | Upstream</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <BreadcrumbsMDM
          title="Upstream"
          //subtitle="List tableau, redash, superset corporate" 
          color="#6A8AFC"
          handleChangeCategory={handleChangeCategory}
          category={category}
        />
        {category === 'Product' && <ProductMDM tabMDM={category} />}
        {category === 'Bills of Supply' && <BosMDM tabMDM={category} />}
        {category === 'Bills of Material' && <BomMDM tabMDM={category} />}
        {category === 'Vendor' && <VendorMDM tabMDM={category} />}
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default Upstream;