import React from "react";

const ResponsiveImage = ({ children, className, thumb, style, alt = "" }) => {
  return (
    <div
      className={`text-left wow move-up ${className && className}`}
      style={style}>
      <img
        src={thumb}
        alt={alt}
        style={{
          width: "100%",
          height: "auto",
        }}
      />
      {children}
    </div>
  );
};

export default ResponsiveImage;
