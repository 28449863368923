import React from 'react'

export default function CompReseorce({title, link}) {
  return (
    <>
        <p>{title}</p>
        <a href={link} rel="noopener noreferrer" target="_blank" style={{ "color": "blue", "textDecoration" : "underline" }}>{link}</a>
    </>
  )
}
