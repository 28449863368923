import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import qore from "../../../../../../../../qoreContext";
import LoveComp from "../../../../../../BusinessIntelligence/report/LoveComp";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Link() {

  const { data: allUpstream } = qore.view("allUpstream").useListRow({
    category: "bom",
  });

  return (
    <>
      <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
        <Row className="">
          {allUpstream.length ? allUpstream?.map((dashboard) => (
            <Col xs md="3" className="mt--25" key={dashboard.id}>
              <Card>
                <Card.Img
                  variant="top"
                  src={dashboard.image}
                  alt={dashboard.title}
                />
                <div className="p-3">
                  <a href={dashboard.link} target="_blank" rel="noopener noreferrer">
                    <p style={{ fontWeight: "500" }}>{dashboard.title}</p>
                  </a>
                  <small>
                    <LoveComp valuedashboard={
                      {
                        "id": dashboard.id,
                        "title": dashboard.title,
                        "category": dashboard.category,
                        "link": dashboard.link,
                        "table": dashboard.table,
                      }
                    } />
                    &nbsp; add to favorites
                  </small>
                </div>
              </Card>
            </Col>
          )) :
            <Row>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
            </Row>
          }
        </Row>
      </Container>
    </>
  );
}
