import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import LayoutDefaultParaportal from '../../layouts/LayoutDefaultParaportal'
import dataMetaHomepage from './dataMetaHomepage.json'
import FlexibleImageSliderOneParaportal from '../../container/flexible-image-slider/FlexibleImageSliderOneParaportal'
import News from "../../pages/info/News/NewsSegmentation";
import UpcomingEvent from '../../container/upcoming-event/UpcomingEvent'
import PortfolioGridParaportal from '../../container/visi-misi/PortfolioGridParaportal'
import MapOneParaportal from '../../container/maps/MapOneParaportal'

export default function HomepageParaportal() {
    return (
        <Fragment>
            <Helmet>
                <title>{`PARAPORTAL - ${dataMetaHomepage.title}`}</title>
            </Helmet>
            <LayoutDefaultParaportal className="template-color-1 template-font-1">
                <FlexibleImageSliderOneParaportal />
                <News view="allNews" title="Update highlights" />
                <UpcomingEvent />
                <PortfolioGridParaportal />
                <MapOneParaportal />
            </LayoutDefaultParaportal>
        </Fragment>
    )
}
