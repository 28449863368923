import HeaderOne from '../components/header/HeaderOne';
import FooterTwoParaportal from '../container/footer/FooterTwoParaportal';
import React from 'react';
import PopupMobileMenu from './PopupMobileMenu';
import PageContainerParaportal from '../container/page';

export default function LayoutDefaultParaportal({ children, className, revealFooter }) {
  return (
    <>
      <PageContainerParaportal className={className} revealFooter={revealFooter}>
        <PopupMobileMenu />
        <HeaderOne />
        {children}
        <FooterTwoParaportal />
      </PageContainerParaportal>
    </>
  )
}
