import React, { useState, useEffect } from 'react';
import { Container } from "react-bootstrap";
import WithOutSidebarLayoutMDM from "../../../pages/MasterDataManagement/Newsletter/WithOutSidebarLayoutMDM";
import { matchSorter } from "match-sorter"
import qore from "../../../../qoreContext";

const NewsletterMDM = () => {
    const [listNews, setListNews] = useState()
    const { data: dataAllNews } = qore.view("allNewsMdm").useListRow({
        overrideLimit: true,
    });

    useEffect(() => {
        let temp = []
        dataAllNews.forEach(p => {
            temp.push({
                'idQore': p.id,
                'id': p.orderNumber,
                'title': p.title,
                'thumb': p.tumbnail,
                'videoId': false,
                'dateCreated': p.dateCreated,
                'meta': {
                    'author': 'Paragon',
                    'category': p.tag,
                    'postDate': p.dateCreated,
                }
            })
        });
        temp = matchSorter(temp, "", { keys: ["dateCreated"] });
        temp.reverse()
        setListNews(temp)
    }, [dataAllNews])


    return (
        <div className="bk-blog-grid-area bg_color--5 section-ptb-150">
            <Container>
                <WithOutSidebarLayoutMDM data={listNews} />
            </Container>
        </div>
    );
};

export default NewsletterMDM;