import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'
import CompReseorce from '../../../components/CompReseorce'
import FAQ from '../content/resContent/FAQ'

const accordionContent = [
    {
        "id": 1,
        "title": "Training",
        "content": <CompReseorce title="Video Training Bills of Supply" link="https://cloud.pti-cosmetics.com/index.php/s/iQsiqLKfM7t8FJd"/>
    },
    {
        "id": 2,
        "title": "Post Test",
        "content": <CompReseorce title="Link post test Bills of Supply" link="https://drive.google.com/drive/folders/1yAdeihv_-UUQoKZ8CqEfmDqJuypADj06?usp=sharing"/>
    },
    {
        "id": 3,
        "title": "Flow",
        "content": <CompReseorce title="Link flow Bills of Supply" link="https://drive.google.com/drive/folders/1UKh8oykKpPeL99fB7v5hQKDPq-adcEZJ?usp=sharing"/>
    },
    {
        "id": 4,
        "title": "User Guide",
        "content": <CompReseorce title="Link user guide Bills of Supply" link="https://docs.google.com/document/d/1f_eHlmr3vD0Nqxlz9_HxTS692eYORvX1W7PUiaDphXo/edit?usp=sharing"/>
    },
    {
        "id": 5,
        "title": "FAQ",
        "content": <FAQ />
    },
]

export default function Resource() {
    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
                <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                    {accordionContent.map(accordion => (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                    {accordion.title}
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={accordion.id}>
                                <Card.Body>{accordion.content}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Container>
        </>
    )
}
