import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import LayoutDefault from "../../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderOne";
import Footer from "../../../container/footer/FooterOne";
import {Container, Card} from 'react-bootstrap'
import Cookies from "universal-cookie";

const AllNews = () => {
  const cookies = new Cookies();
  return (
    <Fragment>
      <Helmet>
        <title>Event</title>
      </Helmet>
      <LayoutDefault className="template-color-1 template-font-1">
        <Header />
        <Container>
          <Card style={{margin: '5em 15em', borderRadius:'20px'}}>
            <div className={`message-box mt--0`} style={{background:'blue', borderRadius:'20px'}}>
                <div className="content" style={{fontSize:'35px', fontWeight:'600', color: 'white'}}>
                  Paraversa Member Card
                </div>

                <div className="content" style={{fontSize:'20px', fontWeight:'300', color: 'white', margin:'30px 0px'}}>
                  {cookies.get('userloggedin')?.display_name}
                </div>
            </div>
          </Card>
        </Container>
        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};

export default AllNews;
