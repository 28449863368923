import React from 'react'

export default function ImageSliderParaportal({image, classes}) {
    return (
        <div className={`flexible-image ${classes ? classes : ''}`}>
            <div className="thumb">
                <img src={image} alt="flexible"/>
            </div>
        </div>
    );
}
