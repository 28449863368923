import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import PortfolioItem from "./PortofolioItem";
import { useMediaQuery } from 'react-responsive'
import qore from "../../../../qoreContext";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ListServiceCatalog = ({ wide, subCategory1, directorate, filter, isListView }) => {
  const [isSeeMore, setIsSeeMore] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const { data: dataServiceCatalog } = qore.view("allServiceCatalog").useListRow({
    subCategory1: subCategory1,
    subCategory2: directorate,
    overrideLimit: true,
    location: filter.location,
    '$by.orderItem': 'asc',
  });

  const [display, setDisplay] = useState()


  useEffect(() => {
    setTimeout(() => {
      setDisplay({ display: "none" })
    }, 3000);
  }, [display])

  if (dataServiceCatalog.length > 0) {
    // GRID VIEW
    return (
      <>
        {isTabletOrMobile ?
          <Row>
            <Col xs={8} sm={8} md={8} lg={8} style={{ margin: '0px', padding: '0px' }}>
              <h5 className="heading" style={{ marginBottom: '20px' }}>
                {directorate}
              </h5>
            </Col>
            {dataServiceCatalog.length > 4 &&
              <Col xs={4} sm={4} md={4} lg={4} style={{ margin: '0px', padding: '0px' }}>
                <p style={{ marginBottom: '20px', fontSize: '10px', textAlign: 'end', color: 'blue', cursor: 'pointer' }}
                  onClick={() => setIsSeeMore(!isSeeMore)}
                >
                  See More
                </p>
              </Col>
            }
          </Row>
          :
          <h5 className="heading" style={{ margin: '20px 0px' }}>
            {directorate}
          </h5>
        }
        <Row className="mt--n20">
          {dataServiceCatalog?.map((item, idx) => {
            return (
              <>
                {isTabletOrMobile ?
                  <>
                    {isSeeMore ?
                      <Col xs={3} sm={3} md={2} lg={1} className="mt--5 move-up wow" style={{ padding: '3px' }}>
                        <PortfolioItem
                          title='{portfolio.title}'
                          thumb={require('../../../assets/img/portfolio/grid/vision.png')}
                          data={item}
                        />
                      </Col>
                      :
                      idx < 4 &&
                      <Col xs={3} sm={3} md={2} lg={1} className="mt--5 move-up wow" style={{ padding: '3px' }}>
                        <PortfolioItem
                          title='{portfolio.title}'
                          thumb={require('../../../assets/img/portfolio/grid/vision.png')}
                          data={item}
                        />
                      </Col>
                    }
                  </>
                  :
                  <Col xs={3} sm={3} md={2} lg={1} className="mt--15 move-up wow">
                    <PortfolioItem
                      title='{portfolio.title}'
                      thumb={require('../../../assets/img/portfolio/grid/vision.png')}
                      data={item}
                    />
                  </Col>
                }
              </>
            )
          })}
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row style={display}>
          <Col xs md="3">
            <Skeleton height={100} />
          </Col>
          <Col xs md="3">
            <Skeleton height={100} />
          </Col>
          <Col xs md="3">
            <Skeleton height={100} />
          </Col>
          <Col xs md="3">
            <Skeleton height={100} />
          </Col>
        </Row>
      </>
    )
  }
};


export default ListServiceCatalog;
