import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LayoutDefaultParaportal from "../../../../layouts/LayoutDefaultParaportal";
import BreadcrumbsBI from "../BreadcrumbsBI";
import RedashFinance from "./content/RedashFinance";
import TableauFinance from "./content/TableauFinance";
import SupersetFianance from "./content/SupersetFianance";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabFinanceBI')

const FinanceBI = () => {
  const [category, setCategory] = useState(tab ? tab : 'Tableau');
  const history = useHistory();
  const params = new URLSearchParams({tabFinanceBI: category});
  
  const handleChangeCategory = (item) => {
    setCategory(item);
  };

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [category])

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Business Intelligence | Finance</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <BreadcrumbsBI
          title="Finance"
          //subtitle="List tableau, redash, superset corporate" 
          color="#6A8AFC"
          handleChangeCategory={handleChangeCategory}
          category={category}
        />
        {category === 'Redash' && <RedashFinance />}
        {category === 'Tableau' && <TableauFinance />}
        {category === 'Superset' && <SupersetFianance />}
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default FinanceBI;