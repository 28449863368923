import React, { useState, useEffect } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import RedashBIContent from "./content/RedashBIContent.jsx";
import TableauBIContent from "./content/TableauBIContent.jsx";
import SupersetBIContent from "./content/SupersetBIContent.jsx";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabBITraining');

const listCategory = [
  {
    key: "Tableau",
    name: "Tableau",
  },
  {
    key: "Redash",
    name: "Redash",
  },
  {
    key: "Superset",
    name: "Superset",
  },
];

export default function TrainingBI({ tabBI }) {
  const [activeKey, setActiveKey] = useState(tab ? tab : 'Tableau');
  const history = useHistory();
  const params = new URLSearchParams({ tabBI, tabBITraining: activeKey });

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [activeKey])

  return (
    <>
      <Container className="ptb--50 ptb-md--80 ptb-sm--60 bg_color--1">
        <Tab.Container id="pills-tab" defaultActiveKey={activeKey}>
          <Nav variant="pills" className="mb-3">
            {listCategory?.map((category) => {
              return (
                <Nav.Item key={category.key}>
                  {activeKey === category.key ? (
                    <Nav.Link
                      eventKey={category.key}
                      onClick={() => { setActiveKey(category.key) }}
                      style={{
                        borderBottom: "4px solid blue",
                        backgroundColor: "white",
                        color: "blue",
                      }}
                    >
                      <strong>{category.name}</strong>
                    </Nav.Link>
                  ) : (
                    <Nav.Link
                      eventKey={category.key}
                      onClick={() => setActiveKey(category.key)}
                      style={{
                        borderBottom: "4px solid lightGrey",
                        backgroundColor: "white",
                      }}
                    >
                      {category.name}
                    </Nav.Link>
                  )}
                </Nav.Item>
              );
            })}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="Tableau">
              <TableauBIContent />
            </Tab.Pane>
            <Tab.Pane eventKey="Redash">
              <RedashBIContent />
            </Tab.Pane>
            <Tab.Pane eventKey="Superset">
              <SupersetBIContent />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
}
