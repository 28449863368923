import React, {useEffect, useState} from 'react';
import Comment from "../../../../../../components/comment";
import qore from "../../../../../../qoreContext";
import { matchSorter } from "match-sorter"

const Comments = ({ idNews }) => {
    const [listComment, setListComment] = useState([])

    const { data : dataComment } = qore.view("allNewsComment").useListRow({
        newsId: idNews
    });

    useEffect(() => {
        let temp = []
        let count = 1
        dataComment.forEach(p => {
            temp.push({
                'author' : p.user,
                'authorPic' : 'default_pic.webp',
                'commentDate': p.updatedAt.slice(0,10),
                'id': count,
                'quote': p.comment,
                'reply': false,
            })
            count = count + 1
        })
        temp = matchSorter(temp, "", { keys: ["commentDate"] });
        temp.reverse()
        setListComment(temp)
    }, [dataComment])

    return (
        <div className="comments-wrapper">
            <div className="inner">
                <h4 className="heading heading-h4">{listComment?.length} Comments</h4>

                <div className={`comment-list-wrap mt--25`}>
                    {listComment.map(comment => (
                        <Comment
                            key={comment.id}
                            author={comment.author}
                            authorPic={require('../../../../../../assets/img/paraportal/' + comment.authorPic)}
                            quote={comment.quote}
                            commentDate={comment.commentDate}
                            reply={comment.reply}
                            className="wow move-up"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Comments;
