import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../../../../components/section-title/SectionTitleOne";
import SlickSlider from "../../../../components/slick";
import DivisionItem from "./DivisionItem";

const Division = ({ divisions }) => {
  const NextArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-right" />
      </button>
    );
  };

  const PrevArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-left" />
      </button>
    );
  };

  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 756,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="brook-testimonial-area" style={{ marginBottom: "4rem" }}>
      <Container
        style={{
          backgroundColor: "#a5a7eb",
          padding: "0",
        }}>
        <Row className="row--0 align-items-center">
          <Col lg={3} className="ptb-md--80 ptb-sm--80">
            <SectionTitle
              heading={`Division`}
              headingColor={"#000"}
              wrapperClass={"d-flex justify-content-center"}
            />
          </Col>

          <Col lg={9}>
            <SlickSlider
              settings={settings}
              classes="brook-element-carousel slick-arrow-center slick-arrow-triangle slick-arrow-trianglestyle-2 testimonial-space-right">
              {divisions.map((division) => (
                <DivisionItem
                  key={division.id}
                  {...division}
                  classes={"hover-transparent space-large--topbottom"}
                />
              ))}
            </SlickSlider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Division;
