import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import SegmentNBI from "../../../pages/info/News/NewsSegmentation";
import Breadcrumb from "../../../components/banner/BannerPageOne";
import LayoutDefaultParaportal from "../../../layouts/LayoutDefaultParaportal";

import img from "../../../../assets/img/paraportal/mascot-02.png";

const AllNews = () => {
  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - News</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <Breadcrumb title="NEWS" color="#a5a7eb" img={img} />
        <SegmentNBI view="segment1" title="News, Business & Internal Update"/>
        <SegmentNBI view="segment2" title="Product Knowledge & Innovation Magazine"/>
        <SegmentNBI view="segment3" title="Covid-19"/>
        <div className="bk-blog-grid-area bg_color--5" style={{paddingBottom:'100px'}}></div>
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default AllNews;
