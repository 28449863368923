import React, { useState, useEffect } from 'react';
import { Col, Card, Row, ListGroup } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'
import ModalDetailApps from "./ModalDetailApps";
import qore from "../../../../qoreContext";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ListServiceCatalogListView = ({ wide, subCategory1, directorate, filter, isListView }) => {
  const [isSeeMore, setIsSeeMore] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [modalShow, setModalShow] = React.useState(false);
  const [catalogChoosed, setCatalogChoosed] = React.useState({});
  const { data: dataServiceCatalog } = qore.view("allServiceCatalog").useListRow({
    subCategory1: subCategory1,
    subCategory2: directorate,
    overrideLimit: true,
    location: filter.location,
    '$by.orderItem': 'asc',
  });

  const [display, setDisplay] = useState()


  useEffect(() => {
    setTimeout(() => {
      setDisplay({ display: "none" })
    }, 3000);
  }, [display])

  if (dataServiceCatalog.length > 0) {
    return (
      <>
        {isTabletOrMobile ?
          <Row>
            <Col xs={8} sm={8} md={8} lg={8} style={{ margin: '0px', padding: '0px' }}>
              <h5 className="heading" style={{ marginBottom: '0px' }}>
                {directorate}
              </h5>
              <p style={{ fontSize: '12px', fontWeight: '300', marginBottom: '20px' }}>
                {dataServiceCatalog.length} apps avaiable
              </p>
            </Col>
          </Row>
          :
          <h5 className="heading" style={{ marginBottom: '0px' }}>
            {directorate}
          </h5>
        }

        <Row>
          {isTabletOrMobile ?
            <>
              <Row className="mt--n20">
                <Card style={{ backgroundColor: 'ghostWhite', borderWidth: '0px', padding: '5px 0px' }}>
                  <Card.Body>
                    <ListGroup>
                      {dataServiceCatalog?.map((item, idx) => {
                        return (
                          <>
                            {(idx < 4 || (isSeeMore && !isFirstRender)) &&
                              <ListGroup.Item style={{ backgroundColor: 'ghostWhite', padding: '5px 15px', borderWidth: '2px', borderTop: 'black', borderLeft: 'black', borderRight: 'black', borderRadius: '0px' }}>
                                <Row onClick={() => { setModalShow(true); setCatalogChoosed(item) }}>
                                  <Col xs={2} sm={2} md={2} lg={2} style={{ margin: '0px', padding: '0px' }}>
                                    <div
                                      className="thumb video-with-thumb"
                                      style={{ position: "relative", height: "80%" }}>
                                      <img
                                        className="vertical-center-image"
                                        src={item?.logo}
                                        style={{ width: '60%', marginTop: '2px' }}
                                        alt='img'
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={9} sm={9} md={9} lg={9} style={{ margin: '0px', padding: '0px' }}>
                                    <p style={{ fontSize: '12px', fontWeight: '500', margin: 'auto' }}>
                                      {item?.serviceTitle}
                                    </p>
                                  </Col>
                                  <Col xs={1} sm={1} md={1} lg={1} style={{ margin: '0px', padding: '0px' }}>
                                    <p style={{ margin: '0px', display: 'flex', justifyContent: 'end' }}>
                                      &gt;
                                    </p>
                                  </Col>

                                </Row>
                              </ListGroup.Item>
                            }
                          </>
                        )
                      })}
                    </ListGroup>
                    {dataServiceCatalog.length > 4 && !isSeeMore &&
                      <p style={{ fontSize: '12px', fontWeight: '500', color: 'blue', margin: '10px 0px 0px 0px', cursor: 'pointer' }}
                        onClick={() => { setIsSeeMore(true); setIsFirstRender(false); }}
                      >
                        See More ({dataServiceCatalog.length}) &#8595;
                      </p>
                    }
                    {isSeeMore &&
                      <p style={{ fontSize: '12px', fontWeight: '500', color: 'blue', margin: '10px 0px 0px 0px', cursor: 'pointer' }}
                        onClick={() => { setIsSeeMore(false); setIsFirstRender(false); }}
                      >
                        See Less &#8593;
                      </p>
                    }
                  </Card.Body>
                </Card>
              </Row>
            </>
            :
            <>
              <Row style={{ padding: '10px 20px' }}>
                <Card style={{ backgroundColor: 'ghostWhite', borderWidth: '0px', padding: '5px 0px' }}>
                  <Card.Body>
                    <ListGroup>
                      {dataServiceCatalog?.map((item, idx) => {
                        return (
                          <>
                            {(idx < 4 || (isSeeMore && !isFirstRender)) &&
                              <ListGroup.Item style={{ backgroundColor: 'ghostWhite', padding: '5px 15px', borderWidth: '2px', borderTop: 'black', borderLeft: 'black', borderRight: 'black', borderRadius: '0px' }}>
                                <Row onClick={() => { setModalShow(true); setCatalogChoosed(item) }}>
                                  <Col xs={2} sm={2} md={2} lg={2} style={{ margin: '0px', padding: '0px' }}>
                                    <div
                                      className="thumb video-with-thumb"
                                      style={{ position: "relative", height: "80%" }}>
                                      <img
                                        className="vertical-center-image"
                                        src={item?.logo}
                                        style={{ width: '60%', marginTop: '2px' }}
                                        alt='img'
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={9} sm={9} md={9} lg={9} style={{ margin: '0px', padding: '0px' }}>
                                    <p style={{ fontSize: '12px', fontWeight: '500', margin: 'auto' }}>
                                      {item?.serviceTitle}
                                    </p>
                                  </Col>
                                  <Col xs={1} sm={1} md={1} lg={1} style={{ margin: '0px', padding: '0px' }}>
                                    <p style={{ margin: '0px', display: 'flex', justifyContent: 'end' }}>
                                      &gt;
                                    </p>
                                  </Col>

                                </Row>
                              </ListGroup.Item>
                            }
                          </>
                        )
                      })}
                    </ListGroup>
                    {dataServiceCatalog.length > 4 && !isSeeMore &&
                      <p style={{ fontSize: '12px', fontWeight: '500', color: 'blue', margin: '10px 0px 0px 0px', cursor: 'pointer' }}
                        onClick={() => { setIsSeeMore(true); setIsFirstRender(false); }}
                      >
                        See More ({dataServiceCatalog.length}) &#8595;
                      </p>
                    }
                    {isSeeMore &&
                      <p style={{ fontSize: '12px', fontWeight: '500', color: 'blue', margin: '10px 0px 0px 0px', cursor: 'pointer' }}
                        onClick={() => { setIsSeeMore(false); setIsFirstRender(false); }}
                      >
                        See Less &#8593;
                      </p>
                    }
                  </Card.Body>
                </Card>
              </Row>
            </>
          }
        </Row>

        <ModalDetailApps
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={catalogChoosed}
        />
      </>
    );
  } else {
    return (
      <>
        <Row style={display}>
          <Col>
            <Skeleton height={100} />
          </Col>
        </Row>
      </>
    )
  }
};


export default ListServiceCatalogListView;
