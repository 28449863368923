import React from 'react';
import {Accordion, Card, Button} from 'react-bootstrap'
import { onHandleGtmViewEvent } from '../../../../helper/gtm';

const AccordionItem = ({title, eventKey, data}) => {

    const trackEventViewGtm = (data) => {
        onHandleGtmViewEvent(data)
    }
    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="button" eventKey={eventKey} className={'text-left'} style={{fontWeight: '200'}} onClick={() => trackEventViewGtm(data)}>
                    <b>{data?.startDate.slice(8,10)} {data?.month} </b> - {data?.title} <span style={{color:'grey', fontSize:'10px'}}>{data?.subtitle}</span>
                </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>
                    Description : {data?.description ? data.description : '-'}
                    <br/>
                    Time : {parseInt(data?.startDate.slice(11,13))+7}{data?.startDate.slice(13,19)} - {parseInt(data?.endDate.slice(11,13))+7}{data?.endDate.slice(13,19)} WIB
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

export default AccordionItem;
