import React from 'react';

const Input = ({type, name, id, placeholder, required, value, className, onChange, onKeyPress}) => {
    return (
        type === 'textarea' ? (
            <textarea 
                id={id} 
                name={name} 
                placeholder={placeholder} 
                required={required} 
                className={className}
                onChange={(e) => onChange(e)}
            />
        ) : (
            <input
                type={type}
                id={id} name={name}
                placeholder={placeholder}
                value={value}
                required={required}
                className={className}
                onChange={(e) => onChange(e)}
                onKeyPress={onKeyPress}
            />
        )
    );
};

export default Input;