import IconListItemOneParaportal from '../../../components/icon-list/IconListItemOneParaportal';
import React from 'react';

const CoreValueContent = () => {
    return (
        <div>
            {dataCoreValue.map(item => (
                <IconListItemOneParaportal data={item} key={item.id}/>
            ))}
        </div>
    );
};

const dataCoreValue = [
    {
        id: 1,
        iconClass: "ion-ios-infinite",
        imgIcon : "value-1.png",
        title: "Ketuhanan",
        content: "Paragonian meyakini keberadaan dan kekuasaan Tuhan Yang Maha Esa."
    },
    {
        id: 2,
        iconClass: "ion-monitor",
        imgIcon : "value-2.png",
        title: "Kepedulian",
        content: "Paragonian menjunjung tinggi nilai kebersamaan dan kasih sayang."
    },
    {
        id: 3,
        iconClass: "ion-ios-baseball-outline",
        imgIcon : "value-3.png",
        title: "Kerendahan Hati",
        content: "Paragonian menyadari bahwa setiap orang memiliki kelebihan dan kekurangan."
    },
    {
        id: 4,
        iconClass: "ion-pinpoint",
        imgIcon : "value-4.png",
        title: "Ketangguhan",
        content: "Paragonian menjalani hidup dengan penuh suka cita dan daya juang tinggi."
    },
    {
        id: 5,
        iconClass: "ion-pinpoint",
        imgIcon : "value-5.png",
        title: "Inovasi",
        content: "Paragonian selalu mengembangkan hal baru yang lebih baik untuk memenuhi dan melampaui harapan pelanggan."
    }
]

export default CoreValueContent;
