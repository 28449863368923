import { QoreClient } from "@feedloop/qore-client";
import createQoreContext from "@feedloop/qore-react";

const client = new QoreClient({
  endpoint: "https://p-qore-dot-pti-feedloop.et.r.appspot.com",
  projectId: "dZNF8SYn2b74Bb1",
  organizationId: "lIdfC42DJCN2XzQ",
  authenticationId: "",
  // endpoint: process.env.REACT_APP_QORE_ENDPOINT || "",
  // projectId: process.env.REACT_APP_QORE_PROJECT_ID || "",
  // organizationId: process.env.REACT_APP_QORE_ORG_ID || "",
  // authenticationId: process.env.REACT_APP_QORE_AUTH_ID || "",
  // onError: error => {
  //   if (error?.response?.status === 401 && window.location.pathname !== "/landing") {
  //     window.location.replace("/landing");
  //   }
  // }
});
const qore = createQoreContext(client);

export default qore;
