import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'
import { Link } from "react-router-dom";


const Report = () => {

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})

  const dataMenu = [
    {
      'id' : 1,
      'title' : 'Corporate',
      'image': 'Corporate.png',
      'link': '/business-intelligence/corporate'
    },
    {
      'id' : 2,
      'title' : 'Commercial',
      'image': 'Commercial.png',
      'link': '/business-intelligence/commercial'
    },
    {
      'id' : 3,
      'title' : 'Finance',
      'image': 'Finance.png',
      'link': '/business-intelligence/finance'
    },
    {
      'id' : 4,
      'title' : 'Marketing',
      'image': 'Marketing.png',
      'link': '/business-intelligence/marketing'
    },
    {
      'id' : 5,
      'title' : 'Parama',
      'image': 'Parama-NDC-1.png',
      'link': '/business-intelligence/parama'
    },
    {
      'id' : 6,
      'title' : 'SCM',
      'image': 'SCM.png',
      'link': '/business-intelligence/scm'
    }
  ]

  return (
    <Container
      className='ptb--50 ptb-md--80 ptb-sm--60 bg_color--1'
      style={isDesktopOrLaptop ? { paddingLeft: '200px', paddingRight: '200px' } : null}
    >
      <Row>
        {dataMenu.map(menu => (
          <Col xs={5} sm={6} lg={4} className="mt--20 pl--5" key={menu.id}>
            <Link to={menu.link}>
              <div className={`portfolio portfolio_style--1`}>
                <div className="thumb">
                  <img src={require(`../asset/${menu.image}`)} alt={menu.title} />                
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Report;
