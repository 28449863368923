import React, { useState, useEffect } from "react";
import qore from "../../../../../qoreContext";
import { useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import WorkAreaHeader from "./WorkAreaHeader";
import WorkAreaMenu from "./WorkAreaMenu";

import "./Sidebar.scss";

const HEAD_OFFICE = "Head Office";
const DISTRIBUTION_CENTER = "Distribution Center";
const PLANT = "Plant";
const CATEGORY_NULL = "NULL";

export default function Sidebar() {
  const { areaKerja, articleTitle } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarItems, setSidebarItems] = useState([]);
  const [defaultActiveKeys, setDefaultActiveKeys] = useState([]);

  const { data: articleList, status: status1 } = qore
    .view("hrscArticleEssentialOnly")
    .useListRow({ overrideLimit: true });
  const { data: areaKerjaList, status: status2 } = qore
    .view("allAreaKerjaHrsc")
    .useListRow({});

  const { data: category0List, status: status3 } = qore
    .view("allCategoryHrsc")
    .useListRow({ level: 0, overrideLimit: true });
  const { data: category1List, status: status4 } = qore
    .view("allCategoryHrsc")
    .useListRow({ level: 1, overrideLimit: true });
  const { data: category2List, status: status5 } = qore
    .view("allCategoryHrsc")
    .useListRow({ level: 2, overrideLimit: true });

  const mapArticle = (articles, parentId) => {
    return articles
      .filter((article) => {
        return article.parent?.id === parentId;
      })
      .map((article) => {
        return {
          id: article.id,
          title: article.title,
          onClick: () => {},
          children: null,
        };
      });
  };

  const mapCategory = (categories, parentId, workingAreas) => {
    const result = categories
      .filter((category) => {
        // FOR TOP CATEGORIES
        if (workingAreas)
          return (
            category.parentId === parentId &&
            category.areaKerja.displayField === workingAreas
          );
        // FOR LOWER CATEGORIES
        return category.parentId === parentId;
      })
      .map((category) => {
        return {
          id: category.id,
          title: category.name,
          onClick: () => {},
        };
      });

    return result;
  };

  const getWorkingAreaChildren = (workingArea) => {
    return mapCategory(category0List, CATEGORY_NULL, workingArea).map(
      (category0) => {
        return {
          ...category0,
          // CATEGORY 0 CHILDREN
          children: [
            ...mapCategory(category1List, category0.id).map((category1) => {
              return {
                ...category1,
                // CATEGORY 1 CHILDREN
                children: [
                  ...mapCategory(category2List, category1.id).map(
                    (category2) => {
                      return {
                        // CATEGORY 2 CHILDREN
                        ...category2,
                        children: mapArticle(articleList, category2.id),
                      };
                    }
                  ),

                  ...mapArticle(articleList, category1.id),
                ],
              };
            }),

            ...mapArticle(articleList, category0.id),
          ],
        };
      }
    );
  };

  const mapSidebar = () => {
    const ho = areaKerjaList.find((item) => item.name === HEAD_OFFICE);
    const dc = areaKerjaList.find((item) => item.name === DISTRIBUTION_CENTER);
    const plant = areaKerjaList.find((item) => item.name === PLANT);

    const result = [
      {
        id: ho.id,
        title: ho.name,
        onClick: () => {},
        children: getWorkingAreaChildren(HEAD_OFFICE),
      },
      {
        id: dc.id,
        title: dc.name,
        onClick: () => {},
        children: getWorkingAreaChildren(DISTRIBUTION_CENTER),
      },
      {
        id: plant.id,
        title: plant.name,
        onClick: () => {},
        children: getWorkingAreaChildren(PLANT),
      },
    ];

    return result;
  };

  const getDefaultActiveKeys = () => {
    const foundArticle = articleList.find((item) => {
      return (
        item.title === articleTitle && item.areaKerja.displayField === areaKerja
      );
    });

    if (!foundArticle) return [];

    const { id } = foundArticle.parent;

    const key0 = category0List.find((item) => item.id === id);
    if (key0) return [key0.id];

    const key1 = category1List.find((item) => item.id === id);
    const key1Parent = category0List.find((item) => item.id === key1?.parentId);
    if (key1) return [key1Parent.id, key1.id];

    const key2 = category2List.find((item) => item.id === id);
    const key2Parent = category1List.find((item) => item.id === key2?.parentId);
    const key2GrandParent = category0List.find(
      (item) => item.id === key2Parent?.parentId
    );
    return [key2GrandParent.id, key2Parent.id, key2.id];
  };

  useEffect(() => {
    const isFetchLoading =
      status1 !== "success" ||
      status2 !== "success" ||
      status3 !== "success" ||
      status4 !== "success" ||
      status5 !== "success";

    if (isFetchLoading) return;

    setIsLoading(true);

    const result = mapSidebar();
    setSidebarItems(result);

    const keys = getDefaultActiveKeys();
    setDefaultActiveKeys(keys);

    setIsLoading(false);
    // eslint-disable-next-line
  }, [status1, status2, status3, status4, status5]);

  if (isLoading || !defaultActiveKeys.length) return null;
  return (
    <div id="hrsc-article-sidebar" className="blog-sidebar-container">
      <div
        className="blog-sidebar-wrapper"
        style={{
          boxShadow: "3px 7px 7px 0 rgba(0,0,0,.34)",
          borderRadius: "12px",
        }}>
        <Accordion defaultActiveKey={defaultActiveKeys[0]}>
          {sidebarItems.map((workArea, i) => {
            return (
              <>
                <WorkAreaHeader
                  workAreaName={workArea.title}
                  isFirstIndex={!i}
                />
                <div style={{ padding: "1rem 1.5rem" }}>
                  {/* LEVEL 0 => must have children */}

                  {workArea.children.map((category0) => {
                    return (
                      <WorkAreaMenu
                        defaultActiveKeys={defaultActiveKeys}
                        workArea={workArea}
                        topCategory={category0}
                      />
                    );
                  })}
                </div>
              </>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}
