import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SlickSlider from "../../../../components/slick";
import DepartementItem from "./DepartementItem";

const Departement = ({ departements }) => {

  const NextArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-right" />
      </button>
    );
  };

  const PrevArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-left" />
      </button>
    );
  };

  const settings = {
    slidesToShow: 2,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="brook-team-area bg_color--1 slick-arrow-hover"
      style={{ marginBottom: "4rem" }}>
      <Container>
        <Row>
          <Col xs={12}>
            <SlickSlider
              settings={settings}
              classes="brook-element-carousel slick-arrow-center slick-arrow-triangle">
              {departements.map((departement) => (
                <DepartementItem key={departement.id} {...departement} />
              ))}
            </SlickSlider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Departement;
