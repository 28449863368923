import React, { useEffect, useState } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import CardItems from "./CardItems";
import qore from "../../../../qoreContext";

const listCategory = [
  {
    key: 'headOffice',
    name: 'Head Office'
  },
  {
    key: 'distributionCenter',
    name: 'Distribution Center'
  },
  {
    key: 'plant',
    name: 'Plant'
  }
]

const TabMenu = ({tabHRSC, activeTab}) => {
  const [activeKey, setActiveKey] = useState(activeTab);
  const { data: dataHRSC_HO } = qore.view("allCategoryHrsc").useListRow({
    areaKerja: 'Head Office',
    parentID: 'NULL',
    '$by.orderParentByArea': 'asc',
  });

  const { data: dataHRSC_DC } = qore.view("allCategoryHrsc").useListRow({
    areaKerja: 'Distribution Center',
    parentID: 'NULL',
    '$by.orderParentByArea': 'asc',
  });

  const { data: dataHRSC_Plant } = qore.view("allCategoryHrsc").useListRow({
    areaKerja: 'Plant',
    parentID: 'NULL',
    '$by.orderParentByArea': 'asc',
  });

  useEffect(()=>{
    tabHRSC(activeKey)
    // eslint-disable-next-line
  },[activeKey])

  return (
    <Container className='ptb--50 ptb-md--80 ptb-sm--60 bg_color--1 pb--50'>
      {/*  <input name="tab" onChange={onChange} /> */}
      <Tab.Container id="pills-tab" defaultActiveKey={activeKey}>
        <Nav variant="pills" className="mb-3">
          {listCategory?.map((category) => {
            return (
              <Nav.Item>
                {activeKey === category.key ?
                  <Nav.Link value={category.key} name='tab' eventKey={category.key} onClick={() => setActiveKey(category.key)} style={{ borderBottom: '4px solid white', backgroundColor: '#0E5679', color: 'white' }}>
                    <strong>{category.name}</strong>
                  </Nav.Link>
                  :
                  <Nav.Link value={category.key} name='tab' eventKey={category.key} onClick={() => setActiveKey(category.key)} style={{ borderBottom: '4px solid lightGrey', backgroundColor: 'white' }}>
                    {category.name}
                  </Nav.Link>
                }
              </Nav.Item>
            )
          })}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="headOffice">
            <CardItems item={dataHRSC_HO} />
          </Tab.Pane>
          <Tab.Pane eventKey="distributionCenter">
            <CardItems item={dataHRSC_DC} />
          </Tab.Pane>
          <Tab.Pane eventKey="plant">
            <CardItems item={dataHRSC_Plant} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default TabMenu;
