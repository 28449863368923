import React, {Fragment} from 'react';
import LayoutDefault from "../../../layouts/LayoutDefault";
import ContactForm from "./Form";

const ContactClassic = () => {
    return (
        <Fragment>
            <LayoutDefault className="template-color-1 template-font-1">
                <ContactForm/>
            </LayoutDefault>
        </Fragment>
    );
};

export default ContactClassic;