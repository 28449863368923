import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import CardContent from "./CardContent";
import HRSCListContent from "./HRSCListContent";

function CardItems({ item }) {
  return (
    <div className="CardItems">
      <Container>
        <Row>
          {item?.map((props) => (
            <Col sm={4} md={4} lg={4} style={{ padding: "0" }}>
              <Card
                fluid={true}
                key={props.id}
                text={"dark"}
                style={{ margin: "10px" }}
              >
                <Card.Header
                  style={{
                    backgroundColor: "#0E5679",
                    fontSize: "15px",
                    color: "white",
                    minHeight: "70px",
                  }}
                >
                  {props.name}
                </Card.Header>
                <Card.Body style={{ marginBottom: "10px" }}>
                  <CardContent id={props.id} />
                  <HRSCListContent id={props.id} />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
export default CardItems;
