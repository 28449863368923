import React, { useEffect, useState } from 'react';
import { Accordion, Container, Row, Col, Card, Button } from 'react-bootstrap'
import qore from "../../../../qoreContext";
import Thumbnail from "../../../../components/about-us/thumbnail/AboutThumbTwo";

export default function QuestionAnswer({ tab }) {
	const [listFaq, setListFaq] = useState([])

	const { data: dataFaq } = qore.view("allFaq").useListRow({
		category: tab === 'ALL' ? null : tab
	});

	useEffect(() => {
		let item = []
		let count = 1
		dataFaq.forEach(el => {
			let data = el
			data.id = count
			item.push(data)
			count = count + 1
		});
		setListFaq(item)
	}, [dataFaq])

	return (
		<div className="bk-accordion-area ptb--50 ptb-md--80 ptb-sm--60 bg_color--1">
			<Container>
				<Row>
					<Col className="m-auto">
						<Accordion defaultActiveKey="1" className={'bk-accordion'}>
							{listFaq?.map(accordion => (
								<Card key={accordion.id}>
									<Card.Header>
										<Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
											{accordion.question}
										</Accordion.Toggle>
									</Card.Header>
									<Accordion.Collapse eventKey={accordion.id}>
										<Card.Body>
											{accordion?.answerParagraph1 &&
												<div className="inner">
													<div className="desc mt--5 mb--15">
														<p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
															<span dangerouslySetInnerHTML={{ __html: accordion?.answerParagraph1 }}></span>
														</p>
													</div>
												</div>
											}
											{accordion?.answerImage1 && <Thumbnail thumb={accordion?.answerImage1} className="mb--60" />}

											{accordion?.answerParagraph2 &&
												<div className="inner">
													<div className="desc mt--5 mb--15">
														<p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
															<span dangerouslySetInnerHTML={{ __html: accordion?.answerParagraph2 }}></span>
														</p>
													</div>
												</div>
											}
											{accordion?.answerImage2 && <Thumbnail thumb={accordion?.answerImage2} className="mb--60" />}

											{accordion?.answerParagraph3 &&
												<div className="inner">
													<div className="desc mt--5 mb--15">
														<p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
															<span dangerouslySetInnerHTML={{ __html: accordion?.answerParagraph3 }}></span>
														</p>
													</div>
												</div>
											}
											{accordion?.answerImage3 && <Thumbnail thumb={accordion?.answerImage3} className="mb--60" />}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							))}
						</Accordion>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
