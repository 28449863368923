import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

import IconEdit from "../../../../assets/paraportal/icon-edit.svg";
import IconUser from "../../../../assets/paraportal/icon-user.svg";
import IconCalendarDays from "../../../../assets/paraportal/icon-calendar-days.svg";

import "./article.scss";

const Article = ({ title, content, createdOn, updatedOn, author }) => {
  createdOn = moment(createdOn).format("MMMM D[,] YYYY");
  updatedOn = moment(updatedOn).format("MMMM D[,] YYYY");

  return (
    <article id="hrsc-article" className="blog-post standard-post">
      <header className="header text-left wow move-up">
        <h3
          className="heading heading-h3 text-left"
          style={{ marginBottom: "2rem", fontSize: "56px" }}>
          {title}
        </h3>
        <Row style={{ marginBottom: "2rem" }}>
          <Col xs={12}>
            <p className="margin-0 text-left">
              <span style={{ marginRight: "0.5rem" }}>
                <img
                  src={IconCalendarDays}
                  alt="icon"
                  className="inline-image"
                />
              </span>
              <span style={{ marginRight: "1rem" }}>
                Created On {createdOn}
              </span>
              <span style={{ marginRight: "0.5rem" }}>
                <img src={IconEdit} alt="icon" className="inline-image" />
              </span>
              Last Updated On {updatedOn}
            </p>
          </Col>
          <Col xs={12}>
            <p className="margin-0 text-left">
              <span style={{ marginRight: "0.5rem" }}>
                <img src={IconUser} alt="icon" className="inline-image" />
              </span>
              by {author}
            </p>
          </Col>
        </Row>

        {/* <Row style={{ marginBottom: "2rem" }}>
          <Col xs={12}>
            <p className="margin-0 text-left" style={{ color: "#999" }}>
              You are here:
            </p>
          </Col>
          <Col xs={12}>
            <p className="margin-0 text-left" style={{ color: "#4398ba" }}>
              BREADCRUMB &#62; BREADCRUMB &#62; BREADCRUMB &#62; BREADCRUMB
              &#62; BREADCRUMB
            </p>
          </Col>
        </Row> */}

        {/* <Row style={{ paddingLeft: "0.5rem", marginBottom: "0.5rem" }}>
          <Col xs={12}>
            <p className="margin-0 text-left" style={{ color: "#4398ba" }}>
              &#60; All Topics
            </p>
          </Col>
        </Row> */}
      </header>

      <section className="content basic-dark2-line-1px pb--50 mb--35">
        <div className="inner">
          <div
            className="desc mb--50 wow move-up"
            style={{ color: "#999" }}
            dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </section>
    </article>
  );
};

export default Article;
