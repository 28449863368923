import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import qore from "../../../../../../../qoreContext";
import LoveComp from "../../../../../BusinessIntelligence/report/LoveComp";

export default function Link() {

    const { data: allDownstream } = qore.view("allDownstream").useListRow({
        category: "customer",
    });

    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
                <Row className="">
                    {allDownstream.length ? allDownstream?.map((dashboard) => (
                        <Col xs md="3" className="mt--25" key={dashboard.id}>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={dashboard.image}
                                    alt={dashboard.title}
                                />
                                <div className="p-3">
                                    <a href={dashboard.link} target="_blank" rel="noopener noreferrer">
                                        <p style={{ fontWeight: "500" }}>{dashboard.title}</p>
                                    </a>
                                    <small>
                                        <LoveComp valuedashboard={
                                            {
                                                "id": dashboard.id,
                                                "title": dashboard.title,
                                                "category": dashboard.category,
                                                "link": dashboard.link,
                                                "table": dashboard.table,
                                            }
                                        } />
                                        &nbsp; add to favorites
                                    </small>
                                </div>
                            </Card>
                        </Col>
                    )) : <p>Nothing to show</p>}
                </Row>
            </Container>
        </>
    );
}
