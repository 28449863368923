import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'
import CompReseorce from '../../../upstream/components/CompReseorce'

const accordionContent = [
    {
        "id": 1,
        "title": "Training",
        "content": "Cooming Soon"
    },
    {
        "id": 2,
        "title": "Post Test",
        "content": "Cooming Soon"
    },
    {
        "id": 3,
        "title": "Flow",
        "content": <CompReseorce title="Link flow POSM" link="https://drive.google.com/file/d/1rqd_qvngYjUhjlWGqoC7UFXqOA_W6cnt/view?usp=sharing"/>
    },
    {
        "id": 4,
        "title": "User Guide",
        "content": "Cooming Soon"
    },
    {
        "id": 5,
        "title": "FAQ",
        "content": "Cooming Soon"
    },
]

export default function Resource() {
    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
                <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                    {accordionContent.map(accordion => (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                    {accordion.title}
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={accordion.id}>
                                <Card.Body>{accordion.content}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Container>
        </>
    )
}
