import TagManager from "react-gtm-module";

const getUserEmail = () => {
  return localStorage.getItem("userEmail");
};

const getUserId = () => {
  return localStorage.getItem("userId");
};

const resetDataLayer = () => {
  window.dataLayer.push(() => {
    window.google_tag_manager[process.env.REACT_APP_GTM_CODE].dataLayer.reset();
  });
};

export const onHandleGtmLogin = (data) => {
  const { userId, email } = data;
  resetDataLayer();
  TagManager.dataLayer({
    dataLayer: {
      event: "login",
      user_id: email,
      crm_id: userId
    },
  });
};

export const onHandleGtmNavigateNavbar = (data) => {
  const { title } = data;
  let eventTitle = "click_navigation_news";
  if (title === "Event") {
    eventTitle = "click_navigaton_event";
  } else if (title === "HR Service Center") {
    eventTitle = "click_navigation_hrsc";
  }
  resetDataLayer();
  TagManager.dataLayer({
    dataLayer: {
      event: eventTitle,
      user_id: getUserEmail(),
      crm_id: getUserId()
    },
  });
};

export const onHandleGtmViewEvent = (data) => {
  const { title } = data;
  resetDataLayer();
  if (window.location.pathname === "/event-page") {
    TagManager.dataLayer({
      dataLayer: {
        event: "click_view_event",
        user_id: getUserEmail(),
        crm_id: getUserId(),
        event_title: title,
        click_source: "Event Page"
      },
    });
  } else {
    TagManager.dataLayer({
      dataLayer: {
        event: "click_view_event",
        user_id: getUserEmail(),
        crm_id: getUserId(),
        event_title: title,
        click_source: "Home Page"
      },
    });
  }
};

export const onHandleGtmViewNews = (data) => {
  const { title } = data;
  resetDataLayer();
  if (window.location.pathname === "/news-page") {
    TagManager.dataLayer({
      dataLayer: {
        event: "click_view_news",
        user_id: getUserEmail(),
        crm_id: getUserId(),
        news_title: title,
        click_source: "News Page"
      },
    });
  } else {
    TagManager.dataLayer({
      dataLayer: {
        event: "click_view_news",
        user_id: getUserEmail(),
        crm_id: getUserId(),
        news_title: title,
        click_source: "Home Page"
      },
    });
  }
};

export const onHandleGtmViewHrsc = (data) => {
  const { title } = data;
  resetDataLayer();
  TagManager.dataLayer({
    dataLayer: {
      event: "click_view_hrsc",
      user_id: getUserEmail(),
      crm_id: getUserId(),
      hrsc_title: title,
    },
  });
};
