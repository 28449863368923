import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import LayoutDefaultParaportal from "../../../../layouts/LayoutDefaultParaportal";
import BreadcrumbOthers from "../BreadcrumbOthers";
import { Container } from "react-bootstrap";

const Others = () => {
  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Master Data Management | Others</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <BreadcrumbOthers
          title="Others"
          color="#6A8AFC"
        />
        <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50" style={{ "height": "320px" }}>
          Coming Soon
        </Container>
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default Others;