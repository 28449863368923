import React from "react";
import LeaderProfile from "./LeaderProfile";
import { Col, Container, Row } from "react-bootstrap";

const LeaderProfiles = ({ leaderProfiles }) => {
  return (
    <Container style={{ marginBottom: "4rem" }}>
      <Row>
        <Col
          lg={4}
          style={{
            display: "flex",
            alignItems: "flex-start",
            paddingTop:
              leaderProfiles[0] && leaderProfiles[0].displayImage
                ? "4rem"
                : "0",
          }}>
          <p
            style={{
              fontSize: "34px",
              fontWeight: "700",
            }}>
            Meet Our Leader
          </p>
        </Col>
        <Col lg={8}>
          <div className={`comment-list-wrap`}>
            {leaderProfiles.map((leaderProfile) => (
              <LeaderProfile
                key={leaderProfile.id}
                className="wow move-up"
                {...leaderProfile}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LeaderProfiles;
