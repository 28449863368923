import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import LayoutDefaultParaportal from "../../../../layouts/LayoutDefaultParaportal";
import BreadcrumbsBI from "../BreadcrumbsBI";
import RedashScm from "./content/RedashScm";
import TableauScm from "./content/TableauScm";
import SupersetScm from "./content/SupersetScm";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabSCMBI')

const ScmBI = () => {
  const [category, setCategory] = useState(tab ? tab : 'Tableau');
  const history = useHistory();
  const params = new URLSearchParams({tabSCMBI: category});
  
  const handleChangeCategory = (item) => {
    setCategory(item);
  };

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [category])

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Business Intelligence | SCM</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <BreadcrumbsBI
          title="SCM"
          //subtitle="List tableau, redash, superset corporate" 
          color="#6A8AFC"
          handleChangeCategory={handleChangeCategory}
          category={category}
        />
        {category === 'Redash' && <RedashScm />}
        {category === 'Tableau' && <TableauScm />}
        {category === 'Superset' && <SupersetScm />}
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default ScmBI;