import React from 'react';
import {Row, Col} from "react-bootstrap";
import portfolios from '../../../data/portfolio/portfolio-one-paraportal.json'
import PortfolioItemOneParaportal from "../../../components/portfolio/PortfolioItemOneParaportal";

const PortfolioOneParaportal = () => {
    return (
        <div className="bk-portfolio-area">
                <Row className={"row--0"}>
                    <Col lg={6}>
                        <PortfolioItemOneParaportal
                            thumb={require('../../../assets/img/portfolio/portfolio-1/news-tile.jpg')}
                            title={[portfolios[0].title]}
                        />
                    </Col>

                    <Col lg={6}>
                        <Row className={"row--0"}>
                            <Col xs={12}>
                                <PortfolioItemOneParaportal
                                    thumb={require('../../../assets/img/portfolio/portfolio-1/product-knowledge-tile.jpg')}
                                    title={[portfolios[1].title]}
                                />
                            </Col>

                            <Col md={6}>
                                <PortfolioItemOneParaportal
                                    thumb={require('../../../assets/img/portfolio/portfolio-1/covid-tile.jpg')}
                                    title={[portfolios[2].title]}
                                />
                            </Col>

                            <Col md={6}>
                                <PortfolioItemOneParaportal
                                    thumb={require('../../../assets/img/portfolio/portfolio-1/quiz-corner-tile.jpg')}
                                    title={[portfolios[3].title]}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </div>
    );
};

export default PortfolioOneParaportal;
