import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'

export default function FAQ() {
    const accordionContent = [
        {
            "id": 1,
            "title": "Dimana letak menu master data, hanya ada menu discuss?",
            "content": "Role belum disetting, sehingga menu master data tidak muncul. Jika telah diset rolenya, maka menu master data dapat ditemukan di pojok kiri atas (ikon kotak empat)."
        },
        {
            "id": 2,
            "title": "Untuk perubahan atau penambahan data BOS dalam jumlah banyak, apa bisa dilakukan mass upload?",
            "content": "Bisa, mass upload dapat direquest ke tim IT melalui tiket GLPI di link berikut: https://glpi.pti-cosmetics.com atau melalui email ke Service Desk. Apabila request dilakukan melalui tiket GLPI, mohon dapat menambahkan tim MDM sebagai watcher. Apabila request dilakukan melalui email, mohon dapat di-cc ke tim MDM. Apabila status tab ingin dibuat langsung Confirmed, mohon melampirkan bukti approval dari approver tab yang bersangkutan di tiket/email. Jika tidak ada bukti approval, status tab akan menjadi Waiting for Approval."
        },
        {
            "id": 3,
            "title": "Apa yang harus dilakukan jika konfigurasi sudah tidak aktif?",
            "content": "PPC creator dapat mengubah status konfigurasi menjadi “Inactive” di tab BOS, kemudian request approval ke PPC Approver."
        },
        {
            "id": 4,
            "title": "Apakah seluruh data BOS di Odoo MDM masuk ke tools planning Futurmaster?",
            "content": "Tidak, hanya jika status tab Upstream dan Downstream telah Confirmed dan status konfigurasi adalah “Active”."
        },
        {
            "id": 5,
            "title": "Bagaimana cara extract data BOS?",
            "content": "<p>Extract data dapat dilakukan dengan 2 cara:</p><ol><li>Download data dari Dashboard BOS di link berikut: <a class='waffle-rich-text-link' href='https://redash.pti-cosmetics.com/dashboard/bill-of-supply'>https://redash.pti-cosmetics.com/dashboard/bill-of-supply</a>,</li><li>Export langsung di Odoo MDM: klik View List (tombol di bawah search bar dengan ikon 3 garis horizontal) &gt; Action &gt; Export &gt; pilih data yang dibutuhkan.</li></ol>"
        },
        {
            "id": 6,
            "title": "Apakah Dashboard BOS di Redash terintegrasi real time dengan BOS di Odoo MDM",
            "content": "Tidak, data di Dashboard BOS tersinkronisasi dengan Odoo MDM satu kali dalam satu hari, setiap jam 7 pagi."
        },
    ]

    return (
        <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">

            <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                {accordionContent.map(accordion => (
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                {accordion.title}
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey={accordion.id}>
                            <Card.Body>
                                <div dangerouslySetInnerHTML={{
                                    __html: accordion.content
                                }}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </Container>
    )
}
