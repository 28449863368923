import React, { Fragment } from 'react'
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../components/banner/BannerPageOne";
import AccordionOne from "./AccordionEvent";
import LayoutDefaultParaportal from "../../../layouts/LayoutDefaultParaportal";
import img from "../../../../assets/img/paraportal/mascot-02.png";

const AllNews = () => {

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Event</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <Breadcrumb title="EVENT" color="#a5a7eb" img={img} />
        <AccordionOne />
      </LayoutDefaultParaportal>
    </Fragment>
  )
}

export default AllNews;