import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import qore from "../../../../qoreContext";
import QuestionAnswer from '../question-answer/QuestionAnswer';
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabFAQ');

export default function TabFaq() {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(tab ? tab : 'All');
  const params = new URLSearchParams({ tabFAQ: activeKey });
  const { data: dataTabFaq, revalidate : revalidateDataFaq } = qore.view("filterQAndA").useListRow({});
  const [dataFaq, setDataFaq] = useState(dataTabFaq)

  useEffect(() => {
    revalidateDataFaq()
    setDataFaq(dataTabFaq)
    
  }, [dataFaq, dataTabFaq, revalidateDataFaq]);

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [activeKey])

  return (
    <div className="tabs-section section ptb--80 ptb-md--80 ptb-sm--60 bg_color--1 pb--50">
      <Container>
        <Row className="mb--50">
          <Col xs={12}>
            <div className="tab">
              <Tab.Container id="pills-tab" defaultActiveKey={activeKey}>
                <Nav variant="pills" className="mb-3">
                  {dataTabFaq.length ? dataTabFaq.map((dataTab) => (
                    <Nav.Item key={dataTab.id}>
                      <Nav.Link eventKey={dataTab.name} onClick={() => setActiveKey(dataTab.name)}>{dataTab.name}</Nav.Link>
                    </Nav.Item>
                  )) : null}
                </Nav>
                <Tab.Content>
                  {dataTabFaq?.map((tab) => {
                    return (
                      <Tab.Pane eventKey={tab.name} key={tab.id}>
                        <QuestionAnswer tab={tab.acronym} />
                      </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
