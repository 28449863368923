import React, { useEffect, useState } from 'react'
import { Accordion, Container, Row, Col, Card, Button, Modal } from 'react-bootstrap'
import qore from "../../../../qoreContext";

export default function ContentITSUpportService() {

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowManager, setModalShowManager] = React.useState(false);

  const { data: contactITBP, revalidate: revalidateContactITBP } = qore.view("allContactItbp").useListRow({});
  const { data: contactITBPManager, revalidate: revalidateContactITBPManager } = qore.view("allContactItbpManager").useListRow({});
  const { data: targetPenyelesaian, revalidate: revalidateTargetPengelesaian } = qore.view("allTargetPenyelesaianIssueRequest").useListRow({});

  const [dataContactITBP, setDataContactITBP] = useState(contactITBP)
  const [dataContactITBPManager, setDataContactITBPBanager] = useState(contactITBPManager)
  const [dataTargetPenyelesaian, setDataTargetPenyelesaian] = useState(targetPenyelesaian)

  useEffect(() => {
    setDataContactITBP(contactITBP)
    revalidateContactITBP()
    setDataContactITBPBanager(contactITBPManager)
    revalidateContactITBPManager()
    setDataTargetPenyelesaian(targetPenyelesaian)
    revalidateTargetPengelesaian()
  }, [contactITBP, contactITBPManager, revalidateContactITBP, revalidateContactITBPManager, revalidateTargetPengelesaian, targetPenyelesaian]);

  const handleClick = () => {
    setModalShow(true)
  }

  const handleClickManager = () => {
    setModalShowManager(true)
  }

  const style = {
    "color": "blue",
    "textDecoration": "underline",
    "cursor": 'pointer'
  }

  const dataEscalationMatrix = [
    {
      id: 1,
      levelEscalation: "1st: If no response from Service Desk in 24h​",
      name: 'Rachmi Anindya Dewi​',
      email: 'rachmi.adewi@paracorpgroup.com​',
      phone: "+6281952924728",
      link: false,
      itbpManager: false
    },
    /* {
      id: 2,
      levelEscalation: "2nd: if no response from 1st level in 6h​​",
      name: 'ITBP per stream​​',
      email: 'Click to see in Contact ITBP Per Stream​',
      phone: 'Click to see in Contact ITBP Per Stream​',
      link: true,
      itbpManager: false
    }, */
    {
      id: 2,
      levelEscalation: "2nd: if no response from 1st Level in 6 hr​",
      name: "ITBP Manager per Squad",
      email: "Click to see in Contact ITBP Manager per Squad​",
      phone: "Click to see in Contact ITBP Manager per Squad",
      link: true,
      itbpManager: true
    },
  ]

  return (
    <>
      <Container className='ptb--80 ptb-md--80 ptb-sm--60 bg_color--1 pb--50'>
        <ul>
          <li><b>Hardware, Software, IT Application Issue, Service Request​</b></li>
          <ul>
            <li>Untuk service request baru atau troubleshooting, silahkan hubungi Service Desk di channel berikut:
            </li>
            <div><i className="fa fa-whatsapp" /> <a style={style} href='https://wa.me/+6281952924722' target="_blank" rel="noopener noreferrer">+62 819-5292-4722</a></div>
            <div><i className="fa fa-envelope-o" /> <a style={style} href='mailto:servicedesk@pti-cosmetics.com' target="_blank" rel="noopener noreferrer">servicedesk@pti-cosmetics.com</a></div>
            <div><i className="fa fa-file-text-o" /> <a style={style} href='https://glpi.pti-cosmetics.com' target="_blank" rel="noopener noreferrer">glpi.pti-cosmetics.com</a></div>
            <li>Penggunaan layanan standar pada Business Hours, Senin - Jumat, 08:00 - 17:00, kecuali cuti bersama dan hari libur nasional.
            </li>
            <li>Layanan tersedia selama 7x24 jam hanya untuk IT issue dengan prioritas sangat kritikal (Major Incident)​ atau request dengan prioritas sangat urgent.
            </li>
          </ul>
          <br></br>
          <li><b>Escalation Matrix​​</b></li>
          <table className="table mt-2">
            <thead style={{ color: 'red' }}>
              <tr style={{ backgroundColor: '#0c70a7', color: 'white' }}>
                <th className="pricing-header">Level of Escalation​</th>
                <th className="pricing-header">Name</th>
                <th className="pricing-header">E-mail​</th>
                <th className="pricing-header">Phone​</th>
              </tr>
            </thead>
            <tbody>
              {dataEscalationMatrix.map(data => {
                return (
                  <tr key={data.id}>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.levelEscalation }} />
                    </td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.name }} />
                    </td>
                    <td>
                      {
                        !data.link ? data.email :
                          data.levelEscalation.substring(0, 3) === '2nd' ? <span style={style} onClick={handleClick}>{data.email}</span> :
                            data.levelEscalation.substring(0, 3) === '3rd' ? <span style={style} onClick={handleClickManager}>{data.email}</span> :
                              null
                      }
                    </td>
                    <td>
                      {
                        !data.link ? data.phone :
                          data.levelEscalation.substring(0, 3) === '2nd' ? <span style={style} onClick={handleClick}>{data.phone}</span> :
                            data.levelEscalation.substring(0, 3) === '3rd' ? <span style={style} onClick={handleClickManager}>{data.phone}</span> :
                              null
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <br></br>
          <li><b>Target Penyelesaian IT Issue dan Request​​​​</b></li>
          <Accordion className={'bk-accordion mt-2'} defaultActiveKey='8287277f-015b-4323-aab6-4a2abae8bd24'>
            {dataTargetPenyelesaian.map(accordion => (
              <Card key={accordion.id}>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'} style={{ 'fontSize': '12px' }}>
                    <span dangerouslySetInnerHTML={{ __html: accordion.reqLevel }} />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={accordion.id}>
                  <Card.Body>
                    <Row>
                      <Col lg={2}>
                        <p>TIME</p>
                      </Col>
                      <Col lg={10}>
                        <Row>
                          <Col lg={6}>
                            <div>Response time :</div>
                            <p dangerouslySetInnerHTML={{ __html: accordion.respTime }} />
                          </Col>
                          <Col lg={6}>
                            <div>Resolution time :</div>
                            <p dangerouslySetInnerHTML={{ __html: accordion.resoTime }} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <p>DEFINISI</p>
                      </Col>
                      <Col lg={10}>
                        <p dangerouslySetInnerHTML={{ __html: accordion.definition }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <p>IMPACT</p>
                      </Col>
                      <Col lg={10}>
                        <p dangerouslySetInnerHTML={{ __html: accordion.impact }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <p>URGENSI</p>
                      </Col>
                      <Col lg={10}>
                        <p dangerouslySetInnerHTML={{ __html: accordion.urgency }} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </ul>
      </Container>

      <Modal
        show={modalShow}
        onHide={setModalShow}
        backdrop="static"
        keyboard={false}
        size='xl'
        centered
      >
        <Modal.Header>
          <Modal.Title>Contact ITBP per Squad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table mt-2">
            <thead>
              <tr style={{ backgroundColor: '#0c70a7', color: 'white' }}>
                <th className="pricing-header">Name​</th>
                <th className="pricing-header">Email</th>
                <th className="pricing-header">Phone</th>
              </tr>
            </thead>
            <tbody>
              {dataContactITBP.map(data => {
                return (
                  <tr key={data.id}>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.name }} />
                    </td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.email }} />
                    </td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.phone }} />
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShowManager}
        onHide={setModalShowManager}
        backdrop="static"
        keyboard={false}
        size='xl'
        centered
      >
        <Modal.Header>
          <Modal.Title>Contact ITBP Manager per Stream</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table mt-2">
            <thead>
              <tr style={{ backgroundColor: '#0c70a7', color: 'white' }}>
                <th className="pricing-header">Name​</th>
                <th className="pricing-header">Email</th>
                <th className="pricing-header">Phone</th>
              </tr>
            </thead>
            <tbody>
              {dataContactITBPManager.map(data => {
                return (
                  <tr key={data.id}>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.name }} />
                    </td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.email }} />
                    </td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.phone }} />
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalShowManager(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
