import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'

const BannerPageOne = ({ title, subtitle, color, style }) => {
  
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  
  return (
    <div
      style={{ ...style, backgroundColor: color, padding:isTabletOrMobile ? '20px' : '30px'}}
      className = "breadcrumb-area ptb--50 ptb-md--150 ptb-sm--150 breadcrumb-title-bar breadcrumb-title-white">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="breadcrumb-inner text-left">
              <h1
                className="heading heading-h1 text-white"
                style={{fontSize:isTabletOrMobile ? '30px' : '35px'}}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p className="text-white" style={{margin:'0px',fontSize:isTabletOrMobile ? '12px' : '20px'}}>{subtitle}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BannerPageOne;
