import React from 'react';
import qore from "../../../qoreContext";
import { Container, Row, Col } from 'react-bootstrap'
import SlickSlider from '../../../components/slick';
import ImageSliderParaportal from '../../../paraportal/components/image-slider';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function FlexibleImageSliderOneParaportal() {
  const { data: dataBanner } = qore.view("homepageBannerActive").useListRow({});

  const NextArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-right" />
      </button>
    );
  };

  const PrevArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-left" />
      </button>
    );
  };

  const settings = {
    slidesToShow: 1,
    dots: true,
    arrows: true,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className="flexible-image bg_color--1 move-up wow">
      <Container>
        <Row>
          <Col xs={12}>
            <SlickSlider settings={settings}
              classes="brook-element-carousel flexible-arrow flexible-dots"
            >
              {dataBanner.length ? dataBanner.map((sliderImage) => (
                <ImageSliderParaportal key={sliderImage.id} image={sliderImage.gambar} />
              )) :  <Skeleton height={750} />}
            </SlickSlider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
