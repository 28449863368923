import React from 'react'
import { Helmet } from "react-helmet";
import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";
import BannerITSupportService from './BannerITSupportService';
import ContentITSUpportService from './content/ContentITSUpportService';

export default function ITSupportService() {
  return (
    <>
      <Helmet>
        <title>PARAPORTAL - IT Support Service</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <BannerITSupportService
          title="IT Support Service"
          subtitle="Together We Drive Technology and Innovation"
          color="#6A8AFC"
        />
        <ContentITSUpportService />
      </LayoutDefaultParaportal>
    </>
  )
}