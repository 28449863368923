import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import Article from "./elements/Article";
import Sidebar from "./elements/Sidebar";
import qore from "../../../qoreContext";
import { useParams } from "react-router-dom";
import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";
import image from '../Service/assets/img/hrsc.jpg'

export default function HrscArticle(sidebar = true) {
  const { areaKerja, articleTitle } = useParams();

  const { data } = qore.view("hrscArticle").useListRow({
    limit: 1,
    areaKerja,
    title: articleTitle,
  });
  const [articleContent] = data;

  return (
    <LayoutDefaultParaportal>
      <div>
        <img src={image} className='img-fluid shadow-4' alt='.' />
      </div>
      <div className="brook-blog-details-area bg_color--1 pb--150">
        <Container>
          <Row>
            {sidebar && (
              <Col lg={3} className="mt_md--60 mt_sm--60">
                <Sidebar />
              </Col>
            )}
            <Col lg={9} className={!sidebar ? "mx-auto" : null}>
              <div className="blog-details-wrapper">
                <Article {...articleContent} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LayoutDefaultParaportal>
  );
}
