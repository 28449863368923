import React from "react";

const DivisionItem = ({ name, description, classes }) => {
  return (
    <div
      className={`${classes && classes}`}
      style={{
        height: "40rem",
        backgroundColor: "#f0f0f0",
        display: "flex",
        alignItems: "center",
        padding: "3rem",
      }}>
      <div className="content">
        <p
          style={{
            marginBottom: "1.5rem",
            fontSize: "24px",
            fontWeight: "600",
          }}>
          {name}
        </p>
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "1.5",
          }}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default DivisionItem;
