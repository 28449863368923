import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import BlogDetailsContent from "./blog-details";
import LayoutDefaultParaportal from "../../../layouts/LayoutDefaultParaportal";

import { useParams } from 'react-router-dom';

const BlogDetailsNoSidebar = () => {
  const { idNews } = useParams();
  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - News</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <BlogDetailsContent sidebar={false} idNews={idNews} />
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default BlogDetailsNoSidebar;
