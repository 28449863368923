import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'
import { Link } from "react-router-dom";

const MasterData = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const dataMenu = [
    {
      'id': 1,
      'title': 'Upstream',
      'image': 'upstream.png',
      'link': '/master-data-management/upstream'
    },
    {
      'id': 2,
      'title': 'Downstream',
      'image': 'downstream.png',
      'link': '/master-data-management/downstream'
    },
    {
      'id': 3,
      'title': 'Others',
      'image': 'others.png',
      'link': '/master-data-management/others'
    }
  ]

  return (
    <Container
      className='ptb--50 ptb-md--80 ptb-sm--60 bg_color--1'
      style={isDesktopOrLaptop ? { paddingLeft: '200px', paddingRight: '200px' } : null}
    >
      <Row>
        {dataMenu.map(menu => (
          <Col xs={5} sm={6} lg={4} className="mt--20 pl--5" key={menu.id}>
            <Link to={menu.link}>
              <div className={`portfolio portfolio_style--1`}>
                <div className="thumb">
                  <img src={require(`../asset/${menu.image}`)} alt={menu.title} />
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default MasterData;