import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BlogItem from "../../../../components/blog/BlogItemSeven";
import "react-modal-video/scss/modal-video.scss";
import { matchSorter } from "match-sorter";

const BlogTwo = ({ view, title, newsList }) => {
  const [listNews, setListNews] = useState([]);

  useEffect(() => {
    let temp = [];
    newsList.forEach((p) => {
      temp.push({
        idQore: p.id,
        id: p.orderNumber,
        title: p.title,
        thumb: p.tumbnail,
        dateCreated: p.dateCreated,
        videoId: false,
        meta: {
          author: "Paragon",
          category: p.tag,
          postDate: p.dateCreated,
        },
      });
    });
    temp = matchSorter(temp, "", { keys: ["dateCreated"] });
    temp.reverse();
    setListNews(temp);
  }, [newsList]);

  if (!newsList.length) return null;
  return (
    <div
      className="bk-blog-area bg_color--5 section-ptb-50 clearfix"
      style={{
        backgroundColor: "#215AA8",
        minHeight: "30rem",
        paddingBottom: "5rem",
        marginBottom: "5rem",
      }}>
      <Container>
        <Row>
          <Col
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}>
            <p
              className="text-white"
              style={{
                fontSize: "48px",
                fontWeight: "700",
                lineHeight: "72px",
                textAlign: "start",
                width: "80%",
                margin: 0,
              }}>
              News
            </p>
          </Col>
          <Col
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}>
            <div className="section-title--between wow move-up">
              <div className="section-btn mt_mobile--30">
                <Link
                  className="button-text"
                  style={{ color: "#215AA8" }}
                  to={`${process.env.PUBLIC_URL + "/all-news"}`}>
                  <span style={{ color: "#fff" }}>See All</span>
                  <i style={{ color: "#fff" }} className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="row mt--0">
          {listNews?.slice(0, 4).map((blog) => (
            <Fragment key={blog.id}>
              <Col xs={12} sm={6} xl={3} className="wow move-up mt--30">
                <BlogItem data={blog} />
              </Col>
            </Fragment>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default BlogTwo;
