import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";


const ContactUsBI = () => {


  return (
    <Container
      className='ptb--50 ptb-md--80 ptb-sm--60 bg_color--1'
    >
      <Row>
        <Col>
          <Card style={{ 'borderColor' : '#6c8afa', 'borderRadius': '15px' }}>
            <Card.Header style={{ 'fontWeight' : '600', 'backgroundColor' : '#6c8afa', 'color' : 'white', 'borderTopLeftRadius': '15px', 'borderTopRightRadius': '15px'}}>
              Tableau
            </Card.Header>
            <Card.Body>
              <p style={{ 'fontWeight': '500' }}>Request to Access Report :</p>
              <ul>
                <li>Complete LTO : <a href="https://bit.ly/LTO-Tableau">https://bit.ly/LTO-Tableau</a></li>
                <li>Submit Request :  <a href="https://bit.ly/LighthouseReq">https://bit.ly/LighthouseReq</a></li>
              </ul>
              <br></br>
              <p style={{ 'fontWeight': '500' }}>Queries / Problem / Feedback</p>
              <ul>
                <li>Mail to : corporate.business.information@pti-cosmetics.com</li>
                <li>Contact : <a href="https://wa.me/6281806771239">wa.me/6281806771239</a></li>
                <li>Submit Issue  : <a href="https://bit.ly/LighthouseSupport">https://bit.ly/LighthouseSupport</a></li>
                <li>Telegram Group : <a href="https://bit.ly/LighthouseGroup">https://bit.ly/LighthouseGroup</a></li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ 'borderColor' : '#7979f1', 'borderRadius': '15px' }}>
            <Card.Header style={{ 'fontWeight' : '600', 'backgroundColor' : '#7979f1', 'color' : 'white', 'borderTopLeftRadius': '15px', 'borderTopRightRadius': '15px'}}>
              Redash
            </Card.Header>
            <Card.Body>
              <p style={{ 'fontWeight': '500' }}>Redash / Superset :</p>
              <ul>
                <li>Submit ticket via GLPI : <a href="https://glpi.pti-cosmetics.com">glpi.pti-cosmetics.com</a></li>
                <li>Guide : <a href="https://bit.ly/selfserviceGLPI">http://bit.ly/selfserviceGLPI</a></li>
                <li>Mail ti : servicedesk@pti-cosmetics.com</li>
                <li>Contact : <a href="https://wa.me/6281952924722">wa.me/6281952924722</a></li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUsBI;
