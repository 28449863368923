import React from 'react'
import { Container } from 'react-bootstrap'
import KaryaParagonianSliderContent from '../content/KaryaParagonianSliderContent'

export default function KaryaParagonian() {
    return (
        <div>
            <Container className='ptb--80 ptb-md--80 ptb-sm--60'>
                <div className="brook-section-title mb--50 mb_sm--20 mb_md--20 wow move-up">
                    <h3 className="heading heading-h3 mb-2" style={{ 'color': '#215AA8' }}>Karya Paragonian</h3>
                    <p style={{ 'color': '#215AA8' }}>Download dan baca e-book karya Paragonian disini</p>
                </div>
                <KaryaParagonianSliderContent />
            </Container>
        </div>
    )
}
