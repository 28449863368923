import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";
import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactUsMDM from "./ContactUs/ContactUsMDM";
import FavoritesMDM from "./FavoritesMDM/FavoritesMDM";
import GlossaryMDM from "./Glossary/GlossaryMDM";
import ListCatMDM from "./ListCatMDM";
import MasterData from "./MasterData/MasterData";
import NewsletterMDM from "./Newsletter/NewsletterMDM";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabMDM');

export default function MasterDataManagement() {
  const [category, setCategory] = useState(tab ? tab : 'Master Data');
  const history = useHistory();
  const params = new URLSearchParams({ tabMDM: category });

  const handleChangeCategory = (item) => {
    setCategory(item);
  };

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [category])

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Master Data Management</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <ListCatMDM
          title="Master Data Management"
          subtitle="Master Data information library"
          color="#6A8AFC"
          handleChangeCategory={handleChangeCategory}
          category={category}
        />
        {category === "Master Data" && <MasterData />}
        {category === "Glossary" && <GlossaryMDM />}
        {category === "Newsletter" && <NewsletterMDM />}
        {category === "Contact Us" && <ContactUsMDM />}
        {category === "Favorites" && <FavoritesMDM />}
      </LayoutDefaultParaportal>
    </Fragment>
  )
}
