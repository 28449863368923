import React, { useState, useEffect, useMemo } from "react";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import qore from "../../../../../../qoreContext";
import FilterComponent from "../../../FilterComponent";
import LoveComp from "../../LoveComp";

const customStyles = {
    rows: {
        style: {
            minHeight: "72px", // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",
            backgroundColor: "#EBF3FF",
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
        },
    },
};

const RedashCommercial = () => {
    const [pending, setPending] = useState(true);
    const [dataTable, setDataTable] = useState([]);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState("");

    const { data: allRedashList } = qore.view("allRedashList").useListRow({
        category : 'commercial'
    });

    const filteredItems = allRedashList.filter(item => (
        item.title && item.title.toLowerCase().includes(filterText.toLowerCase()))
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
                placeholder="Search Redash"
            />
        );
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDataTable(filteredItems);
            setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [filteredItems]);

    const columns = [
        {
            name: "Name",
            selector: (row) => <a href={row.link} target='_blank' rel="noopener noreferrer">{row.title}</a>,
            sortable: true,
            wrap: true,
        },
        {
            name: "Add to favorites",
            cell: (row) =>
                <LoveComp valuedashboard={
                    {
                        "id": row.id,
                        "title": row.title,
                        "category": row.category,
                        "link": row.link,
                        "table": row.table,
                    }
                }
                />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: false,
        },
    ];

    return (
        <Container className="ptb--80 ptb-md--80 ptb-sm--60 bg_color--1 pb--50">
            <DataTable
                columns={columns}
                data={dataTable}
                pointerOnHover
                highlightOnHover
                pagination
                progressPending={pending}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
            />
        </Container>
    );
};

export default RedashCommercial;
