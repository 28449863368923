import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'
import CompReseorce from '../../../components/CompReseorce'
import FAQ from '../../bomMDM/content/resContent/FAQ'

const accordionContent = [
    {
        "id": 1,
        "title": "Training",
        "content": <CompReseorce title="Link training videos Bills of Material" link="https://cloud.pti-cosmetics.com/index.php/s/jDHS6mLJfG68Kka"/>
    },
    {
        "id": 2,
        "title": "Post Test",
        "content": <CompReseorce title="Link post test Bills of Material" link="https://drive.google.com/drive/folders/1aMXFPCMppcXpSlgiL5NVvPE7iiB7vb36?usp=sharing"/>
    },
    {
        "id": 3,
        "title": "Flow",
        "content": <CompReseorce title="Link flow Bills of Material" link="https://drive.google.com/drive/folders/1z2u-AZ1HC8ocA-MHhu79Vqu14eHr5Kcx?usp=sharing"/>
    },
    {
        "id": 4,
        "title": "User Guide",
        "content": <CompReseorce title="Link user guide Bill of Material" link="https://docs.google.com/document/d/18texwmGyWo_YTRWrB9dAncyrCE3DQTr7T0uQfSEIx4c/edit?usp=sharing"/>
    },
    {
        "id": 5,
        "title": "FAQ",
        "content": <FAQ />
    },
]

export default function Resource() {
    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
                <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                    {accordionContent.map(accordion => (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                    {accordion.title}
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={accordion.id}>
                                <Card.Body>{accordion.content}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Container>
        </>
    )
}
