import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import BlogItem from "../../../../components/blog/BlogItemSeven";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const WithOutSidebarLayout = ({ data }) => {
  const [page, setPage] = useState(1)
  const [listPages, setListPages] = useState([])
  const [newsShowed, setNewsShowed] = useState([])

  useEffect(() => {
    const pages = Math.floor(data?.length / 16) + 1
    const listPage = []
    for (let i = 1; i <= pages; i++) {
      listPage.push(i)
    }
    setListPages(listPage)
    setNewsShowed(data?.slice(0, 16))
  }, [data])

  const handleChangePage = ((e, p) => {
    setPage(p)
    setNewsShowed(data?.slice((16 * (parseInt(p) - 1)), (16 * (parseInt(p)))))
  })

  return (
    <Fragment>
      <Row className="mt--n60">
        {newsShowed?.length ? newsShowed?.map(blog => (
          <Col lg={3} sm={4} key={blog.id}>
            <BlogItem
              data={blog}
              className="mt--60 blog-theme-color"
            />
          </Col>
        )) :
          <Row>
            <Col xs md="3">
              <Skeleton height={150} />
            </Col>
            <Col xs md="3">
              <Skeleton height={150} />
            </Col>
            <Col xs md="3">
              <Skeleton height={150} />
            </Col>
            <Col xs md="3">
              <Skeleton height={150} />
            </Col>
          </Row>
        }
      </Row>

      <Row>
        <Col lg={12}>
          <div className="brook-pagination-wrapper text-center pt--80">
            <ul className="brook-pagination">
              <li><button><i className="fa fa-angle-left" /></button></li>
              {listPages?.map(p => (
                <>
                  {(p === page)
                    ? <li className="active"><button>{p}</button></li>
                    : <li><button onClick={(e) => handleChangePage(e, p)}>{p}</button></li>
                  }
                </>
              ))}
              <li><button><i className="fa fa-angle-right" /></button></li>
            </ul>
          </div>
        </Col>
      </Row>
    </Fragment>

  );
};

export default WithOutSidebarLayout;
