import React from "react";
import { Accordion, Card } from "react-bootstrap";

export default function AccordionItem({ accordionItem, children }) {
  return (
    <Card style={{ border: "0px" }}>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "0.25rem 0",
          border: "0px",
        }}>
        <Accordion.Toggle
          // as={Button}
          // variant="link"
          eventKey={accordionItem.id}
          style={{
            color: "#868686",
            textDecoration: "none",
            padding: "0",
            border: "0px",
            textAlign: "left",
            width: "100%",
          }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "15%%",
              }}>
              &#9658;
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                paddingLeft: "0.5rem"
              }}>
              {accordionItem.title}
            </div>
          </div>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={accordionItem.id}>
        {children ? children : null}
      </Accordion.Collapse>
    </Card>
  );
}
