import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/banner/BannerPageOne";
import dataMetaCulture from './dataMetaCulture.json'
import img from "../../../assets/img/paraportal/mascot-02.png";
import LeftImageCultureSection from "./container/LeftImageCultureSection";
import RightImageCultureSection from "./container/RightImageCultureSection";
import VisionContent from "./content/VisionContent";
import ParmasysContent from "./content/ParmasysContent";
import MisionContent from "./content/MisionContent";
import CoreValueContent from "./content/CoreValueContent";
import SliderParaco from "./container/SliderParaco";
import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";
import OurVideo from "./container/OurVideo";
import KaryaParagonian from "./container/KaryaParagonian";

export default function CultureParaportal() {
  return (
    <Fragment>
      <Helmet>
        <title>{`PARAPORTAL - ${dataMetaCulture.title}`}</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <Breadcrumb title="Hai Paragonian, Selamat Datang di Paragon Culture!" color="#1f5aa8" img={img} />
        <LeftImageCultureSection
          title="Vision"
          image="culture-visi-misi-value.jpg"
          content={<VisionContent />}
        />
        <RightImageCultureSection
          title="Mision"
          content={<MisionContent />}
          image="culture-visi-misi-value.jpg"
        />
        <LeftImageCultureSection
          title="Paragon Management System"
          image="Rumah-Parmasys-FULL.png"
          content={<ParmasysContent />}
        />
        <RightImageCultureSection
          title="Core Value"
          image="Rumah-Parmasys-FULL.png"
          content={<CoreValueContent />}
        />
        <SliderParaco />
        <OurVideo />
        <KaryaParagonian />
      </LayoutDefaultParaportal>
    </Fragment>
  );
}