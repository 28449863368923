import React from 'react';
import qore from "../../../../../../qoreContext";

const Navigation = ({ prev, next}) => {
    const { data : dataNewsPrev } = qore.view("allNews").useGetRow(prev);
    const { data : dataNewsNext } = qore.view("allNews").useGetRow(next);
    
    return (
        <div className="post-nav-list mb--45 wow move-up">
            <div className="nav-item previous">
                {dataNewsPrev && 
                    <a href={`${process.env.PUBLIC_URL + '/news-detail/'+dataNewsPrev?.id}`}>
                        <div className="link-text">
                            <span className="fa fa-arrow-left"/>
                            <p>Prev</p>
                        </div>
                        <span>{dataNewsPrev?.title}</span>
                    </a>
                }  
            </div>
            <div className="nav-item next mt_sm--30">
                {dataNewsNext && 
                    <a href={`${process.env.PUBLIC_URL + '/news-detail/'+dataNewsNext?.id}`}>
                        <div className="link-text">
                            <p>Next</p>
                            <span className="fa fa-arrow-right"/>
                        </div>
                        <span>{dataNewsNext?.title}</span>
                    </a>
                }
            </div>
        </div>
    );
};

export default Navigation;
