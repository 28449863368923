import React from "react";

export default function WorkAreaHeader({ workAreaName, isFirstIndex }) {
  return (
    <p
      style={{
        backgroundColor: "#4398ba",
        color: "#fff",
        padding: "0.5rem 1.5rem",
        fontSize: "18px",
        borderRadius: isFirstIndex ? "12px 12px 0 0" : "0",
      }}>
      {workAreaName}
    </p>
  );
}
