import IconListItemOneParaportal from '../../../components/icon-list/IconListItemOneParaportal';
import React from 'react';

const MisionContent = () => {
    return (
        <div>
            {dataMision.map(item => (
                <IconListItemOneParaportal data={item} key={item.id}/>
            ))}
        </div>
    );
};

const dataMision = [
    {
        id: 1,
        iconClass: "ion-ios-infinite",
        imgIcon : "mision-1.png",
        title: "Mengembangkan Paragonian",
        content: "Belajar secara berkesinambungan dan mengembangkan Paragonian yang kompeten dan berdaya saing."
    },
    {
        id: 2,
        iconClass: "ion-monitor",
        imgIcon : "mision-2.png",
        title: "Menciptakan kebaikan untuk pelanggan",
        content: "Kami mendengarkan kebutuhan pelanggan dan menciptakan produk yang melampaui harapan pelanggan."
    },
    {
        id: 3,
        iconClass: "ion-ios-baseball-outline",
        imgIcon : "mision-3.png",
        title: "Perbaikan berkesinambungan",
        content: "Kami senantiasa berinovasi dalam seluruh proses untuk meningkatkan kualitas produk."
    },
    {
        id: 4,
        iconClass: "ion-pinpoint",
        imgIcon : "mision-4.png",
        title: "Tumbuh bersama-sama",
        content: "Kami bekerja sama dengan mitra bisnis demi kemajuan bersama"
    },
    {
        id: 5,
        iconClass: "ion-pinpoint",
        imgIcon : "mision-5.png",
        title: "Memelihara bumi",
        content: "Bumi menyediakan semua kebutuhan hidup kita, kami senantiasa berusaha memeliharanya."
    },
    {
        id: 6,
        iconClass: "ion-pinpoint",
        imgIcon : "mision-6.png",
        title: "Mendukung pendidikan dan kesehatan bangsa",
        content: "Kami berusaha membangun generasi melalui pengembangan sumber daya manusia berpengetahuan, berpengalaman dan sehat."
    },
    {
        id: 7,
        iconClass: "ion-pinpoint",
        imgIcon : "mision-7.png",
        title: "Mengembangkan Bisnis",
        content: "Kami melakukan pengembangan produk, jasa dan ekspansi unit bisnis."
    }
]

export default MisionContent;
