import React from 'react';
import Input from "../../../../../../components/input";
import Button from "../../../../../../components/button";
import Cookies from "universal-cookie";
import qore from "../../../../../../qoreContext";

const CommentForm = ({ idNews }) => {
    const cookies = new Cookies();
    const [userEmail] = React.useState(cookies.get('user_email'));
    const [comment, setComment] = React.useState('');

    const { insertRow : insertComment } = qore.view("allNewsComment").useInsertRow({});
    
    const inputHandler = (e) => {
        setComment(e.target.value)
    }
    
    const handleSubmitComment = async (event) => {
        const dataComment = {
            user: userEmail,
            comment: comment,
            news: idNews
        };
        await insertComment(dataComment);
        setComment('')
        window.location.reload();
    }
    
    
    return (
        <div className="comment-form-wrapper">
            <div className="header wow move-up">
                <h4 className="heading heading-h4">Leave A Comment</h4>
                <div className="comment-note mt--20 mb--30">
                    <p className="bk_pra">Logged in as {userEmail} </p>
                </div>
            </div>

            <div className="contact-form wow move-up">
                <div className="form-inputs">
                    <Input
                        type="textarea"
                        placeholder="Your Comment *"
                        required={true}
                        value = {comment}
                        onChange={(e) => inputHandler(e)}
                    />

                    <div className="blog-btn mt--30">
                        <Button
                            type="button"
                            btnText="Post Comment"
                            color="theme"
                            size="sd"
                            btnStyle="rounded"
                            onClick={handleSubmitComment}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentForm;
