import React, { useEffect } from "react";
import imagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";
import { Col, Container, Row } from "react-bootstrap";
import PortfolioItem from "../../../../components/portfolio/PortfolioItemOne";

const PortfolioMasonry = ({ wide, gutter, galleries }) => {
  useEffect(() => {
    const grid = document.querySelector(".masonry-list");
    imagesLoaded(grid, () => {
      new Masonry(grid);
    });
  }, [galleries]);

  return (
    <div
      className="brook-portfolio-area bg_color--1 bk-masonry-wrapper"
      style={{ marginBottom: "4rem" }}>
      <Container fluid={!!wide} className={wide ? "px-0" : ""}>
        <Row>
          <Col lg={12}>
            <div className="masonry-list portfolio-grid-metro4">
              <div className="resizer" />
              {galleries.map((item) => (
                <div
                  className={`grid-masonry-item portfolio-33-33 ${
                    !gutter ? "px-0 mb-0" : ""
                  }`}
                  key={item.id}>
                  <PortfolioItem title={item.name} thumb={item.displayImage} />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PortfolioMasonry;
