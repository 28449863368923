import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'
import CompReseorce from '../../../upstream/components/CompReseorce'

const accordionContent = [
    {
        "id": 1,
        "title": "Training",
        "content": <CompReseorce title="Link training videos Customer" link="https://cloud.pti-cosmetics.com/index.php/s/arjtoKaQQnFfyPi"/>
    },
    {
        "id": 2,
        "title": "Post Test",
        "content": <CompReseorce title="Link post test Customer" link="https://drive.google.com/drive/folders/1MCQ5SXKc3P_8xt5tfc49Ag61-J-3h7-7?usp=sharing"/>
    },
    {
        "id": 3,
        "title": "Flow",
        "content": <CompReseorce title="Link flow Customer" link="https://drive.google.com/drive/folders/1MCQ5SXKc3P_8xt5tfc49Ag61-J-3h7-7?usp=sharing"/>
    },
    {
        "id": 4,
        "title": "User Guide",
        "content": <CompReseorce title="Link user guide Customer" link="https://docs.google.com/document/d/10WcCUVoWF19M6hFF0gga4xxp3lOmxv9jTg6A3YZe0IU/edit#heading=h.vx42ybbabre4"/>
    },
    {
        "id": 5,
        "title": "FAQ",
        "content": "Coming soon"
    },
]

export default function Resource() {
    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
                <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                    {accordionContent.map(accordion => (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                    {accordion.title}
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={accordion.id}>
                                <Card.Body>{accordion.content}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Container>
        </>
    )
}
