import React, {useEffect, useState} from 'react';
import {Accordion, Container, Row, Col} from 'react-bootstrap'
import AccordionItem from "./AccordionItem";
import dateFormat from "dateformat";
import qore from "../../../../qoreContext";

const AccordionOne = ({limit}) => {
  const today = new Date();
  const [listEvent, setListEvent] = useState([])
  const [dateChoosed, setDateChoosed] = useState(today)
  const { data : dataAllEvent } = qore.view("eventActive").useListRow({
    overrideLimit : true,
    '$by.startDate' : 'desc',
    month: dateFormat(dateChoosed,"mmmm"),
    year: dateFormat(dateChoosed,"yyyy")
  });

  useEffect(() => {
    let temp = []
    let count = 1
    dataAllEvent.forEach(p => {
      temp.push({
        'id' : count,
        'title' : p.title,
        'month' : p.month,
        'subtitle' : p.subtitle,
        'description' : p.description,
        'startDate' : p.startDate,
        'endDate' : p.endDate
      })
      count=count+1
    });
    setListEvent(temp)
  }, [dataAllEvent])
  
  const handlePrevMonth = () => {
    const newDate = new Date(dateChoosed.getFullYear(), dateChoosed.getMonth() - 1, 1)
    setDateChoosed(newDate)
  }

  const handleNextMonth = () => {
    const newDate = new Date(dateChoosed.getFullYear(), dateChoosed.getMonth() + 1, 1)
    setDateChoosed(newDate)
  }

  return (
    <div className={limit ? 'bk-accordion-area' : 'bk-accordion-area ptb--50 ptb-md--80 ptb-sm--60 bg_color--1 basic-thine-line'}>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="section-title--between wow move-up" style={{justifyContent:'flex-start'}}>
              <div className="title">
                <h4 style={{color: '#7d66b3', marginTop:'20px', marginRight:'20px'}}>{dateFormat(dateChoosed,"mmmm")} {dateFormat(dateChoosed,"yyyy")}</h4>
              </div>
              <div className="section-btn mt_mobile--30">
                <Row>
                  <Col xs={6}>
                    <div className="button-text" style={{color: '#7d66b3', cursor: 'pointer'}} onClick={handlePrevMonth}
                      ><i className="fa fa-arrow-left"/>
                    </div>
                  </Col>

                  <Col xs={6}>
                    <div className="button-text" style={{color: '#7d66b3', cursor: 'pointer'}} onClick={handleNextMonth}
                      ><i className="fa fa-arrow-right"/>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="m-auto">
            <Accordion defaultActiveKey={""} className={'bk-accordion'}>
              {listEvent.length === 0 ? <span style={{marginLeft:'20px'}}>No Event in {dateFormat(dateChoosed,"mmmm")}</span> : null}
              {/* {listEvent.map(accordion => (
                <AccordionItem
                  key={accordion.id}
                  eventKey={`${accordion.id}`}
                  data={accordion}
                />
              ))} */}

              {limit ? listEvent.slice(0, limit).map(accordion => (
                <AccordionItem
                  key={accordion.id}
                  eventKey={`${accordion.id}`}
                  data={accordion}
                />
              )) : listEvent.map(accordion => (
                <AccordionItem
                  key={accordion.id}
                  eventKey={`${accordion.id}`}
                  data={accordion}
                />
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccordionOne;
