import React from "react";
import Cookies from "universal-cookie";

const ContactClassic = () => {
  const cookies = new Cookies();
  const [loginStatus, setLoginStatus] = React.useState(true);

  React.useEffect(() => {
    cookies.remove("userEmail");
    cookies.remove("token");
    cookies.remove("pdpdkzqh");
    localStorage.clear();
    setLoginStatus(false);
  }, [cookies, setLoginStatus]);

  if (!loginStatus) {
    return (
      window.location.replace('/')
    )
  } else {
    return <></>;
  }
};

export default ContactClassic;
