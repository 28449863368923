import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import YouTube from "react-youtube";
import { useMediaQuery } from "react-responsive";
import qore from "../../../../../qoreContext";

export default function SupersetBIContent() {
  const { data: dataTrainingVideoBI } = qore.view("allTrainingVideosBi").useListRow({
    category: 'Superset'
  });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  const opts = {
    height: isDesktopOrLaptop ? "150" : "100",
    width: isDesktopOrLaptop ? "250" : "130",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Container className="ptb--50 ptb-md--80 ptb-sm--60 bg_color--1">
      <Row>
        {dataTrainingVideoBI.length
          ? dataTrainingVideoBI.map((dataVideo) => (
            <Col
              xs={5}
              sm={6}
              lg={3}
              className="mt--20 pl--5"
              key={dataVideo.id}
            >
              <div className="text-center">
                <YouTube opts={opts} videoId={dataVideo.youtubeId} />
                <p className="h5">{dataVideo.title}</p>
              </div>
            </Col>
          ))
          : 'Nothing data to show'}
      </Row>
    </Container>
  );
}
