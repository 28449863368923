import React from "react";
import { Accordion } from "react-bootstrap";
import AccordionItem from "./AccordionItem";
import ArticleLink from "./ArticleLink";

export default function WorkAreaMenu({
  topCategory,
  defaultActiveKeys,
  workArea,
}) {
  return (
    <AccordionItem accordionItem={topCategory}>
      <Accordion
        defaultActiveKey={defaultActiveKeys[1]}
        style={{ paddingLeft: "1rem" }}>
        {/* LEVEL 1 */}
        {topCategory.children.map((category1, i) => {
          return (
            <>
              {category1.children ? (
                <AccordionItem accordionItem={category1}>
                  <Accordion
                    defaultActiveKey={defaultActiveKeys[2]}
                    style={{ paddingLeft: "1rem" }}>
                    {/* LEVEL 2 */}
                    {category1.children.map((category2, j) => {
                      return (
                        <>
                          {category2.children ? (
                            <AccordionItem accordionItem={category2}>
                              {/* LOWEST ARTICLE */}
                              <div
                                style={{
                                  paddingLeft: "1rem",
                                }}>
                                {category2.children.map((lowestArticle, k) => {
                                  return (
                                    <ArticleLink
                                      title={lowestArticle.title}
                                      workArea={workArea.title}
                                    />
                                  );
                                })}
                              </div>
                            </AccordionItem>
                          ) : (
                            <ArticleLink
                              title={category2.title}
                              workArea={workArea.title}
                            />
                          )}
                        </>
                      );
                    })}
                  </Accordion>
                </AccordionItem>
              ) : (
                <ArticleLink
                  title={category1.title}
                  workArea={workArea.title}
                />
              )}
            </>
          );
        })}
      </Accordion>
    </AccordionItem>
  );
}
