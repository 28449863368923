import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../components/banner/BannerPageTwo";
import ApplicationServices from "../../pages/apps/application-services/ApplicationServices"
import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";

const AppsPage = () => {

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Apps</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <Breadcrumb
          title="APPS"
          subtitle="Together We Drive Technology and Innovation"
          color="#6A8AFC"
        />
        <ApplicationServices />
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default AppsPage;