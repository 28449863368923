import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import markerIcon from '../../assets/img/paragon_pinpoint.png'
import { Modal } from 'react-bootstrap'
import qore from "../../../qoreContext";

function MapItemOneParaportal({ google }) {

	const { data: allListDc } = qore.view("allListDc").useListRow({
		overrideLimit: true,
	});
	const markers = allListDc

	const [modalShowManager, setModalShowManager] = React.useState(false);
	const [dataPin, setDataPin] = React.useState('Head Office');

	const handleActiveMarker = (marker) => {
		setModalShowManager(true)
		setDataPin(marker)
	};

	const handleOnLoad = (map) => {
		const bounds = new google.maps.LatLngBounds();
		markers.forEach(({ position }) => bounds.extend(position));
		map.fitBounds(bounds);
	};

	return (
		<>
			<Map
				onLoad={handleOnLoad}
				onClick={() => setModalShowManager(false)}
				mapContainerStyle={{ width: "100vw", height: "100vh" }}
				google={google}
				initialCenter={{
					"lat": -1.4181904,
					"lng": 108.3785394
				}}
				zoom={5}
			>
				{
					markers.map(pin => (

						<Marker
							key={pin.id}
							position={pin.position}
							onClick={() => handleActiveMarker(pin)}
							icon={{ url: markerIcon }}
						/>

					))
				}
			</Map>

			<Modal
				show={modalShowManager}
				onHide={setModalShowManager}
				keyboard={false}
				size='lg'
				centered
			>

				<Modal.Body className='text-center'>
					<h4 className='mb-3'><b>{dataPin.name ? dataPin.name : 'Name'}</b></h4>
					<span>{dataPin.phone ? dataPin.phone : 'Phone'}</span><br></br>
					<span>{dataPin.email ? dataPin.email : 'Email'}</span>
				</Modal.Body>

			</Modal>
		</>
	)
};


export default GoogleApiWrapper({
	apiKey: 'AIzaSyBZz9CKMF-nccXhnKeHXzM1VIxw4i_5gNk'
})(MapItemOneParaportal);
