import React from 'react';
import MapItemOneParaportal from '../../components/map/MapItemOneParaportal';

const MapOneParaportal = props => {
    return (
        <div className="google-map-area pt--40 pt-md--80 pt-sm--80 bg_color--1 section " style={{ 'backgroundColor' : '#F7F7F7' }}>
            <div className="brook-section-title mb--40 pl--150 pr--150">
                <h3 className="heading heading-h3" style={{ 'color': '#215AA8' }}>We’re all over places</h3>
            </div>

            <div className="position-relative" style={{height: '500px'}}>
                <MapItemOneParaportal latitude="47.444" longitude="-122.176"/>
            </div>
        </div>
    );
};

export default MapOneParaportal;
