import React, { useState, useEffect, useMemo } from "react";
import { Container } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import qore from "../../../../../../../../../qoreContext";
import FilterComponent from "../../../../../../../BusinessIntelligence/FilterComponent";

const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#EBF3FF',
            fontSize: '16px',
            fontWeight: 'bold'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const SemiFinishedGoodsBulkRefill = () => {

    const [pending, setPending] = useState(true)
    const [dataTable, setDataTable] = useState([])

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    const [filterText, setFilterText] = useState('')

    const { data: dataTableRaw } = qore.view("allUpstreamProductCatalogueSemiFinishedGoodsRefill").useListRow({
        overrideLimit: true,
    });


    const filteredItems = dataTableRaw.filter(item =>
        (item.tab && item.tab.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.field && item.field.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.dataType && item.dataType.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.description && item.description.toLowerCase().includes(filterText.toLowerCase()))||
        (item.mandatory && item.mandatory.toLowerCase().includes(filterText.toLowerCase())),
    )

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
                placeholder="Search Catalogue Semi Finished Goods Refill"
            />
        );
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDataTable(filteredItems);
            setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [filteredItems])


    const columns = [
        {
            name: 'Tab',
            selector: row => row.tab,
            sortable: true,
            wrap: true
        },
        {
            name: 'Field',
            selector: row => row.field,
            sortable: true,
            wrap: true

        },
        {
            name: 'Data Type',
            selector: row => row.isNoHtml ?
                row.definition :
                <div dangerouslySetInnerHTML={{
                    __html: row.dataType
                }}
                />,
            wrap: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            wrap: true

        },
        {
            name: 'Mandatory',
            selector: row => row.mandatory,
            sortable: true,
            wrap: true

        },
    ];

    return (
        <Container className='bg_color--1 pb--50'>
            <DataTable
                columns={columns}
                data={dataTable}
                pointerOnHover
                highlightOnHover
                pagination
                progressPending={pending}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
            />
        </Container>
    );
};

export default SemiFinishedGoodsBulkRefill;
