import React, { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BlogItem from "../../../../components/blog/BlogItemSeven";
import 'react-modal-video/scss/modal-video.scss';
import { matchSorter } from "match-sorter"
import qore from "../../../../qoreContext";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const BlogTwo = ({ view, title }) => {
  const [tag1News, setTag1News] = useState();
  const [tag2News, setTag2News] = useState();
  const [tag3News, setTag3News] = useState();
  const [tag4News, setTag4News] = useState();
  const [listNews, setListNews] = useState([])
  const { data: dataAllNews } = qore.view("newsPublish").useListRow({
    tag: tag1News,
    tag2: tag2News,
    tag3: tag3News,
    tag4: tag4News
  });

  useEffect(() => {
    if (view === 'segment1') {
      setTag1News('News')
      setTag2News('Internal Update')
      setTag3News('Business Update')
      setTag4News('News & Event')
    } else if (view === 'segment2') {
      setTag1News('Innovation Magazine')
      setTag2News('Product Knowledge')
    } else if (view === 'segment3') {
      setTag1News('Covid 19')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let temp = []
    dataAllNews.forEach(p => {
      temp.push({
        'idQore': p.id,
        'id': p.orderNumber,
        'title': p.title,
        'thumb': p.tumbnail,
        'dateCreated': p.dateCreated,
        'videoId': false,
        'meta': {
          'author': 'Paragon',
          'category': p.tag,
          'postDate': p.dateCreated,
        }
      })
    });
    temp = matchSorter(temp, "", { keys: ["dateCreated"] });
    temp.reverse()
    setListNews(temp)
  }, [dataAllNews])

  return (
    <div className="bk-blog-area bg_color--5 section-ptb-50 clearfix">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="section-title--between wow move-up">
              <h3 className="heading heading-h3 mb-3" style={{ 'color': '#215AA8' }}>{title}</h3>
              <div className="section-btn mt_mobile--30">
                <Link className="button-text" style={{ color: '#215AA8' }}
                  to={`${process.env.PUBLIC_URL + '/all-news'}`}><span>See All</span>
                  <i className="fa fa-arrow-right" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="row mt--0">
          {listNews?.length ? listNews?.slice(0, 4).map(blog => (
            <Fragment key={blog.id}>
              <Col xs={12} sm={6} xl={3} className="wow move-up mt--30">
                <BlogItem data={blog} />
              </Col>
            </Fragment>
          )) :
            <Row>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
              <Col xs md="3">
                <Skeleton height={150} />
              </Col>
            </Row>
          }
        </Row>
      </Container>
    </div>
  );
};

export default BlogTwo;
