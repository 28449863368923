import React, {useState} from 'react';
import SliderSwiper from "../../../../components/swiper";

const SliderParaco = () => {
    const [swiper, updateSwiper] = useState(null);

    const settings = {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        loop : true,
        autoplay : true,
        navigation: {
            nextEl: '.swiper-btn-prev',
            prevEl: '.swiper-btn-next',
        },
        breakpoints: {
            600: {
                slidesPerView: 2
            }
        }
    };

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };
    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <div
            className="flexible-image bg_color--1 swipper-custom-arrow ptb--100 ptb-md--50 ptb-sm--50">
            <SliderSwiper
                reference={updateSwiper}
                settings={settings}
                containerClass="flexibleimage__wrapper"
            >
                {/* {sliderImages.map((sliderImage, key) => (
                    <ImageSlider key={key} image={sliderImage} classes="swiper-slide" />
                ))} */}

                <div className='flexible-image swiper-slide'>
                    <div className="thumb">
                        <img src={require('../../../assets/CulturePage/image/slide-culture-dila.png')} alt="dila"/>
                    </div>
                </div>
                <div className='flexible-image swiper-slide'>
                    <div className="thumb">
                        <img src={require('../../../assets/CulturePage/image/slide-culture-genta.png')} alt="genta"/>
                    </div>
                </div>
                <div className='flexible-image swiper-slide'>
                    <div className="thumb">
                        <img src={require('../../../assets/CulturePage/image/slide-culture-karin.png')} alt="karin"/>
                    </div>
                </div>
                <div className='flexible-image swiper-slide'>
                    <div className="thumb">
                        <img src={require('../../../assets/CulturePage/image/slide-culture-novo.png')} alt="novo"/>
                    </div>
                </div>
            </SliderSwiper>
            <button onClick={goPrev} className="swipper-btn swiper-btn-prev">
                <i className="fa fa-angle-left"/>
            </button>
            <button onClick={goNext} className="swipper-btn swiper-btn-next">
                <i className="fa fa-angle-right"/>
            </button>
        </div>
    );
};

export default SliderParaco;