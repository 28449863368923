import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../components/banner/BannerPageOne";
import Blogs from "../../../pages/info/News/NewsContainer";
import LayoutDefaultParaportal from "../../../layouts/LayoutDefaultParaportal";

import img from "../../../../assets/img/paraportal/mascot-02.png";

const AllNews = () => {
  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - News</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <Breadcrumb title="NEWS" color="#a5a7eb" img={img} />
        <Blogs sidebar={false} />
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default AllNews;
