import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import { BsX } from "react-icons/bs";

const FilterComponent = ({ filterText, onFilter, onClear, placeholder }) => (
  <>
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={filterText}
            onChange={onFilter}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
          <Button
            onClick={onClear}
            style={{ 'backgroundColor': '#215AA8', borderBottomLeftRadius: '0', borderTopLeftRadius: '0' }}
          >
            <BsX />
          </Button>
        </InputGroup>
      </Form.Group>
    </Form>
  </>
);

export default FilterComponent;