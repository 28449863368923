import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function BannerITSupportService({ title, subtitle, color, style}) {
    return (
        <>
            <div
                style={{ ...style, backgroundColor: color }}
                className="breadcrumb-area ptb--50 ptb-md--150 ptb-sm--150 breadcrumb-title-bar breadcrumb-title-white">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="breadcrumb-inner text-left mt_sm--50 mt_md--50">
                                <h3
                                    className="heading heading-h3 text-white"
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                                <p className="text-white">{subtitle}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
