import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import SlickSlider from "../../../../components/slick";
import qore from "../../../../qoreContext";

const KaryaParagonianSliderContent = () => {

    const { data: dataKaryaParagonianShow } = qore.view("karyaParagonianShow").useListRow({});
    
    const NextArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-right"/>
            </button>
        );
    };

    const PrevArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-left"/>
            </button>
        );
    };

    const settings = {
        slidesToShow: 4,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow />,
        loop : false,
        dots: true,
        responsive: [
            {
                "breakpoint": 801,
                "settings": {
                    slidesToShow: 2,
                    loop:false
                }
            },
            {
                "breakpoint": 577,
                "settings": {
                    slidesToShow: 2,
                    dots:true,
                    arrows: true,
                    loop:false
                }
            }
        ]
    };

    return (
        <div
            className="bk-carousel-slider bg_color--1 slick-arrow-hover pb_sm--60">
            <Container>
                <Row>
                    <Col xs={12}>
                        <SlickSlider
                            settings={settings}
                            classes="brook-element-carousel slick-arrow-center slick-dots-bottom slick-gutter-15"
                        >
                            {dataKaryaParagonianShow.map(item => (
                                <a href={item.urlLink} key={item.id}>
                                    <div className="carousel-slider text-center">
                                        <div className="thumb">
                                            <img src={item.gambar} alt={item.name}/>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </SlickSlider>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default KaryaParagonianSliderContent;