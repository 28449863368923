import React from "react";

const DepartementItem = ({ name, description, displayImage }) => {
  return (
    <div
      className="team team__style--3"
      style={{
        height: "40rem",
        backgroundColor: "#f0f0f0",
        padding: "3rem",
        display: "flex",
        alignItems: "center",
      }}>
      <div className="team-info text-left">
        <div className="info">
          <h5
            className="heading"
            style={{
              marginBottom: "1.25rem",
              fontSize: "24px",
              fontWeight: "600",
            }}>
            {name}
          </h5>
          <p
            className="text"
            style={{ lineHeight: "1.5", fontSize: "14px", fontWeight: "500" }}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DepartementItem;
