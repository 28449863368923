import React from "react";
import { Col, Row } from "react-bootstrap";
import ResponsiveImage from "../../../components/responsiveImage";
import IconMail from "../../../../assets/paraportal/icon-mail.svg";

const LeaderProfile = ({ name, role, displayImage, email }) => {
  return (
    <div style={{ marginBottom: "3rem" }}>
      <Row>
        <Col
          lg={3}
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <ResponsiveImage
            thumb={displayImage}
            style={{ padding: "0.5rem 1rem 0.5rem 0.5rem" }}
          />
        </Col>
        <Col
          lg={3}
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <div className="content">
            <h5
              className="heading heading-h5"
              style={{ fontWeight: "700", marginBottom: "0.5rem" }}>
              {name}
            </h5>
            <p className="text">{role}</p>
          </div>
        </Col>
        <Col
          lg={6}
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          <div className="content" style={{ flex: "0 0 100%" }}>
            <Row>
              <Col lg={2} style={{ paddingRight: "0", textAlign: "right" }}>
                <img src={IconMail} alt="icon-mail" />
              </Col>
              <Col lg={10}>
                <div className="text pr_sm--5">
                  <p className="bk_pra">{email}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LeaderProfile;
