import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Brief = ({ displayImage, description }) => {
  return (
    <div
      style={{
        margin: "4rem 0 8rem",
      }}>
      <Container
        style={{ minHeight: "22rem", borderBottom: "1px solid #dddddd" }}>
        <Row style={{ paddingBottom: "4rem" }}>
          <Col
            lg={6}
            style={{
              padding: "0",
              display: "flex",
              alignItems: "center",
            }}>
            <img src={displayImage} alt="directorate pic" />
          </Col>
          <Col
            lg={6}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "2.5rem",
            }}>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "500",
                padding: "0",
                color: "#215aa8",
              }}
              dangerouslySetInnerHTML={{ __html: description }}></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Brief;
