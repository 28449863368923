import React from "react";
import { useParams } from "react-router-dom";
import qore from "../../../qoreContext";

import { Helmet } from "react-helmet";

import TopBanner from "../../components/banner/TopBanner";

import MeetOurLeader from "./MeetOurLeader";
import Division from "./Division";
import Departement from "./Departement";
import News from "./News";
import Gallery from "./Gallery";
import OurWork from "./OurWork";
import Brief from "./Brief";
import VisitOurMedium from "./VisitOurMedium";
import ContactUs from "./ContactUs";

import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";

import img from "../../../assets/img/paraportal/mascot-02.png";

const Directorate = () => {
  const STATUS_SUCCESS = "success";
  const { directorateName: routeParam } = useParams();

  const { data: directorates, status: allDirectorateStatus } = qore
    .view("allDirectorate")
    .useListRow({ routeParam });
  const { data: topBanners, status: directorateTopBannerStatus } = qore
    .view("directorateTopBanner")
    .useListRow({ routeParam });
  const { data: briefs, status: allPCorpBriefStatus } = qore
    .view("allPCorpBrief")
    .useListRow({ routeParam });
  const { data: leaderProfiles, status: allPCorpLeaderProfileStatus } = qore
    .view("allPCorpLeaderProfile")
    .useListRow({ routeParam });
  const { data: divisions, status: allPCorpDivisionStatus } = qore
    .view("allPCorpDivision")
    .useListRow({ routeParam });
  const { data: departements, status: allPCorpDepartementStatus } = qore
    .view("allPCorpDepartement")
    .useListRow({ routeParam });
  const { data: ourWorks, status: allPCorpOurWorkStatus } = qore
    .view("allPCorpOurWork")
    .useListRow({ routeParam });
  const { data: newsList, status: newsPublishStatus } = qore
    .view("newsPublish")
    .useListRow({});
  const { data: galleries, status: allPCorpGalleryStatus } = qore
    .view("allPCorpGallery")
    .useListRow({ routeParam });

  const [topBanner] = topBanners;
  const [brief] = briefs;
  const [directorate] = directorates;

  const isLoading = !(
    allDirectorateStatus === STATUS_SUCCESS &&
    directorateTopBannerStatus === STATUS_SUCCESS &&
    allPCorpBriefStatus === STATUS_SUCCESS &&
    allPCorpLeaderProfileStatus === STATUS_SUCCESS &&
    allPCorpDivisionStatus === STATUS_SUCCESS &&
    allPCorpDepartementStatus === STATUS_SUCCESS &&
    allPCorpOurWorkStatus === STATUS_SUCCESS &&
    newsPublishStatus === STATUS_SUCCESS &&
    allPCorpGalleryStatus === STATUS_SUCCESS
  );

  return (
    <>
      <Helmet>
        <title>PARAPORTAL - Corp</title>
      </Helmet>

      {!isLoading && (
        <LayoutDefaultParaportal className="template-color-1 template-font-1">
          {!!topBanner && <TopBanner {...topBanner} img={img} />}

          {!!brief && <Brief {...brief} />}

          {!!leaderProfiles.length && (
            <MeetOurLeader leaderProfiles={leaderProfiles} />
          )}

          {!!divisions.length && <Division divisions={divisions} />}

          {!!departements.length && <Departement departements={departements} />}

          {!!ourWorks.length && <OurWork ourWorks={ourWorks} />}

          {!!topBanner && <VisitOurMedium />}

          {!!newsList.length && <News newsList={newsList} />}

          {!!galleries.length && (
            <Gallery galleries={galleries} gutter={true} />
          )}

          {!!directorate && <ContactUs {...directorate} />}
        </LayoutDefaultParaportal>
      )}
    </>
  );
};

export default Directorate;
