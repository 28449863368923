import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import LayoutDefaultParaportal from "../../../../layouts/LayoutDefaultParaportal";
import BreadcrumbsMDMDown from "../BreadcrumbsMDMDown";
import Customer from "./Customer/Customer";
import Discount from "./Discount/Discount";
import POSM from "./POSM/POSM";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabDownstream');
let subTabDownstream = paramsVal.get('subTabDownstream');

const Downstream = () => {
  const [category, setCategory] = useState(tab ? tab : 'Customer');
  const history = useHistory();
  const params = new URLSearchParams({ tabDownstream: category, subTabDownstream : subTabDownstream ? subTabDownstream : 'Link'});

  const handleChangeCategory = (item) => {
    setCategory(item)
  }

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [category])

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Master Data Management | Downstream</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <BreadcrumbsMDMDown
          title="Downstream"
          //subtitle="List tableau, redash, superset corporate" 
          color="#6A8AFC"
          handleChangeCategory={handleChangeCategory}
          category={category}
        />
        {category === 'Customer' && <Customer tabMDM={category} />}
        {category === 'Discount' && <Discount tabMDM={category} />}
        {category === 'POSM' && <POSM tabMDM={category} />}
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default Downstream;