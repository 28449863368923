import { Card } from "react-bootstrap";
import React from "react";
import qore from "../../../../qoreContext";
import { Link } from "react-router-dom";
import "./styleHRSC.css";
import { onHandleGtmViewHrsc } from "../../../../helper/gtm";

const HRSCListContent = ({ id }) => {
  const { data: dataHRSC_list } = qore.view("allHrServiceCenter").useListRow({
    parent_id: id,
    overrideLimit: true,
    "$by.createdOn": "desc",
  });

  const trackHrscGtm = (item) => {
    const temp = { ...item };
    temp.title = `${item.title} | ${item.areaKerja.displayField}`
    onHandleGtmViewHrsc(item);
  };

  return (
    <div>
      {dataHRSC_list?.map((item) => (
        <Card className="border-0">
          <Card.Body style={{ padding: "0.75rem 1.25rem" }}>
            <ul className="ul__list">
              <Link
                to={`${`${process.env.PUBLIC_URL}/hrsc-article/${item?.areaKerja.displayField}/${item?.title}`}`}
                style={{ color: "black" }}
                onClick={() => trackHrscGtm(item)}
              >
                <li className="listcontent">{item?.title}</li>
              </Link>
            </ul>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default HRSCListContent;
