import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const BannerPageOne = ({ title, subtitle, color, img, style }) => {
  return (
    <div
      style={{ ...style, backgroundColor: color }}
      className="breadcrumb-area ptb--100 ptb-md--150 ptb-sm--150 breadcrumb-title-bar breadcrumb-title-white">
      <Container>
        <Row>
          <Col lg={4}>
            <img src={img} alt="logo" />
          </Col>
          <Col lg={8}>
            <div className="breadcrumb-inner text-center mt_sm--50 mt_md--50">
              <h1
                className="heading heading-h1 text-white"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p className="text-white">{subtitle}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BannerPageOne;
