import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import Logo from "../../components/header/elements/logo";
import Widget from "../../../components/widget";
import Copyright from "../../../components/footer/Copyright";
import logo from '../../assets/img/logo/logo paragon.png';
import { onHandleGtmNavigateNavbar } from '../../../helper/gtm';

const FooterTwoParaportal = () => {

	const socials = [
		{
			"id": 1,
			"media": "instagram",
			"username": "paragon.id"
		},
		{
			"id": 2,
			"media": "linkedin",
			"username": "company/pt-paragon-technology-&-innovation"
		},
		{
			"id": 3,
			"media": "youtube",
			"username": "channel/UCLYzp0VJnnTIptl4Ivz1l0Q"
		},
	]

	const trackFooterGtm = (title) => {
		onHandleGtmNavigateNavbar({
			title: title
		})
	}

	return (
		<footer
			className="page-footer bg_color--2 pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30"
			style={{ backgroundColor: '#215aa8' }}
		>
			<div className="bk-footer-inner pt--80 pb--60 pt_md--90 pt_sm--90 pb_md--30 pb_sm--20">
				<Row>
					<Col xs={12} sm={6} lg={4} xl={7}>
						<Widget
							wrapClasses="text-var--2"
							title={null}
						>
							<Logo logo={logo} />
						</Widget>
					</Col>

					<Col xs={12} sm={6} lg={4} xl={3} className="mt_mobile--40">
						<Widget
							wrapClasses="text-var--2 menu--about"
						>
							<div className="footer-menu">
								<Row>
									<Col xl={3} sm={6} xs={4}>
										<ul className="ft-menu-list bk-hover">
											<li><Link to={process.env.PUBLIC_URL + "/news-page"} onClick={() => trackFooterGtm("News")}>News</Link></li>
											<li><Link to={process.env.PUBLIC_URL + "/event-page"} onClick={() => trackFooterGtm("Event")}>Event</Link></li>
											<li><Link to={process.env.PUBLIC_URL + "/culture-paraportal"}>Culture</Link></li>
										</ul>
									</Col>
									<Col xl={9} sm={6} xs={8}>
										<ul className="ft-menu-list bk-hover">
											<li><Link to={process.env.PUBLIC_URL + "/apps-page"}>Apps</Link></li>
											<li><Link to={process.env.PUBLIC_URL + "/hrsc"} onClick={() => trackFooterGtm("HR Service Center")}>HR Service Center</Link></li>
											<li><Link to={process.env.PUBLIC_URL + "/faq-paraportal"}>FAQs</Link></li>
										</ul>
									</Col>
								</Row>
							</div>
						</Widget>
					</Col>

					<Col xs={12} sm={6} lg={4} xl={2} className="mt_md--40 mt_sm--40">
						<Widget
							wrapClasses="menu--about"
							title="Contact"
						>
							<ul className="social-icon icon-size-medium text-dark text-left tooltip-layout move-up wow">
								{socials.map(icon => (
									<li key={icon.id} className={icon.media}>
										<a
											className="link hint--bounce hint--top hint--theme text-white"
											href={`https://${icon.media}.com/${icon.username}`}
											aria-label={(icon.media)}
											target="_blank"
											rel="noreferrer noopener"
										>
											<i className={`fa fa-${icon.media}`} />
										</a>
									</li>
								))}
							</ul>
						</Widget>
					</Col>
				</Row>
			</div>

			<div className="copyright ptb--10">
				<div className="copyright-right text-md-center text-center">
					<Copyright />
				</div>
			</div>
		</footer>
	);
};

export default FooterTwoParaportal;