import React, { useState, useEffect } from "react";
import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";
import BackgroundDesktop from "../../../assets/paraportal/paraversa-member-card-bg-desktop.svg";
import BackgroundMobile from "../../../assets/paraportal/paraversa-member-card-bg-mobile.svg";
import ResponsiveImage from "../../components/responsiveImage";
import Cookies from "universal-cookie";
import bwipjs from "bwip-js";
import "./ParaversaMemberCard.scss";

export default function ParaversaMemberCard() {
  const [name, setName] = useState("");
  const [serialNumber, setSerialNumber] = useState('9309256749');
  const cookies = new Cookies();

  useEffect(() => {
    const user = cookies.get("userloggedin");
    const code = cookies.get("user_serial_number");

    setName(user);
    setSerialNumber(code);
  }, [cookies, name, serialNumber]);

  useEffect(() => {
    if (!serialNumber) return;

    bwipjs.toCanvas("barcode-canvas-desktop", {
      bcid: "code128", // Barcode type
      text: serialNumber,
      scaleX: 2,
      scaleY: 3,
      height: 10, // Bar height, in millimeters
      includetext: false, // Show human-readable text
      textxalign: "center", // Always good to set this
    });

    bwipjs.toCanvas("barcode-canvas-mobile", {
      bcid: "code128", // Barcode type
      text: serialNumber,
      scaleX: 2,
      scaleY: 3,
      height: 10, // Bar height, in millimeters
      includetext: false, // Show human-readable text
      textxalign: "center", // Always good to set this
    });
  }, [serialNumber]);

  return (
    <LayoutDefaultParaportal>
      {/* DESKTOP */}
      <div id="paraportal-member-card-desktop">
        <div
          style={{
            padding: "8rem 0 8rem",
            display: "flex",
            justifyContent: "center",
          }}>
          <ResponsiveImage thumb={BackgroundDesktop}>
            {/* TITLE */}
            <div
              style={{
                position: "absolute",
                top: "5.5rem",
                left: "5.5rem",
              }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: "36px",
                  fontWeight: "700",
                  lineHeight: "54px",

                  marginBottom: "0.25rem",
                }}>
                Paraversa Member Club
              </p>
            </div>
            {/* NAME & SERIAL NUMBER */}
            <div
              style={{
                position: "absolute",
                top: "12rem",
                left: "5.5rem",
                width: "14rem",
                height: "7rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "36px",

                  marginBottom: "0.25rem",
                }}>
                Your Name
              </p>
              <p
                style={{
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "27px",
                  letterSpacing: "0.1rem",
                }}>
                9309256749
              </p>
            </div>
            {/* BARCODE BOX */}
            <div
              style={{
                position: "absolute",
                top: "12rem",
                left: "22rem",
                width: "13rem",
                height: "7rem",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <canvas id="barcode-canvas-desktop">123456</canvas>
            </div>
          </ResponsiveImage>
        </div>
      </div>
      {/* MOBILE */}
      <div id="paraportal-member-card-mobile">
        <div
          style={{
            padding: "8rem 0 8rem",
            display: "flex",
            justifyContent: "center",
          }}>
          <ResponsiveImage thumb={BackgroundMobile}>
            {/* TITLE */}
            <div
              style={{
                position: "absolute",
                top: "5rem",
                left: "3.5rem",
              }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: "36px",
                  fontWeight: "700",
                  lineHeight: "54px",

                  marginBottom: "0.25rem",
                }}>
                Paraversa Member Club
              </p>
            </div>
            {/* NAME & SERIAL NUMBER */}
            <div
              style={{
                position: "absolute",
                top: "15rem",
                left: "3.5rem",
                width: "14rem",
                height: "7rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "36px",

                  marginBottom: "0.25rem",
                }}>
                Your Name
              </p>
              <p
                style={{
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "27px",
                  letterSpacing: "0.1rem",
                }}>
                9309256749
              </p>
            </div>
            {/* BARCODE BOX */}
            <div
              style={{
                position: "absolute",
                top: "23rem",
                left: "3.5rem",
                width: "13rem",
                height: "7rem",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <canvas id="barcode-canvas-mobile"></canvas>
            </div>
          </ResponsiveImage>
        </div>
      </div>
    </LayoutDefaultParaportal>
  );
}