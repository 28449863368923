import React, { useState } from "react";
import { Col, Container, Row, Button, Spinner, Card, Form, Alert } from "react-bootstrap";
import Parallax, { Layer } from "react-parallax-scroll";
import axios from "axios";
import Cookies from "universal-cookie";
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import Logo from "../../../components/header/elements/logo";
import logo from '../../../assets/img/paraportal/paraportal-logo.png'
import img from '../../../assets/img/paraportal/mascot-02.png'
import { onHandleGtmLogin } from "../../../helper/gtm";

const ContactTwo = () => {
  const cookies = new Cookies();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnTextLogin, setBtnTextLogin] = useState("Login");
  const [btnDisableLogin, setBtnDisableLogin] = useState(false);
  const [spinnerShow, setspinnerShow] = useState("none");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [messageError, setMessageError] = useState("");
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const location = useLocation()

  const inputHandler = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      setMessageError("");
    }
    if (e.target.name === "password") {
      setPassword(e.target.value)
      setMessageError("");
    }
  };

  const handleLogin = (event) => {
    setBtnDisableLogin(true)
    setBtnTextLogin("Login...")
    setspinnerShow(false)

    axios.post('https://auth-service.pti-cosmetics.com/login', {
      email,
      password
    })
      .then(function (response) {
        const dataUserLogin = response.data
        cookies.set("token", dataUserLogin.data.token, { path: "/" });
        cookies.set("userEmail", dataUserLogin.data.mail, { path: "/" });
        cookies.set('pdpdkzqh', 'ljhmonqc', { path: "/" })
        localStorage.setItem("userEmail", email);
        localStorage.setItem("userId", dataUserLogin?.data?.uidNumber);
        onHandleGtmLogin({
          email: email,
          userId: dataUserLogin?.data?.uidNumber
        })
        setBtnDisableLogin(false)
        setspinnerShow("none")
        setBtnTextLogin("Login")
        console.log(response.data);
      })
      .catch(function (error) {
        setMessageError(error.response.data.errors[0].message);
        setBtnDisableLogin(false)
        setspinnerShow("none")
        setBtnTextLogin("Login")
        setVisibleAlert(true)
        setTimeout(() => {
          setVisibleAlert(false)
        }, 3000)
        console.log(error.response.data.errors[0].message);
      });
  };

  if (cookies.get("pdpdkzqh") === "ljhmonqc") {
    return (
      window.location.replace(location.pathname)
    )
  } else {
    return (
      <>
        <Parallax>
          {
            isPortrait ?
              <Container className="wow move-up" fluid>
                <Card className="p-3" style={{ marginTop: "80px" }}>
                  <div className="brook-title mb--30 text-center">
                    <Logo logo={logo} />
                    <div className="bk-separator--20" />
                    <p className="bk_pra">Login with your email corporate</p>
                  </div>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail" required>
                      <Form.Label>Email*</Form.Label>
                      <input
                        style={{ borderRadius: "5px", borderColor: "gray", opacity: "0.5" }}
                        required
                        autoFocus
                        type="email"
                        name="email"
                        placeholder="example@mail.com"
                        value={email}
                        onChange={(e) => inputHandler(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password*</Form.Label>
                      <Form.Control
                        style={{ borderRadius: "5px", borderColor: "gray", opacity: "0.5" }}
                        type="password"
                        name="password"
                        placeholder="your password"
                        required={true}
                        value={password}
                        onChange={(e) => inputHandler(e)}
                        onKeyPress={(event) => {
                          if (event?.key === "Enter") {
                            handleLogin();
                          }
                        }}
                      />
                    </Form.Group>

                    <div className="d-grid">
                      <Button
                        onClick={handleLogin}
                        variant="primary"
                        disabled={btnDisableLogin}
                        size={"md"}
                        className="brook-btn bk-btn-theme"
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ display: spinnerShow }}
                        />
                        {btnTextLogin}
                      </Button>
                    </div>
                  </Form>
                  <Alert variant="danger" className="mt--20 text-center" show={visibleAlert}>
                    {messageError}
                  </Alert>
                </Card>
                <div className="text-center">
                  <img src={img} width={250} className="mt-5 text-center" alt="paranovo" style={{ opacity: "0.8" }} />
                </div>
              </Container>
              :
              <Layer
                className="bg_image--login"
                settings={{ speed: -0.2, type: "backgroundY" }}>
                <div className="brook-contact-form-area ptb--150 ptb-md--80 ptb-sm--150">
                  <Container>
                    <Row>
                      <Col lg={6}></Col>
                      <Col lg={6} className="wow move-up">
                        <Card className="p-5">
                          <div className="brook-title mb--30 text-center">
                            <Logo logo={logo} />
                            <div className="bk-separator--20" />
                            <p className="bk_pra">Login with your email corporate</p>
                          </div>
                          <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail" required>
                              <Form.Label>Email*</Form.Label>
                              <input
                                style={{ borderRadius: "5px", borderColor: "gray", opacity: "0.5" }}
                                required
                                autoFocus
                                type="email"
                                name="email"
                                placeholder="example@mail.com"
                                value={email}
                                onChange={(e) => inputHandler(e)}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Password*</Form.Label>
                              <Form.Control
                                style={{ borderRadius: "5px", borderColor: "gray", opacity: "0.5" }}
                                type="password"
                                name="password"
                                placeholder="your password"
                                required
                                value={password}
                                onChange={(e) => inputHandler(e)}
                                onKeyPress={(event) => {
                                  if (event?.key === "Enter") {
                                    handleLogin();
                                  }
                                }}
                              />
                            </Form.Group>
                            <div className="d-grid">
                              <Button
                                onClick={handleLogin}
                                variant="primary"
                                disabled={btnDisableLogin}
                                size={"md"}
                                className="brook-btn bk-btn-theme"
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ display: spinnerShow }}
                                />
                                {btnTextLogin}
                              </Button>
                            </div>
                          </Form>
                          <Alert variant="danger" className="mt--20 text-center" show={visibleAlert}>
                            {messageError}
                          </Alert>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Layer>
          }
        </Parallax>
      </>
    );
  }
};

export default ContactTwo;
