import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const VisitOurMedium = () => {
  const backgroundColor = "#6a89fc";

  return (
    <div style={{ backgroundColor }}>
      <Container>
        <Row style={{ minHeight: "18rem" }}>
          <Col
            lg={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}>
            <p
              className="text-white"
              style={{
                fontSize: "48px",
                fontWeight: "700",
                lineHeight: "72px",
                textAlign: "start",
                width: "80%",
              }}>
              Visit Our Medium
            </p>
            <p
              className="text-white"
              style={{
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "36px",
                textAlign: "start",
                width: "80%",
              }}>
              Baca karya Paragonian yang lainnya disini
            </p>
          </Col>
          <Col
            lg={6}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}>
            <a href="https://paraportal.pti-cosmetics.com/paraportal/information-technology/#">
              <button
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#215AA8",
                  padding: "1rem 2rem",
                  borderRadius: "5px",
                  fontSize: "18px",
                  fontWeight: "700",
                }}>
                READ HERE
              </button>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VisitOurMedium;
