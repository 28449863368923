import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const LeftImageCultureSection = ({title, content, image}) => {
    return (
        <div style={{ 'backgroundColor' : '#f7f7f7' }}>
        <Container className='ptb--80 ptb-md--80 ptb-sm--60 bg_color--1' style={{ 'backgroundColor' : '#f7f7f7' }}>
            <Row>
                <Col md={6} className="order-1 order-lg-0">
                    <div className="brook-section-title mb--50 mb_sm--20 mb_md--20 wow move-up">
                        <h3 className="heading heading-h3" style={{ 'color' : '#215AA8' }}>{title}</h3>
                    </div>
                    {content}
                </Col>
                <Col md={6} className="order-0 order-lg-1 ">
                    <div className="thumb text-left wow move-up mb_sm--30">
                        <img src={require(`../../../assets/CulturePage/image/${image}`)} alt="thumb" />
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
    );
};

export default LeftImageCultureSection;
