import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Cookies from "universal-cookie";
import qore from "../../../../qoreContext";

export default function LoveComp({ valuedashboard }) {
  const [cursor, setCursor] = useState("default");
  const [click, setClick] = useState();
  const [idFavorites, setIdfavorites] = useState();
  const [smShow, setSmShow] = useState(false);
  const cookies = new Cookies();

  const { insertRow: inserFavorites } = qore.view("allFavoritesby").useInsertRow();
  const { deleteRow: deleteFavorites } = qore.view("allFavoritesby").useDeleteRow();
  const { data: allFavoritesbyUser } = qore.view("allFavoritesby").useListRow({
    email: cookies.get('user_email')
  });

  const handleMouseEnter = () => {
    setCursor("pointer");
  };

  const dataIDDashboardFavorites = allFavoritesbyUser?.map((data) => (
    data.dashboardId
  ))

  const cekListFavorites = dataIDDashboardFavorites.includes(valuedashboard.id)

  const handleClick = (a) => {
    setClick(!click);
    setSmShow(true)

    if (!click) {
      inserFavorites({
        "email": cookies.get('user_email'),
        "dashboardName": valuedashboard.title,
        "dashboardId": valuedashboard.id,
        "link": valuedashboard.link,
        "table": valuedashboard.table,
      }).then((result) => {
        setIdfavorites(result.id)
      })
    }
    else if (click && cekListFavorites) {
      // const emailUser = cookies.get('user_email')
    }
    else {
      deleteFavorites(idFavorites)
    }
  };

  useEffect(() => {
    cekListFavorites ? setClick(true) : setClick(false)
  }, [cekListFavorites]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSmShow(false)
    }, 1000);
    return () => clearTimeout(timeout);
  }, [smShow]);

  return (
    <>
      <i
        id={valuedashboard.id}
        className={click ? "fa fa-heart" : "fa fa-heart-o"}
        //className={classNameHeart}
        onMouseEnter={handleMouseEnter}
        onClick={e => handleClick(e.currentTarget.id)}
        style={{ cursor: cursor, color: "red" }}
      />

      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body id="example-modal-sizes-title-sm">
          <small>{click ? `${valuedashboard.title} added to favorites` : `${valuedashboard.title} removed from favorites`}</small>
        </Modal.Body>
      </Modal>
    </>
  );
}
