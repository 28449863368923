import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'
import CompReseorce from '../../../components/CompReseorce'
import FAQ from './resContent/FAQ'

const accordionContent = [
    {
        "id": 1,
        "title": "Training",
        "content": <CompReseorce title="Video Training Odoo MDM [Product]" link="https://cloud.pti-cosmetics.com/index.php/s/TNX79cRN6zqBQmA?path=%2F"/>
    },
    {
        "id": 2,
        "title": "Post Test",
        "content": <CompReseorce title="Post Test Product" link="https://drive.google.com/drive/folders/1j6If_BLo80eJziupaOp6EB89z0UNUeHc?usp=sharing"/>
    },
    {
        "id": 3,
        "title": "Flow",
        "content":  <CompReseorce title="Coming Soon"/>
    },
    {
        "id": 4,
        "title": "User Guide",
        "content": <CompReseorce title="Rev.1 MDM User Guide - Product Master Data" link="https://docs.google.com/document/d/1iMKrxak7N312C-7n3HVe2HKumKVWdaatDcSo1TAbtd8/edit?usp=sharing"/>
    },
    {
        "id": 5,
        "title": "FAQ",
        "content": <FAQ />
    },
]

export default function Resource() {
    return (
        <>
            <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">

                <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                    {accordionContent.map(accordion => (
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                    {accordion.title}
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey={accordion.id}>
                                <Card.Body>{accordion.content}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Container>
        </>
    )
}
