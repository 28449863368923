import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

function ModalDetailApps(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <div style={{ padding: "15px" }}>
        <Modal.Header style={{ display: "block" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            <Row>
              <Col xs={8} sm={8} md={9} lg={10}>
                {props.data.serviceName}
                <br />
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "100",
                    margin: "10px 0px 0px 0px",
                  }}>
                  {props.data.description}
                </p>
              </Col>
              <Col xs={4} sm={4} md={3} lg={2}>
                {props.data.url &&
                  !props.data.isMobileApps &&
                  props?.data.modalType !== "Reporting" && (
                    <a href={"https://" + props.data.url} target="_blank" without rel="noopener noreferrer">
                      <Button style={{ width: "100%" }}>Visit</Button>
                    </a>
                  )}
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: "5px 0px"}}>
            <Col xs={12} sm={3}>FEATURES</Col>

            {props.data?.modalType === "Reporting" ? (
              <Col xs={12} sm={9}>
                <Row>
                  {props.data?.feature?.map((feat) => {
                    return (
                      <Col xs={6}>
                        <a
                          href={feat?.link}
                          style={{
                            color: "#000000",
                            textDecorationLine: "underline",
                          }}>
                          {feat?.text}
                        </a>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            ) : (
              <Col xs={12} sm={9}>
                {props.data?.feature?.map((feat) => {
                  return (
                    <>
                      &bull; {feat} <br />
                    </>
                  );
                })}
              </Col>
            )}
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <Col xs={12} sm={3}>BUSINESS OWNER</Col>
            <Col xs={12} sm={9}>{props.data.businessOwner}</Col>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <Col xs={12} sm={3}>SME</Col>
            <Col xs={12} sm={9}>{props.data.subjectMasterExpert}</Col>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <Col xs={12} sm={3}>SUPPORT HOURS</Col>
            <Col xs={12} sm={9}>{props.data.supportedHours}</Col>
          </Row>
          <Row style={{ padding: "5px 0px" }}>
            <Col xs={12} sm={3}>REQUEST PROCEDURE</Col>
            <Col xs={12} sm={9}>
              For Business as usual please contact our Service Desk at : <br />
              mail : servicedesk@pti-cosmetics.com <br />
              WA : +62 819-5292-4722 <br />
              or you can submit glpi ticket at{" "}
              <a href="https://glpi.pti-cosmetics.com/glpi/" target="_blank" without rel="noopener noreferrer">
                <span style={{color:'blue'}}>
                  <u>
                    glpi.pti-cosmetics.com
                  </u>
                </span>
              </a>
              &nbsp; <br/>
              (guidelines :{" "}
              <a href="https://bit.ly/selfserviceGLPI" target="_blank" without rel="noopener noreferrer">
                <span style={{color:'blue'}}>
                  <u>
                    bit.ly/selfserviceGLPI
                  </u>
                </span>
              </a>)
            </Col>
          </Row>
          {props.data.isMobileApps && (
            <Row style={{ padding: "5px 0px" }}>
              <Col xs={12} sm={3}>DOWNLOAD</Col>
              <Col xs={12} sm={9}>
                {props.data.appStoreLink && (
                  <a
                    href={"https://" + props.data.appStoreLink}
                    target="_blank" without rel="noopener noreferrer">
                    <Button style={{ width: "auto", marginRight: "10px" }}>
                      <i className="fa fa-apple" style={{ margin: "5px" }} />
                      AppStore
                    </Button>
                  </a>
                )}
                {props.data.playStoreLink && (
                  <a
                    href={"https://" + props.data.playStoreLink}
                    target="_blank" without rel="noopener noreferrer">
                    <Button style={{ width: "auto" }}>
                      <i class="fa fa-play" style={{ margin: "5px" }}></i>
                      PlayStore
                    </Button>
                  </a>
                )}
              </Col>
            </Row>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default ModalDetailApps;
