import React from "react";
import { Card } from "react-bootstrap";
import ModalDetailApps from "./ModalDetailApps";

const PortfolioItemOne = ({ className, thumb, title, data }) => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Card
        style={{
          padding: "5px",
          borderRadius: "5px",
          borderWidth: "0px",
          boxShadow: "5px 5px 20px lightgrey",
          height: "120px",
        }}
        onClick={() => setModalShow(true)}>
        <div
          className={`portfolio with-caption`}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "200px",
          }}>
          <div
            className="thumb video-with-thumb"
            style={{ position: "relative", height: "80%" }}>
            <img
              className="vertical-center-image"
              src={data?.logo}
              alt={data?.serviceName}
            />
          </div>
          <div className="text-center">
            <div className="info">
              <p
                className="heading"
                style={{ fontSize: "10px", marginTop: "5px" }}>
                {data?.serviceTitle}
              </p>
              <p className="bk_pra" style={{ fontSize: "8px" }}>{data?.serviceLocation}</p>
            </div>
          </div>
        </div>
      </Card>
      <ModalDetailApps
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </>
  );
};

export default PortfolioItemOne;
