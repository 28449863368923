import React from "react";
import { Col, Container, Row, Card} from "react-bootstrap";

const style ={
    "color" : "blue",
    "textDecoration": "underline"
}

const ContactUsMDM = () => {
    return (
        <Container
            className='ptb--50 ptb-md--80 ptb-sm--60 bg_color--1'
        >
            <Row>
                <Col>
                    <Card style={{ 'borderColor': '#6c8afa', 'borderRadius': '15px' }}>
                        <Card.Header style={{ 'fontWeight': '600', 'backgroundColor': '#6c8afa', 'color': 'white', 'borderTopLeftRadius': '15px', 'borderTopRightRadius': '15px' }}>
                            Upstream
                        </Card.Header>
                        <Card.Body>
                            <p style={{ 'fontWeight': '500' }}>Project (product, BOS, vendor, BOM, dll)</p>
                            <ul>
                                <li>Contact : <a href="https://wa.me/628174802801" target="_blank" rel="noopener noreferrer" style={style}>wa.me/628174802801</a> (Dinna Nuriah Arumsari)</li>
                                <li>Contact :  <a href="https://wa.me/6282234655526" target="_blank" rel="noopener noreferrer" style={style}>wa.me/6282234655526</a> (Mey Eka Sulistyoningtyas)</li>
                                <li>Contact :  <a href="https://wa.me/6285967089381" target="_blank" rel="noopener noreferrer" style={style}>wa.me/6285967089381 </a> (Puspa Fadila)</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ 'borderColor': '#7979f1', 'borderRadius': '15px' }}>
                        <Card.Header style={{ 'fontWeight': '600', 'backgroundColor': '#7979f1', 'color': 'white', 'borderTopLeftRadius': '15px', 'borderTopRightRadius': '15px' }}>
                            Downstream
                        </Card.Header>
                        <Card.Body>
                            <p style={{ 'fontWeight': '500' }}>Project (customer, discount, employee, dll)</p>
                            <ul>
                                <li>Contact : <a href="https://wa.me/6287876038774" target="_blank" rel="noopener noreferrer" style={style}>wa.me/6287876038774</a> (Fathiyatusyarifah)</li>
                                <li>Contact :  <a href="https://wa.me/6287884587710" target="_blank" rel="noopener noreferrer" style={style}>wa.me/6287884587710</a> (Dewi Kurniasari)</li>
                                <li>Contact :  <a href="https://wa.me/6287709255872" target="_blank" rel="noopener noreferrer" style={style}>wa.me/6287709255872</a> (Tria Oktavia Aradiny)</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col lg={6}>
                    <Card style={{ 'borderColor': '#6c8afa', 'borderRadius': '15px' }}>
                        <Card.Header style={{ 'fontWeight': '600', 'backgroundColor': '#6c8afa', 'color': 'white', 'borderTopLeftRadius': '15px', 'borderTopRightRadius': '15px' }}>
                            Others
                        </Card.Header>
                        <Card.Body>
                            <p style={{ 'fontWeight': '500' }}>Project</p>
                            <ul>
                                <li>Contact : <a href="https://wa.me/628174802801" target="_blank" rel="noopener noreferrer" style={style}>wa.me/628174802801</a> (Dinna Nuriah Arumsari)</li>
                                <li>Contact : <a href="https://wa.me/6287876038774" target="_blank" rel="noopener noreferrer" style={style}>wa.me/6287876038774</a> (Fathiyatusyarifah)</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactUsMDM;
