import React, { useState, useEffect, useMemo } from "react";
import {Container} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import qore from "../../../../qoreContext";
import FilterComponent from "../FilterComponent";


const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
          backgroundColor: '#EBF3FF',
          fontSize: '16px',
          fontWeight: 'bold'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

const Glossary = () => {

  const [pending, setPending] = useState(true)
  const [dataTable, setDataTable] = useState([])

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [filterText, setFilterText] = useState('')

  const { data: dataTableRaw } = qore.view("allGlossary").useListRow({
    overrideLimit: true,
  });

  const filteredItems = dataTableRaw.filter(item =>
    (item.term && item.term.toLowerCase().includes(filterText.toLowerCase())) || 
    (item.abbreviation && item.abbreviation.toLowerCase().includes(filterText.toLowerCase()))||
    (item.definition && item.definition.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.unit && item.unit.toLowerCase().includes(filterText.toLowerCase())),
  )

  const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText} 
        placeholder="Search Glossary"
      />
		);
	}, [filterText, resetPaginationToggle]);

  useEffect(() => {
    const timeout = setTimeout(() => {
			setDataTable(filteredItems);
			setPending(false);
		}, 1000);
		return () => clearTimeout(timeout);
  }, [filteredItems])


  const columns = [
    {
        name: 'Term',
        selector: row => row.term,
        sortable: true,
        wrap : true
    },
    {
        name: 'Abbreviation',
        selector: row => row.abbreviation,
        sortable: true,
        wrap : true
        
    },
    {
        name: 'Definition',
        selector: row => row.isNoHtml ?
        row.definition :
          <div dangerouslySetInnerHTML={{
              __html: row.definition
            }}
          />,
        wrap: true,
    },
    {
        name: 'Unit',
        selector: row => row.unit,
        sortable: true,
        wrap : true
        
    },
  ];

  return (
    <Container className='ptb--80 ptb-md--80 ptb-sm--60 bg_color--1 pb--50'>
      <DataTable
          columns={columns}
          data={dataTable}
          pointerOnHover
          highlightOnHover
          pagination
          progressPending={pending}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          customStyles={customStyles}
        />
    </Container>
  );
};

export default Glossary;
