import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'

export default function FAQ() {
    const accordionContent = [
        {
            "id": 1,
            "title": "Dimana letak menu master data, hanya ada menu discuss?",
            "content": "Role belum disetting, sehingga menu master data tidak muncul. Jika telah di set role nya maka menu master data dapat ditemukan di pojok kiri atas (ikon kotak empat)."
        },
        {
            "id": 2,
            "title": "Bagaimana cara melihat item apa yang perlu di approve ?",
            "content": "Fitur Product Dashoard Role, under menu report lalu klik status waiting pada masing-masing tab."
        },
        {
            "id": 3,
            "title": "Kenapa master data yang baru di-create tidak terkirim ke Odoo MRP ?",
            "content": "<ol><li>Belum approve general tab, sehingga pastikan general tab sudah di approve baik oleh accountable maupun MDM dan status nya adalah confirmed.</li><li>Product company tidak diisi PT Paragon Technology and Innovation, sehingga pastikan product company telah diisi dengan benar sesuai dengan company mana item akan digunakan.</li><li>Bugs sistem. Jika general tab sudah di approve dan product company sudah diisi dengan benar maka jika item tidak ditemukan di odoo MRP bisa dikarenakan adanya bugs sistem sehingga perlu follow up ke tim IT / MDM.</li></ol>"
        },
        {
            "id": 4,
            "title": "Bagaimana alur permintaan akses ke odoo MDM ?",
            "content": "<ol><li>Akses Approver &amp; Creator : Mengirimkan permintaan akses ke tim MDM dengan melampirkan approval dari superior dan menjalankan training terlebih dahulu.</li><li>Akses Viewer : Mengirimkan permintaan akses ke tim MD</li></ol>"
        },
        {
            "id": 5,
            "title": "Kenapa item tidak terkirim ke ERP Paragon sehingga tdak bisa dibuat PO ?",
            "content": "<ol><li>Belum approve purchase tab, sehingga pastikan purhase tab sudah di approve oleh approver SSC.</li><li>Product company tidak diisi PT Paragon Technology and Innovation, sehingga pastikan product company telah diisi dengan benar sesuai dengan company mana item akan digunakan.</li><li>Bugs sistem. Jika general tab sudah di approve dan product company sudah diisi dengan benar maka jika item tidak ditemukan di odoo MRP bisa dikarenakan adanya bugs sistem sehingga perlu follow up ke tim IT / MDM.</li></ol>"
        },
        {
            "id": 6,
            "title": "Kenapa email notifikasi tidak masuk ke beberapa approver ?",
            "content": "<ol><li>Approver yang dimaksud tidak tercantum email nya sebagai penerima notifikasi., sehingga perlu memastikan apakah creator sudah memasukkan email nya atau belum.</li><li>Bugs sistem. Email approver sudah diinput sebagai penerima namun notifikasi masih tetap belum terkirim sehingga perlu follow up ke IT / MDM</li></ol>"
        },
        {
            "id": 7,
            "title": "Kenapa master data yang baru di-create tidak terkirim ke ERP Varcos ?",
            "content": "<ol><li>Belum approve general tab, sehingga pastikan general tab sudah di approve baik oleh accountable maupun MDM dan status nya adalah confirmed.</li><li>Product company tidak diisi PT Varcos CItra Internasional, sehingga pastikan product company telah diisi dengan benar sesuai dengan company mana item akan digunakan.</li><li>Bugs sistem. Jika general tab sudah di approve dan product company sudah diisi dengan benar maka jika item tidak ditemukan di odoo MRP bisa dikarenakan adanya bugs sistem sehingga perlu follow up ke tim IT / MDM.</li></ol>"
        },
        {
            "id": 8,
            "title": "Kenapa item yang di request approval oleh creator tidak muncul di odoo approver?",
            "content": "List company yang ada disebelah user belum di ceklist sehingga item atas company tersebut tidak muncul"
        },
        {
            "id": 9,
            "title": "Kenapa start date validity pada tab purchase item RM/PM tidak bisa dibuat atau diset tanggal hari ini ?",
            "content": "Karena ada line vendor pada tab purchase yang statusnya masih draft, sehingga jika ada penambahan line bau perlu dipastikan semua line yang telah disubmit sebelumnya sudah diapprove."
        },
        {
            "id": 10,
            "title": "Bagaimana cara pengisian product company untuk item Finished goods Varcos ?",
            "content": "<p>Perlu diketahui terlebih dahulu dimana item tersebut diproduksi, jika:</p><ol><li style='list-style-type: none;'><ol><li>Item diolah di Paragon dan dikemas di Varcos, maka product company diisi PT Varcos Citra Internasional.</li><li>Item diolah di Paragon dan dikemas di Paragon, maka product company diisi PT Paragon Technology and Innovation dan PT Varcos Citra Internasional.</li><li>Item diolah di Varcos dan dikemas di Varcos, maka product company diisi PT Varcos Citra Internasional.</li></ol></li></ol>"
        },
        {
            "id": 11,
            "title": "Bagaimana cara pengisian netto claim untuk item package ?",
            "content": "Jika package terdiri dari item FG yang diual terpisah maka netto clain diisi nol. Jika package terdiri dari item FG yang tidak dijual terpisah maka netto claim diisi dengan hasil penjumlahan netto komponen package tersebut"
        },
        {
            "id": 12,
            "title": "Bagaimana cara menghapus master attribute product yang tidak terpakai?",
            "content": "Master Attribute tidak bisa dihapus atau di archive langsung oleh user, perlu adanya bantuan IT untuk melakukakannya karena perlu ada pengecekan apakah master attribute tersebut ada penggunaannya atau tidak"
        },
    ]

    return (
        <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">

            <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                {accordionContent.map(accordion => (
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                {accordion.title}
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey={accordion.id}>
                            <Card.Body>
                                <div dangerouslySetInnerHTML={{
                                    __html: accordion.content
                                }}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </Container>
    )
}
