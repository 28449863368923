import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'
import CompReseorce from '../../../upstream/components/CompReseorce'

const accordionContent = [
  {
    "id": 1,
    "title": "Training Videos",
    "content": <CompReseorce title="Link training videos Discount" link="https://paracorpgroup-my.sharepoint.com/:v:/p/helsa_audrya/EVCVLVIxhipJgBTpCUSXPKoBf_W00E0WwIWi4MQ7j4m03Q?e=wobgaZ" />
  },
  {
    "id": 2,
    "title": "Post Test",
    "content": <CompReseorce title="Link post test DIscount" link="https://forms.gle/ACkocjeaEE9aK2bN9" />
  },
  {
    "id": 3,
    "title": "Flow",
    "content": <CompReseorce title="Link flow Discount" link="https://drive.google.com/drive/folders/1S0CLO6IA7X15Gvf2UPRpZJY6xA586fo1?usp=sharing" />
  },
  {
    "id": 4,
    "title": "User Guide",
    "content": <CompReseorce title="Link user guide Discount" link="https://docs.google.com/document/d/1v39jFItoUUohSPeMYRLNeOTrGQ8vDfmzrU0M4e0YgAQ/edit" />
  },
  {
    "id": 5,
    "title": "FAQ",
    "content": <CompReseorce title="FAQ Discount" link="https://docs.google.com/document/d/1v39jFItoUUohSPeMYRLNeOTrGQ8vDfmzrU0M4e0YgAQ/edit" />
  },
]

export default function Resource() {
  return (
    <>
      <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">
        <Accordion defaultActiveKey="1" className={'bk-accordion'}>
          {accordionContent.map(accordion => (
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                  {accordion.title}
                </Accordion.Toggle>
              </Card.Header>

              <Accordion.Collapse eventKey={accordion.id}>
                <Card.Body>{accordion.content}</Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Container>
    </>
  )
}
