import React, { useState, useEffect, useMemo } from "react";
import { Container, Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import qore from "../../../../qoreContext";
import Cookies from "universal-cookie";
import FilterComponent from "../../BusinessIntelligence/FilterComponent";

const customStyles = {
    rows: {
        style: {
            minHeight: "72px", // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",
            backgroundColor: "#EBF3FF",
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px",
        },
    },
};

const FavoritesMDM = () => {
    const [pending, setPending] = useState(true);
    const [dataTable, setDataTable] = useState([]);
    const cookies = new Cookies();

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [smShow, setSmShow] = useState(false);
    const [dataClickName, setDataClickName] = useState();

    const { data: allFavoritesby, revalidate: revalidateFavorites } = qore.view("allFavoritesby").useListRow({
        email: cookies.get('user_email')
    });
    const { deleteRow: deleteFavorites } = qore.view("allFavoritesby").useDeleteRow();

    const filteredItems = allFavoritesby.filter(item => (
        item.dashboardName && item.dashboardName.toLowerCase().includes(filterText.toLowerCase()))
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
                placeholder="Search Favorites"
            />
        );
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDataTable(filteredItems);
            setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [filteredItems]);

    const handleClick = async (id, name) => {
        setSmShow(true)
        setDataClickName(name)

        const deleteFav = await deleteFavorites(id)
        if (deleteFav) {
            revalidateFavorites()
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSmShow(false)
        }, 1000);
        return () => clearTimeout(timeout);
    }, [smShow]);

    const columns = [
        {
            name: "Dashboard name",
            selector: (row) => <a href={row.link} target='_blank' rel="noopener noreferrer">{row.dashboardName}</a>,
            sortable: true,
            wrap: true,
        },
        {
            name: "Category",
            selector: (row) => row.table,
            sortable: true,
            wrap: true,
        },
        {
            name: "action",
            cell: (row) =>
                <>
                    <Button
                        value={row.id}
                        name={row.dasboardName}
                        variant="outline-danger"
                        size="sm"
                        onClick={e => handleClick(e.target.value, e.target.name)}
                    >
                        <i className="fa fa-trash" />
                        &nbsp; remove
                    </Button>
                    {/* <Modal
            size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Body id="example-modal-sizes-title-sm">
              <small>{`${row.id} removed from favorites`}</small>
            </Modal.Body>
          </Modal> */}
                </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: false,
        },
    ];

    return (
        <Container className="ptb--80 ptb-md--80 ptb-sm--60 bg_color--1 pb--50">
            <DataTable
                columns={columns}
                data={dataTable}
                pointerOnHover
                highlightOnHover
                pagination
                progressPending={pending}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
            />

            <Modal
                size="sm"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body id="example-modal-sizes-title-sm">
                    <small>{`${dataClickName} removed from favorites`}</small>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default FavoritesMDM;
