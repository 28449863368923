import React from "react";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const listCategory = [
  {
    icon: 'fa fa-pie-chart',
    name: 'Customer',
  },
  {
    icon: 'fa fa-bar-chart',
    name: 'Discount',
  },
  {
    icon: 'fa fa-bar-chart',
    name: 'POSM',
  },
]

const BreadcrumbsMDMDown = ({ title, subtitle, color, style, handleChangeCategory, category }) => {
  return (
    <div
      style={{ ...style, backgroundColor: color }}
      className="breadcrumb-area ptb--50 ptb-md--150 ptb-sm--150 breadcrumb-title-bar breadcrumb-title-white">
      <Container>
        <div className="breadcrumb-inner text-left mt_sm--50 mt_md--50">
          <Link to="/master-data-management">
            <h3 className="heading text-white"><BsChevronLeft />&nbsp;{title}</h3>
          </Link>
          <p className="text-white">{subtitle}</p>
        </div>
        <div className="mt-5">
          {listCategory.map((cat) => {
            return (
              cat?.name === category ?
                <Button
                  key={cat.name}
                  onClick={() => handleChangeCategory(cat?.name)}
                  style={{ marginRight: '10px', borderRadius: '10px', backgroundColor: '#215AA8' }}                     >
                  <i className={cat.icon} />
                  &nbsp;&nbsp;{cat?.name}
                </Button>
                :
                <Button
                  key={cat.name}
                  onClick={() => handleChangeCategory(cat?.name)}
                  style={{ marginRight: '10px', borderRadius: '10px', backgroundColor: 'white', color: 'darkblue' }}                     >
                  <i className={cat.icon} />
                  &nbsp;&nbsp;{cat?.name}
                </Button>
            )
          })}
        </div>
      </Container>
    </div>
  );
};

export default BreadcrumbsMDMDown;
