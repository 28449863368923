import React from 'react';
import Thumbnail from "../../../../../../components/about-us/thumbnail/AboutThumbTwo";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Article = ({ data }) => {
  return (
    <>
      {
        data !== null ?
          <article className="blog-post standard-post">
            <header className="header mb--40 text-center">
              <h3 className="heading heading-h3 font-32">
                {data?.title}
              </h3>
              <div className="post-meta mt--20">
                <div className="post-date">{data?.dateCreated.slice(0, 10)}</div>
                <div className="post-category">
                  <div className="blog-tag-list pl--5">
                    <a href={`${process.env.PUBLIC_URL + "/"}`}>{data?.tag}</a>
                  </div>
                </div>
              </div>
            </header>
            <Thumbnail thumb={data?.tumbnail} className="mb--60" />
            <section className="content basic-dark2-line-1px pb--50 mb--35">
              {data?.image1 && <Thumbnail thumb={data?.image1} className="mb--60" />}
              <div className="inner">
                <div className="desc mt--45 mb--50">
                  <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                    <div dangerouslySetInnerHTML={{ __html: data?.paragraph1 }}></div>
                  </p>
                </div>
              </div>
              {data?.image2 && <Thumbnail thumb={data?.image2} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph2 }}></div>
                </p>
              </div>
              {data?.image3 && <Thumbnail thumb={data?.image3} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph3 }}></div>
                </p>
              </div>
              {data?.image4 && <Thumbnail thumb={data?.image4} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph4 }}></div>
                </p>
              </div>
              {data?.image5 && <Thumbnail thumb={data?.image5} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph5 }}></div>
                </p>
              </div>
              {data?.image6 && <Thumbnail thumb={data?.image6} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph6 }}></div>
                </p>
              </div>
              {data?.image7 && <Thumbnail thumb={data?.image7} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph7 }}></div>
                </p>
              </div>
              {data?.image8 && <Thumbnail thumb={data?.image8} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph8 }}></div>
                </p>
              </div>
              {data?.image9 && <Thumbnail thumb={data?.image9} className="mb--60" />}
              <div className="desc mt--45">
                <p className="bk_pra wow move-up" style={{ textAlign: 'justify' }}>
                  <div dangerouslySetInnerHTML={{ __html: data?.paragraph9 }}></div>
                </p>
              </div>
            </section>
          </article> :
          <>
            <Skeleton height={50} />
            <Skeleton height={350} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </>
      }
    </>
  );
};

export default Article;
