import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

const NewsItemBi = ({data, className}) => {
    return (
        <div className={`blog-grid blog-grid--modern blog-standard ${className}`} style={{minHeight:'200px'}}>
            <div className="post-thumb">
                <Link to={`${process.env.PUBLIC_URL + '/news-detail-bi/'+data?.idQore}`}>
                    <img style={{maxHeight:'170px', objectFit:'cover', overflow:'hidden'}} src={data.thumb} alt={data.title}/>
                </Link>
            </div>
            <div className="post-content text-center">
                <div className="post-inner">
                    <div className="post-meta mb--10">
                        <div className="post-date" style={{fontSize:'12px'}}>{data?.meta?.postDate?.slice(0,10)}</div>
                        <div className="post-category" style={{fontSize:'12px'}}>
                            <Link to={`${process.env.PUBLIC_URL + '/news-detail-bi/'+data?.idQore}`}>{data.meta.category}</Link></div>
                    </div>
                    <h6 className="heading line-height-1-39">
                        <Link to={`${process.env.PUBLIC_URL + '/news-detail-bi/'+data?.idQore}`}>{data?.title?.slice(0,35)}</Link>
                    </h6>
                    <Link to={`${process.env.PUBLIC_URL + '/news-detail-bi/'+data?.idQore}`} className="post-read-more" style={{color:'white'}}>&nbsp;</Link>
                </div>
            </div>
        </div>
    );
};

NewsItemBi.propTypes = {
    data: PropTypes.object
};

export default NewsItemBi;
