import React, { useEffect, useState } from 'react';
import LayoutDefaultParaportal from '../../../layouts/LayoutDefaultParaportal'
import { Helmet } from "react-helmet";
import Searchone from '../../../components/search/Searchone';
import TabMenu from './TabMenu';
import image from '../assets/img/hrsc.jpg'
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tabHRSCLink = paramsVal.get('tabHRSC');
let filterAreaLink = paramsVal.get('filterArea');

export default function HRSC() {
  const [tabHRSC, setTabHRSC] = useState(tabHRSCLink ? tabHRSCLink : "headOffice")
  const [filterArea, setFilterArea] = useState(filterAreaLink ? filterAreaLink.split(",") : [])
  const history = useHistory();
  const params = new URLSearchParams({
    tabHRSC,
    filterArea,
  });

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [tabHRSC, filterArea])

  const tabHRSCChange = (e) => {
    setTabHRSC(e)
  }

  const filterAreaChange = (e) => {
    setFilterArea(e)
  }

  return (
    <>
      <Helmet>
        <title>HRSC - PARAPORTAL</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <div>
          <img src={image} className='img-fluid shadow-4' alt='.' />
        </div>
        <Searchone className="template-color-1 template-font-1"
          title="Silahkan cari apa yang Anda butuhkan di HRSC pada kolom ini"
          color="#0E5679"
          filterAreaChange={filterAreaChange}
          filterArea={filterArea}
        />
        <TabMenu tabHRSC={tabHRSCChange} activeTab={tabHRSC} />
      </LayoutDefaultParaportal>
    </>
  )
}