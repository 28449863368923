import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import LayoutDefaultParaportal from "../../layouts/LayoutDefaultParaportal";
import ListCatBI from "./ListCatBI";
import Report from "./report/Report";
import KpiCatalogue from "./kpi-catalogue/KpiCatalogue";
import Glossary from "./glossary/Glossary";
import ContactUsBI from "./contact-us/ContactUsBI";
import NewsBi from "./news/NewsBi";
import TrainingBI from "./training/TrainingBI";
import FavoritesBI from "./favorites/FavoritesBI";
import { useHistory } from 'react-router';

let search = window.location.search;
let paramsVal = new URLSearchParams(search);
let tab = paramsVal.get('tabBI');
let tabTraining = paramsVal.get('tabBITraining')

const BusinessIntelligence = () => {
  const [category, setCategory] = useState(tab ? tab : 'Report');
  const history = useHistory();
  const params = new URLSearchParams(category === "Training" ? { tabBI: category, tabBITraining: tabTraining ? tabTraining : 'Tableau'} : {tabBI: category});
  
  const handleChangeCategory = (item) => {
    setCategory(item);
  };

  useEffect(() => {
    history.push({ search: params.toString() });
    // eslint-disable-next-line
  }, [category])

  return (
    <Fragment>
      <Helmet>
        <title>PARAPORTAL - Business Intelligence</title>
      </Helmet>
      <LayoutDefaultParaportal className="template-color-1 template-font-1">
        <ListCatBI
          title="Business Intelligence (BI)"
          subtitle="Intelligent data at your finger tips"
          color="#6A8AFC"
          handleChangeCategory={handleChangeCategory}
          category={category}
        />
        {category === "Report" && <Report />}
        {category === "KPI Catalogue" && <KpiCatalogue />}
        {category === "Glossary" && <Glossary />}
        {category === "News" && <NewsBi />}
        {category === "Training" && <TrainingBI tabBI={category} />}
        {category === "Contact Us" && <ContactUsBI />}
        {category === "Favorites" && <FavoritesBI />}
      </LayoutDefaultParaportal>
    </Fragment>
  );
};

export default BusinessIntelligence;
