import React from 'react'
import { Accordion, Container, Card, Button } from 'react-bootstrap'

export default function FAQ() {
    const accordionContent = [
        {
            "id": 1,
            "title": "Dimana letak menu master data, hanya ada menu discuss ?",
            "content": "Role belum disetting, sehingga menu master data tidak muncul. Jika telah di set role nya maka menu master data dapat ditemukan di pojok kiri atas (ikon kotak empat)."
        },
        {
            "id": 2,
            "title": "Kenapa tidak bisa request approval, tidak bisa mengirimkan notifikasi, tidak bisa approve ?",
            "content": "Ketika tidak bisa melakukan action dan terdapat notifikasi tekait restriction access berarti ada yang perlu diperbaiki dari setting role yang telah diberikan, maka perlu disampaikan issue nya ke tim MDM / IT supaya diperbaiki di user access nya."
        },
        {
            "id": 3,
            "title": "Kenapa formula tidak terkirim ke MRP/ERP Varcos ?",
            "content": "<p><strong>Penyebab formula tidak terkirim ke MRP ada beberapa hal, diantaranya:</strong></p><ol><li>General status belum confirmed sehingga perlu adanya approval terlebih dahulu supaya formula terkirim ke MRP.</li><li>Field Companies tidak diisi dengan company tujuannya sehingga formula tidak dikirimkan.</li><li>Product belum tersedia di MRP/ERP Varcos sehingga perlu dicek master data product apakah sudah terkirim atau belum.</li><li>Queue pengiriman data formula. Jika formula belum terkirim ke MRP dalam hitungan hari setelah di approve perlu adanya laporan case ke MDM / IT.</li><li>Bugs Sistem, perlu follow up ke tim MDM / IT.</li></ol>"
        },
    ]

    return (
        <Container className="ptb--30 ptb-md--30 ptb-sm--60 bg_color--1 pb--50">

            <Accordion defaultActiveKey="1" className={'bk-accordion'}>
                {accordionContent.map(accordion => (
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="button" eventKey={accordion.id} className={'text-left'}>
                                {accordion.title}
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey={accordion.id}>
                            <Card.Body>
                                <div dangerouslySetInnerHTML={{
                                    __html: accordion.content
                                }}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </Container>
    )
}
