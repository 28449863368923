// import { Container, Card, Row, Col, Button, Accordion, Badge } from 'react-bootstrap';
// import React, { Fragment } from 'react';
// import CardItems from './CardItems';
// import ChildContent from './ChildContent';
// import HRSCListContent from './HRSCListContent';
// import { useState, useCallback } from 'react';
// import Collapse from 'react-bootstrap/Collapse';
// import ChildComponent from './ChildComponent';

// const CardContent = ({ id }) => {
//     console.log(id)
//     const { data: dataHRSC_child } = qore.view("allCategoryHrsc").useListRow({
//         parentID: id,
//         expanded: false
//     });

//     const [expanded, setExpanded] = React.useState([dataHRSC_child.expanded]);
//     return (
//         <>
//             {dataHRSC_child?.map((item) => (
//                 <ul onClick= {() => setExpanded(item.expanded= !item.expanded)}>
//                     <p>{item.name}</p>
//                     {item.expanded && <ChildComponent id={item.id} />}

//                 </ul>
//             ))}
//         </>
//     );
// }

// export default CardContent;

import { Card, Accordion, Badge } from 'react-bootstrap';
import React from 'react';
import qore from "../../../../qoreContext";
import ChildContent from './ChildContent';
import HRSCListContent from './HRSCListContent';
import './styleHRSC.css'


const CardContent = ({ id }) => {
  const { data: dataHRSC_child } = qore.view("allCategoryHrsc").useListRow({
    parentID: id,
    overrideLimit: true
  });
  return (
    <div style={{
      margin: '-2%'
    }}>
      {dataHRSC_child?.map((item) => (
        <Accordion >
          <Card className="mb-1 border-0 card__accordion" style={{ maxHeight: '20%' }}>
            <Card.Header style={{ backgroundColor: 'white', margin: '0px', padding: '0px', borderBottom: '0px' }}>
              <Accordion.Toggle
                eventKey="0" style={{ lineHeight: '12px', borderColor: 'transparent' }}>
                <Badge className="" bg="secondary" style={{ color: 'grey' }}>&#9658;</Badge>
                {item?.name}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="card__body">
                <ChildContent id={item.id} />
                <HRSCListContent id={item.id} />
              </Card.Body>
            </Accordion.Collapse>

          </Card>
        </Accordion>
      ))}
    </div>
  );
}

export default CardContent;